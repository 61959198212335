
@import url('https://fonts.googleapis.com/css2?family=Cormorant+SC:wght@500&family=Edu+VIC+WA+NT+Beginner:wght@600&family=Inder&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ballet:opsz@16..72&family=Caveat&family=Dancing+Script:wght@500&family=Edu+QLD+Beginner&family=Edu+VIC+WA+NT+Beginner:wght@500&family=Oswald&family=Yanone+Kaffeesatz&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Akaya+Telivigala&family=Akronim&family=Allura&family=Anton&family=Arizonia&family=Baloo+2&family=Bangers&family=Barrio&family=Bellota:wght@300&family=Berkshire+Swash&family=Bigshot+One&family=Blaka&family=Boogaloo&family=Cabin+Sketch&family=Caesar+Dressing&family=Carter+One&family=Chewy&family=Comforter&family=Cookie&family=Cormorant+SC:wght@400;500&family=Courgette&family=Edu+VIC+WA+NT+Beginner:wght@600&family=Engagement&family=Fascinate+Inline&family=Fontdiner+Swanky&family=Gorditas&family=Great+Vibes&family=Griffy&family=Hachi+Maru+Pop&family=Henny+Penny&family=Homemade+Apple&family=Inder&family=Inspiration&family=Jim+Nightshade&family=Joti+One&family=Julee&family=Kaushan+Script&family=Kranky&family=Leckerli+One&family=Libre+Barcode+128+Text&family=Libre+Barcode+39+Extended+Text&family=Libre+Barcode+39+Text&family=Lobster&family=Lobster+Two&family=Lovers+Quarrel&family=Macondo&family=Macondo+Swash+Caps&family=Metal+Mania&family=Miniver&family=Mochiy+Pop+One&family=Mochiy+Pop+P+One&family=Molle&family=Monofett&family=Mrs+Sheppards&family=Mystery+Quest&family=Neonderthaw&family=Nosifer&family=Nova+Flat&family=Overlock+SC&family=Pacifico&family=Parisienne&family=Playball&family=Poiret+One&family=Press+Start+2P&family=Redressed&family=Ribeye&family=Rubik+Moonrocks&family=Ruthie&family=Rye&family=Sacramento&family=Sarina&family=Secular+One&family=Send+Flowers&family=Shadows+Into+Light&family=Short+Stack&family=Sonsie+One&family=Spicy+Rice&family=Square+Peg&family=Sriracha&family=Srisakdi&family=Stalemate&family=Stick&family=Tangerine:wght@400;700&family=Titan+One&family=Trade+Winds&family=Train+One&family=Underdog&family=Unica+One&family=Unkempt&family=VT323&family=WindSong&family=Yesteryear&family=ZCOOL+KuaiLe&family=Zilla+Slab+Highlight&display=swap');


body {
    background: black;
    overflow-x: hidden;
    
}




.enterInfo {
    -webkit-animation: ani 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: ani 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.exitInfo {
    -webkit-animation: ani 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: ani 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-direction: reverse;
}


/* ----------------------------------------------
 * Generated by Animista on 2022-7-23 6:0:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-fwd-br
 * ----------------------------------------
 */
@-webkit-keyframes ani {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(-35deg) translate( 50px, 50px) skew(35deg, -10deg);
        transform: rotateY(20deg) rotateX(-35deg) translate(50px, 50px) skew(35deg, -10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}





@keyframes ani {
    0% {
        -webkit-transform: rotateY(20deg) rotateX(-35deg) translate(50px, 50px) skew(35deg, -10deg);
        transform: rotateY(20deg) rotateX(-35deg) translate(50px, 50px) skew(35deg, -10deg);
        opacity: 0;
    }

    100% {
        -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
    }
}
