/* ----------------------------------------------
 * Generated by Animista on 2022-2-8 15:58:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-pop-up-top
 * ----------------------------------------
 */


.text-pop-up-top {
    animation-name: text-pop-up-top;
    animation-duration: 0.5s;
}

.text-pop-up-tr {
    animation-name: text-pop-up-tr;
    animation-duration: 0.5s;
}

.text-pop-up-right {
    animation-name: text-pop-up-right;
    animation-duration: 0.5s;
}

.text-pop-up-br {
    animation-name: text-pop-up-br;
    animation-duration: 0.5s;
}

.text-pop-up-bottom {
    animation-name: text-pop-up-bottom;
    animation-duration: 0.5s;
}

.text-pop-up-bl {
    animation-name: text-pop-up-bl;
    animation-duration: 0.5s;
}

.text-pop-up-left {
    animation-name: text-pop-up-left;
    animation-duration: 0.5s;
}

.text-pop-up-tl {
    animation-name: text-pop-up-tl;
    animation-duration: 0.5s;
}

.text-shadow-pop-top {
    animation-name: text-shadow-pop-top;
    animation-duration: 0.5s;
}

.text-shadow-pop-tr {
    animation-name: text-shadow-pop-tr;
    animation-duration: 0.5s;
}

.text-shadow-pop-right {
    animation-name: text-shadow-pop-right;
    animation-duration: 0.5s;
}

.text-shadow-pop-br {
    animation-name: text-shadow-pop-br;
    animation-duration: 0.5s;
}

.text-shadow-pop-bottom {
    animation-name: text-shadow-pop-bottom;
    animation-duration: 0.5s;
}

.text-shadow-pop-bl {
    animation-name: text-shadow-pop-bl;
    animation-duration: 0.5s;
}

.text-shadow-pop-left {
    animation-name: text-shadow-pop-left;
    animation-duration: 0.5s;
}

.text-shadow-pop-tl {
    animation-name: text-shadow-pop-tl;
    animation-duration: 0.5s;
}

.text-flicker-in-glow {
    animation-name: text-flicker-in-glow;
    animation-duration: 0.5s;
}

.text-flicker-out-glow {
    animation-name: text-flicker-out-glow;
    animation-duration: 0.5s;
}

.text-blur-out {
    animation-name: text-blur-out;
    animation-duration: 0.5s;
}

.blur-out-contract {
    animation-name: blur-out-contract;
    animation-duration: 0.5s;
}

.blur-out-contract-bck {
    animation-name: blur-out-contract-bck;
    animation-duration: 0.5s;
}

.blur-out-expand {
    animation-name: blur-out-expand;
    animation-duration: 0.5s;
}

.blur-out-expand-fwd {
    animation-name: blur-out-expand-fwd;
    animation-duration: 0.5s;
}

.text-focus-in {
    animation-name: text-focus-in;
    animation-duration: 0.5s;
}

.focus-in-expand {
    animation-name: focus-in-expand;
    animation-duration: 0.5s;
}

.focus-in-expand-fwd {
    animation-name: focus-in-expand-fwd;
    animation-duration: 0.5s;
}

.focus-in-contract {
    animation-name: focus-in-contract;
    animation-duration: 0.5s;
}

.focus-in-contract-bck {
    animation-name: focus-in-contract-bck;
    animation-duration: 0.5s;
}

.tracking-out-contract {
    animation-name: tracking-out-contract;
    animation-duration: 0.5s;
}

.tracking-out-contract-bck {
    animation-name: tracking-out-contract-bck;
    animation-duration: 0.5s;
}

.tracking-out-contract-bck-top {
    animation-name: tracking-out-contract-bck-top;
    animation-duration: 0.5s;
}

.tracking-out-contract-bck-bottom {
    animation-name: tracking-out-contract-bck-bottom;
    animation-duration: 0.5s;
}

.tracking-out-expand {
    animation-name: tracking-out-expand;
    animation-duration: 0.5s;
}

.tracking-out-expand-fwd {
    animation-name: tracking-out-expand-fwd;
    animation-duration: 0.5s;
}

.tracking-out-expand-fwd-top {
    animation-name: tracking-out-expand-fwd-top;
    animation-duration: 0.5s;
}

.tracking-out-expand-fwd-bottom {
    animation-name: tracking-out-expand-fwd-bottom;
    animation-duration: 0.5s;
}

.tracking-in-expand {
    animation-name: tracking-in-expand;
    animation-duration: 0.5s;
}

.tracking-in-expand-fwd {
    animation-name: tracking-in-expand-fwd;
    animation-duration: 0.5s;
}

.tracking-in-expand-fwd-top {
    animation-name: tracking-in-expand-fwd-top;
    animation-duration: 0.5s;
}

.tracking-in-expand-fwd-bottom {
    animation-name: tracking-in-expand-fwd-bottom;
    animation-duration: 0.5s;
}

.tracking-in-contract {
    animation-name: tracking-in-contract;
    animation-duration: 0.5s;
}

.tracking-in-contract-bck {
    animation-name: tracking-in-contract-bck;
    animation-duration: 0.5s;
}

.tracking-in-contract-bck-top {
    animation-name: tracking-in-contract-bck-top;
    animation-duration: 0.5s;
}

.tracking-in-contract-bck-bottom {
    animation-name: tracking-in-contract-bck-bottom;
    animation-duration: 0.5s;
}

.scale-up-center {
    animation-name: scale-up-center;
    animation-duration: 0.5s;
}

.scale-up-top {
    animation-name: scale-up-top;
    animation-duration: 0.5s;
}

.scale-up-tr {
    animation-name: scale-up-tr;
    animation-duration: 0.5s;
}

.scale-up-right {
    animation-name: scale-up-right;
    animation-duration: 0.5s;
}

.scale-up-br {
    animation-name: scale-up-br;
    animation-duration: 0.5s;
}

.scale-up-bottom {
    animation-name: scale-up-bottom;
    animation-duration: 0.5s;
}

.scale-up-hor-right {
    animation-name: scale-up-hor-right;
    animation-duration: 0.5s;
}

.scale-up-hor-left {
    animation-name: scale-up-hor-left;
    animation-duration: 0.5s;
}

.scale-up-hor-center {
    animation-name: scale-up-hor-center;
    animation-duration: 0.5s;
}

.scale-up-ver-bottom {
    animation-name: scale-up-ver-bottom;
    animation-duration: 0.5s;
}

.scale-up-tl {
    animation-name: scale-up-tl;
    animation-duration: 0.5s;
}

.scale-up-ver-top {
    animation-name: scale-up-ver-top;
    animation-duration: 0.5s;
}

.scale-up-left {
    animation-name: scale-up-left;
    animation-duration: 0.5s;
}

.scale-up-bl {
    animation-name: scale-up-bl;
    animation-duration: 0.5s;
}

.scale-up-ver-center {
    animation-name: scale-up-ver-center;
    animation-duration: 0.5s;
}

.scale-down-center {
    animation-name: scale-down-center;
    animation-duration: 0.5s;
}

.scale-down-top {
    animation-name: scale-down-top;
    animation-duration: 0.5s;
}

.scale-down-tr {
    animation-name: scale-down-tr;
    animation-duration: 0.5s;
}

.scale-down-right {
    animation-name: scale-down-right;
    animation-duration: 0.5s;
}

.scale-down-br {
    animation-name: scale-down-br;
    animation-duration: 0.5s;
}

.scale-down-bottom {
    animation-name: scale-down-bottom;
    animation-duration: 0.5s;
}

.scale-down-hor-right {
    animation-name: scale-down-hor-right;
    animation-duration: 0.5s;
}

.scale-down-left {
    animation-name: scale-down-left;
    animation-duration: 0.5s;
}

.scale-down-bl {
    animation-name: scale-down-bl;
    animation-duration: 0.5s;
}

.scale-down-hor-left {
    animation-name: scale-down-hor-left;
    animation-duration: 0.5s;
}

.scale-down-ver-to-bottom {
    animation-name: scale-down-ver-to-bottom;
    animation-duration: 0.5s;
}

.scale-down-ver-top {
    animation-name: scale-down-ver-top;
    animation-duration: 0.5s;
}

.scale-down-hor-center {
    animation-name: scale-down-hor-center;
    animation-duration: 0.5s;
}

.scale-down-ver-center {
    animation-name: scale-down-ver-center;
    animation-duration: 0.5s;
}

.scale-down-tl {
    animation-name: scale-down-tl;
    animation-duration: 0.5s;
}

.rotate-center {
    animation-name: rotate-center;
    animation-duration: 0.5s;
}

.rotate-top {
    animation-name: rotate-top;
    animation-duration: 0.5s;
}

.rotate-br {
    animation-name: rotate-br;
    animation-duration: 0.5s;
}

.rotate-tl {
    animation-name: rotate-tl;
    animation-duration: 0.5s;
}

.rotate-vert-center {
    animation-name: rotate-vert-center;
    animation-duration: 0.5s;
}

.rotate-diagonal-2 {
    animation-name: rotate-diagonal-2;
    animation-duration: 0.5s;
}

.rotate-diagonal-tl {
    animation-name: rotate-diagonal-tl;
    animation-duration: 0.5s;
}

.rotate-bottom {
    animation-name: rotate-bottom;
    animation-duration: 0.5s;
}

.rotate-hor-center {
    animation-name: rotate-hor-center;
    animation-duration: 0.5s;
}

.rotate-vert-left {
    animation-name: rotate-vert-left;
    animation-duration: 0.5s;
}

.rotate-diagonal-tr {
    animation-name: rotate-diagonal-tr;
    animation-duration: 0.5s;
}

.rotate-tr {
    animation-name: rotate-tr;
    animation-duration: 0.5s;
}

.rotate-bl {
    animation-name: rotate-bl;
    animation-duration: 0.5s;
}

.rotate-hor-top {
    animation-name: rotate-hor-top;
    animation-duration: 0.5s;
}

.rotate-vert-right {
    animation-name: rotate-vert-right;
    animation-duration: 0.5s;
}

.rotate-diagonal-br {
    animation-name: rotate-diagonal-br;
    animation-duration: 0.5s;
}

.rotate-right {
    animation-name: rotate-right;
    animation-duration: 0.5s;
}

.rotate-left {
    animation-name: rotate-left;
    animation-duration: 0.5s;
}

.rotate-hor-bottom {
    animation-name: rotate-hor-bottom;
    animation-duration: 0.5s;
}

.rotate-diagonal-1 {
    animation-name: rotate-diagonal-1;
    animation-duration: 0.5s;
}

.rotate-diagonal-bl {
    animation-name: rotate-diagonal-bl;
    animation-duration: 0.5s;
}

.rotate-scale-up {
    animation-name: rotate-scale-up;
    animation-duration: 0.5s;
}

.rotate-scale-down {
    animation-name: rotate-scale-down;
    animation-duration: 0.5s;
}

.rotate-scale-up-hor {
    animation-name: rotate-scale-up-hor;
    animation-duration: 0.5s;
}

.rotate-scale-down-hor {
    animation-name: rotate-scale-down-hor;
    animation-duration: 0.5s;
}

.rotate-scale-down-diag-1 {
    animation-name: rotate-scale-down-diag-1;
    animation-duration: 0.5s;
}

.rotate-scale-up-diag-1 {
    animation-name: rotate-scale-up-diag-1;
    animation-duration: 0.5s;
}

.rotate-scale-down-diag-2 {
    animation-name: rotate-scale-down-diag-2;
    animation-duration: 0.5s;
}

.rotate-scale-down-ver {
    animation-name: rotate-scale-down-ver;
    animation-duration: 0.5s;
}

.rotate-scale-up-diag-2 {
    animation-name: rotate-scale-up-diag-2;
    animation-duration: 0.5s;
}

.rotate-scale-up-ver {
    animation-name: rotate-scale-up-ver;
    animation-duration: 0.5s;
}

.rotate-90-cw {
    animation-name: rotate-90-cw;
    animation-duration: 0.5s;
}

.rotate-90-ccw {
    animation-name: rotate-90-ccw;
    animation-duration: 0.5s;
}

.rotate-90-tr-cw {
    animation-name: rotate-90-tr-cw;
    animation-duration: 0.5s;
}

.rotate-90-br-cw {
    animation-name: rotate-90-br-cw;
    animation-duration: 0.5s;
}

.rotate-90-bl-cw {
    animation-name: rotate-90-bl-cw;
    animation-duration: 0.5s;
}

.rotate-90-tl-cw {
    animation-name: rotate-90-tl-cw;
    animation-duration: 0.5s;
}

.rotate-90-vertical-fwd {
    animation-name: rotate-90-vertical-fwd;
    animation-duration: 0.5s;
}

.rotate-90-tr-ccw {
    animation-name: rotate-90-tr-ccw;
    animation-duration: 0.5s;
}

.rotate-90-br-ccw {
    animation-name: rotate-90-br-ccw;
    animation-duration: 0.5s;
}

.rotate-90-bl-ccw {
    animation-name: rotate-90-bl-ccw;
    animation-duration: 0.5s;
}

.rotate-90-tl-ccw {
    animation-name: rotate-90-tl-ccw;
    animation-duration: 0.5s;
}

.rotate-90-vertical-bck {
    animation-name: rotate-90-vertical-bck;
    animation-duration: 0.5s;
}

.rotate-90-top-cw {
    animation-name: rotate-90-top-cw;
    animation-duration: 0.5s;
}

.rotate-90-right-cw {
    animation-name: rotate-90-right-cw;
    animation-duration: 0.5s;
}

.rotate-90-bottom-cw {
    animation-name: rotate-90-bottom-cw;
    animation-duration: 0.5s;
}

.rotate-90-left-cw {
    animation-name: rotate-90-left-cw;
    animation-duration: 0.5s;
}

.rotate-90-horizontal-fwd {
    animation-name: rotate-90-horizontal-fwd;
    animation-duration: 0.5s;
}

.rotate-90-top-ccw {
    animation-name: rotate-90-top-ccw;
    animation-duration: 0.5s;
}

.rotate-90-right-ccw {
    animation-name: rotate-90-right-ccw;
    animation-duration: 0.5s;
}

.rotate-90-bottom-ccw {
    animation-name: rotate-90-bottom-ccw;
    animation-duration: 0.5s;
}

.rotate-90-left-ccw {
    animation-name: rotate-90-left-ccw;
    animation-duration: 0.5s;
}

.rotate-90-horizontal-bck {
    animation-name: rotate-90-horizontal-bck;
    animation-duration: 0.5s;
}

.flip-horizontal-bottom {
    animation-name: flip-horizontal-bottom;
    animation-duration: 0.5s;
}

.flip-horizontal-top {
    animation-name: flip-horizontal-top;
    animation-duration: 0.5s;
}

.flip-horizontal-bck {
    animation-name: flip-horizontal-bck;
    animation-duration: 0.5s;
}

.flip-horizontal-fwd {
    animation-name: flip-horizontal-fwd;
    animation-duration: 0.5s;
}

.flip-vertical-fwd {
    animation-name: flip-vertical-fwd;
    animation-duration: 0.5s;
}

.flip-diagonal-1-fwd {
    animation-name: flip-diagonal-1-fwd;
    animation-duration: 0.5s;
}

.flip-diagonal-2-fwd {
    animation-name: flip-diagonal-2-fwd;
    animation-duration: 0.5s;
}

.flip-diagonal-2-bck {
    animation-name: flip-diagonal-2-bck;
    animation-duration: 0.5s;
}

.flip-diagonal-1-bck {
    animation-name: flip-diagonal-1-bck;
    animation-duration: 0.5s;
}

.flip-vertical-bck {
    animation-name: flip-vertical-bck;
    animation-duration: 0.5s;
}

.flip-vertical-left {
    animation-name: flip-vertical-left;
    animation-duration: 0.5s;
}

.flip-diagonal-1-bl {
    animation-name: flip-diagonal-1-bl;
    animation-duration: 0.5s;
}

.flip-diagonal-2-tl {
    animation-name: flip-diagonal-2-tl;
    animation-duration: 0.5s;
}

.flip-diagonal-2-br {
    animation-name: flip-diagonal-2-br;
    animation-duration: 0.5s;
}

.flip-diagonal-1-tr {
    animation-name: flip-diagonal-1-tr;
    animation-duration: 0.5s;
}

.flip-vertical-right {
    animation-name: flip-vertical-right;
    animation-duration: 0.5s;
}

.flip-2-hor-top-1 {
    animation-name: flip-2-hor-top-1;
    animation-duration: 0.5s;
}

.flip-2-hor-top-2 {
    animation-name: flip-2-hor-top-2;
    animation-duration: 0.5s;
}

.flip-2-hor-top-bck {
    animation-name: flip-2-hor-top-bck;
    animation-duration: 0.5s;
}

.flip-2-hor-top-fwd {
    animation-name: flip-2-hor-top-fwd;
    animation-duration: 0.5s;
}

.flip-2-ver-right-fwd {
    animation-name: flip-2-ver-right-fwd;
    animation-duration: 0.5s;
}

.flip-2-hor-bottom-fwd {
    animation-name: flip-2-hor-bottom-fwd;
    animation-duration: 0.5s;
}

.flip-2-ver-left-fwd {
    animation-name: flip-2-ver-left-fwd;
    animation-duration: 0.5s;
}

.flip-2-ver-left-bck {
    animation-name: flip-2-ver-left-bck;
    animation-duration: 0.5s;
}

.flip-2-hor-bottom-bck {
    animation-name: flip-2-hor-bottom-bck;
    animation-duration: 0.5s;
}

.flip-2-ver-right-bck {
    animation-name: flip-2-ver-right-bck;
    animation-duration: 0.5s;
}

.flip-2-ver-right-2 {
    animation-name: flip-2-ver-right-2;
    animation-duration: 0.5s;
}

.flip-2-hor-bottom-2 {
    animation-name: flip-2-hor-bottom-2;
    animation-duration: 0.5s;
}

.flip-2-ver-left-2 {
    animation-name: flip-2-ver-left-2;
    animation-duration: 0.5s;
}

.flip-2-ver-left-1 {
    animation-name: flip-2-ver-left-1;
    animation-duration: 0.5s;
}

.flip-2-hor-bottom-1 {
    animation-name: flip-2-hor-bottom-1;
    animation-duration: 0.5s;
}

.flip-2-ver-right-1 {
    animation-name: flip-2-ver-right-1;
    animation-duration: 0.5s;
}

.flip-scale-up-hor {
    animation-name: flip-scale-up-hor;
    animation-duration: 0.5s;
}

.flip-scale-down-hor {
    animation-name: flip-scale-down-hor;
    animation-duration: 0.5s;
}

.flip-scale-up-ver {
    animation-name: flip-scale-up-ver;
    animation-duration: 0.5s;
}

.flip-scale-down-diag-1 {
    animation-name: flip-scale-down-diag-1;
    animation-duration: 0.5s;
}

.flip-scale-up-diag-1 {
    animation-name: flip-scale-up-diag-1;
    animation-duration: 0.5s;
}

.flip-scale-down-ver {
    animation-name: flip-scale-down-ver;
    animation-duration: 0.5s;
}

.flip-scale-down-diag-2 {
    animation-name: flip-scale-down-diag-2;
    animation-duration: 0.5s;
}

.flip-scale-up-diag-2 {
    animation-name: flip-scale-up-diag-2;
    animation-duration: 0.5s;
}

.flip-scale-2-hor-top {
    animation-name: flip-scale-2-hor-top;
    animation-duration: 0.5s;
}

.flip-scale-2-ver-left {
    animation-name: flip-scale-2-ver-left;
    animation-duration: 0.5s;
}

.flip-scale-2-hor-bottom {
    animation-name: flip-scale-2-hor-bottom;
    animation-duration: 0.5s;
}

.flip-scale-2-ver-right {
    animation-name: flip-scale-2-ver-right;
    animation-duration: 0.5s;
}

.swing-top-fwd {
    animation-name: swing-top-fwd;
    animation-duration: 0.5s;
}

.swing-top-bck {
    animation-name: swing-top-bck;
    animation-duration: 0.5s;
}

.swing-bottom-left-bck {
    animation-name: swing-bottom-left-bck;
    animation-duration: 0.5s;
}

.swing-right-bck {
    animation-name: swing-right-bck;
    animation-duration: 0.5s;
}

.swing-bottom-left-fwd {
    animation-name: swing-bottom-left-fwd;
    animation-duration: 0.5s;
}

.swing-right-fwd {
    animation-name: swing-right-fwd;
    animation-duration: 0.5s;
}

.swing-top-right-bck {
    animation-name: swing-top-right-bck;
    animation-duration: 0.5s;
}

.swing-bottom-bck {
    animation-name: swing-bottom-bck;
    animation-duration: 0.5s;
}

.swing-top-left-bck {
    animation-name: swing-top-left-bck;
    animation-duration: 0.5s;
}

.swing-top-right-fwd {
    animation-name: swing-top-right-fwd;
    animation-duration: 0.5s;
}

.swing-bottom-fwd {
    animation-name: swing-bottom-fwd;
    animation-duration: 0.5s;
}

.swing-top-left-fwd {
    animation-name: swing-top-left-fwd;
    animation-duration: 0.5s;
}

.swing-left-bck {
    animation-name: swing-left-bck;
    animation-duration: 0.5s;
}

.swing-bottom-right-bck {
    animation-name: swing-bottom-right-bck;
    animation-duration: 0.5s;
}

.swing-left-fwd {
    animation-name: swing-left-fwd;
    animation-duration: 0.5s;
}

.swing-bottom-right-fwd {
    animation-name: swing-bottom-right-fwd;
    animation-duration: 0.5s;
}

.slide-top {
    animation-name: slide-top;
    animation-duration: 0.5s;
}

.slide-right {
    animation-name: slide-right;
    animation-duration: 0.5s;
}

.slide-bottom {
    animation-name: slide-bottom;
    animation-duration: 0.5s;
}

.slide-bl {
    animation-name: slide-bl;
    animation-duration: 0.5s;
}

.slide-br {
    animation-name: slide-br;
    animation-duration: 0.5s;
}

.slide-tl {
    animation-name: slide-tl;
    animation-duration: 0.5s;
}

.slide-tr {
    animation-name: slide-tr;
    animation-duration: 0.5s;
}

.slide-left {
    animation-name: slide-left;
    animation-duration: 0.5s;
}

.slide-bck-center {
    animation-name: slide-bck-center;
    animation-duration: 0.5s;
}

.slide-bck-bottom {
    animation-name: slide-bck-bottom;
    animation-duration: 0.5s;
}

.slide-bck-br {
    animation-name: slide-bck-br;
    animation-duration: 0.5s;
}

.slide-bck-right {
    animation-name: slide-bck-right;
    animation-duration: 0.5s;
}

.slide-bck-tr {
    animation-name: slide-bck-tr;
    animation-duration: 0.5s;
}

.slide-bck-tl {
    animation-name: slide-bck-tl;
    animation-duration: 0.5s;
}

.slide-bck-top {
    animation-name: slide-bck-top;
    animation-duration: 0.5s;
}

.slide-bck-left {
    animation-name: slide-bck-left;
    animation-duration: 0.5s;
}

.slide-bck-bl {
    animation-name: slide-bck-bl;
    animation-duration: 0.5s;
}

.slide-fwd-center {
    animation-name: slide-fwd-center;
    animation-duration: 0.5s;
}

.slide-fwd-top {
    animation-name: slide-fwd-top;
    animation-duration: 0.5s;
}

.slide-fwd-tr {
    animation-name: slide-fwd-tr;
    animation-duration: 0.5s;
}

.slide-fwd-right {
    animation-name: slide-fwd-right;
    animation-duration: 0.5s;
}

.slide-fwd-br {
    animation-name: slide-fwd-br;
    animation-duration: 0.5s;
}

.slide-fwd-bottom {
    animation-name: slide-fwd-bottom;
    animation-duration: 0.5s;
}

.slide-fwd-tl {
    animation-name: slide-fwd-tl;
    animation-duration: 0.5s;
}

.slide-fwd-left {
    animation-name: slide-fwd-left;
    animation-duration: 0.5s;
}

.slide-fwd-bl {
    animation-name: slide-fwd-bl;
    animation-duration: 0.5s;
}

.slide-rotate-hor-top {
    animation-name: slide-rotate-hor-top;
    animation-duration: 0.5s;
}

.slide-rotate-hor-t-bck {
    animation-name: slide-rotate-hor-t-bck;
    animation-duration: 0.5s;
}

.slide-rotate-hor-t-fwd {
    animation-name: slide-rotate-hor-t-fwd;
    animation-duration: 0.5s;
}

.slide-rotate-ver-right {
    animation-name: slide-rotate-ver-right;
    animation-duration: 0.5s;
}

.slide-rotate-ver-r-bck {
    animation-name: slide-rotate-ver-r-bck;
    animation-duration: 0.5s;
}

.slide-rotate-ver-r-fwd {
    animation-name: slide-rotate-ver-r-fwd;
    animation-duration: 0.5s;
}

.slide-rotate-ver-l-fwd {
    animation-name: slide-rotate-ver-l-fwd;
    animation-duration: 0.5s;
}

.slide-rotate-ver-left {
    animation-name: slide-rotate-ver-left;
    animation-duration: 0.5s;
}

.slide-rotate-hor-b-fwd {
    animation-name: slide-rotate-hor-b-fwd;
    animation-duration: 0.5s;
}

.slide-rotate-hor-b-bck {
    animation-name: slide-rotate-hor-b-bck;
    animation-duration: 0.5s;
}

.slide-rotate-hor-bottom {
    animation-name: slide-rotate-hor-bottom;
    animation-duration: 0.5s;
}

.slide-rotate-ver-l-bck {
    animation-name: slide-rotate-ver-l-bck;
    animation-duration: 0.5s;
}

.shadow-drop-center {
    animation-name: shadow-drop-center;
    animation-duration: 0.5s;
}

.shadow-drop-top {
    animation-name: shadow-drop-top;
    animation-duration: 0.5s;
}

.shadow-drop-right {
    animation-name: shadow-drop-right;
    animation-duration: 0.5s;
}

.shadow-drop-bottom {
    animation-name: shadow-drop-bottom;
    animation-duration: 0.5s;
}

.shadow-drop-left {
    animation-name: shadow-drop-left;
    animation-duration: 0.5s;
}

.shadow-drop-lr {
    animation-name: shadow-drop-lr;
    animation-duration: 0.5s;
}

.shadow-drop-tl {
    animation-name: shadow-drop-tl;
    animation-duration: 0.5s;
}

.shadow-drop-bl {
    animation-name: shadow-drop-bl;
    animation-duration: 0.5s;
}

.shadow-drop-br {
    animation-name: shadow-drop-br;
    animation-duration: 0.5s;
}

.shadow-drop-tr {
    animation-name: shadow-drop-tr;
    animation-duration: 0.5s;
}

.shadow-drop-tb {
    animation-name: shadow-drop-tb;
    animation-duration: 0.5s;
}

.shadow-drop-2-center {
    animation-name: shadow-drop-2-center;
    animation-duration: 0.5s;
}

.shadow-drop-2-top {
    animation-name: shadow-drop-2-top;
    animation-duration: 0.5s;
}

.shadow-drop-2-lr {
    animation-name: shadow-drop-2-lr;
    animation-duration: 0.5s;
}

.shadow-drop-2-tl {
    animation-name: shadow-drop-2-tl;
    animation-duration: 0.5s;
}

.shadow-drop-2-left {
    animation-name: shadow-drop-2-left;
    animation-duration: 0.5s;
}

.shadow-drop-2-bottom {
    animation-name: shadow-drop-2-bottom;
    animation-duration: 0.5s;
}

.shadow-drop-2-bl {
    animation-name: shadow-drop-2-bl;
    animation-duration: 0.5s;
}

.shadow-drop-2-right {
    animation-name: shadow-drop-2-right;
    animation-duration: 0.5s;
}

.shadow-drop-2-br {
    animation-name: shadow-drop-2-br;
    animation-duration: 0.5s;
}

.shadow-drop-2-tr {
    animation-name: shadow-drop-2-tr;
    animation-duration: 0.5s;
}

.shadow-drop-2-tb {
    animation-name: shadow-drop-2-tb;
    animation-duration: 0.5s;
}

.scale-in-center {
    animation-name: scale-in-center;
    animation-duration: 0.5s;
}

.scale-in-top {
    animation-name: scale-in-top;
    animation-duration: 0.5s;
}

.scale-in-bottom {
    animation-name: scale-in-bottom;
    animation-duration: 0.5s;
}

.scale-in-hor-right {
    animation-name: scale-in-hor-right;
    animation-duration: 0.5s;
}

.scale-in-tr {
    animation-name: scale-in-tr;
    animation-duration: 0.5s;
}

.scale-in-hor-left {
    animation-name: scale-in-hor-left;
    animation-duration: 0.5s;
}

.scale-in-br {
    animation-name: scale-in-br;
    animation-duration: 0.5s;
}

.scale-in-hor-center {
    animation-name: scale-in-hor-center;
    animation-duration: 0.5s;
}

.scale-in-right {
    animation-name: scale-in-right;
    animation-duration: 0.5s;
}

.scale-in-ver-bottom {
    animation-name: scale-in-ver-bottom;
    animation-duration: 0.5s;
}

.scale-in-tl {
    animation-name: scale-in-tl;
    animation-duration: 0.5s;
}

.scale-in-ver-top {
    animation-name: scale-in-ver-top;
    animation-duration: 0.5s;
}

.scale-in-bl {
    animation-name: scale-in-bl;
    animation-duration: 0.5s;
}

.scale-in-left {
    animation-name: scale-in-left;
    animation-duration: 0.5s;
}

.scale-in-ver-center {
    animation-name: scale-in-ver-center;
    animation-duration: 0.5s;
}

.rotate-in-center {
    animation-name: rotate-in-center;
    animation-duration: 0.5s;
}

.rotate-in-top {
    animation-name: rotate-in-top;
    animation-duration: 0.5s;
}

.rotate-in-tr {
    animation-name: rotate-in-tr;
    animation-duration: 0.5s;
}

.rotate-in-right {
    animation-name: rotate-in-right;
    animation-duration: 0.5s;
}

.rotate-in-br {
    animation-name: rotate-in-br;
    animation-duration: 0.5s;
}

.rotate-in-bottom {
    animation-name: rotate-in-bottom;
    animation-duration: 0.5s;
}

.rotate-in-diag-1 {
    animation-name: rotate-in-diag-1;
    animation-duration: 0.5s;
}

.rotate-in-ver {
    animation-name: rotate-in-ver;
    animation-duration: 0.5s;
}

.rotate-in-hor {
    animation-name: rotate-in-hor;
    animation-duration: 0.5s;
}

.rotate-in-tl {
    animation-name: rotate-in-tl;
    animation-duration: 0.5s;
}

.rotate-in-left {
    animation-name: rotate-in-left;
    animation-duration: 0.5s;
}

.rotate-in-bl {
    animation-name: rotate-in-bl;
    animation-duration: 0.5s;
}

.rotate-in-diag-2 {
    animation-name: rotate-in-diag-2;
    animation-duration: 0.5s;
}

.rotate-in-2-cw {
    animation-name: rotate-in-2-cw;
    animation-duration: 0.5s;
}

.rotate-in-2-ccw {
    animation-name: rotate-in-2-ccw;
    animation-duration: 0.5s;
}

.rotate-in-2-bck-cw {
    animation-name: rotate-in-2-bck-cw;
    animation-duration: 0.5s;
}

.rotate-in-2-bck-ccw {
    animation-name: rotate-in-2-bck-ccw;
    animation-duration: 0.5s;
}

.rotate-in-2-bl-ccw {
    animation-name: rotate-in-2-bl-ccw;
    animation-duration: 0.5s;
}

.rotate-in-2-bl-cw {
    animation-name: rotate-in-2-bl-cw;
    animation-duration: 0.5s;
}

.rotate-in-2-br-ccw {
    animation-name: rotate-in-2-br-ccw;
    animation-duration: 0.5s;
}

.rotate-in-2-fwd-ccw {
    animation-name: rotate-in-2-fwd-ccw;
    animation-duration: 0.5s;
}

.rotate-in-2-fwd-cw {
    animation-name: rotate-in-2-fwd-cw;
    animation-duration: 0.5s;
}

.rotate-in-2-br-cw {
    animation-name: rotate-in-2-br-cw;
    animation-duration: 0.5s;
}

.rotate-in-2-tr-ccw {
    animation-name: rotate-in-2-tr-ccw;
    animation-duration: 0.5s;
}

.rotate-in-2-tl-ccw {
    animation-name: rotate-in-2-tl-ccw;
    animation-duration: 0.5s;
}

.rotate-in-2-tl-cw {
    animation-name: rotate-in-2-tl-cw;
    animation-duration: 0.5s;
}

.rotate-in-2-tr-cw {
    animation-name: rotate-in-2-tr-cw;
    animation-duration: 0.5s;
}

.swirl-in-fwd {
    animation-name: swirl-in-fwd;
    animation-duration: 0.5s;
}

.swirl-in-top-bck {
    animation-name: swirl-in-top-bck;
    animation-duration: 0.5s;
}

.swirl-in-tr-bck {
    animation-name: swirl-in-tr-bck;
    animation-duration: 0.5s;
}

.swirl-in-bck {
    animation-name: swirl-in-bck;
    animation-duration: 0.5s;
}

.swirl-in-top-fwd {
    animation-name: swirl-in-top-fwd;
    animation-duration: 0.5s;
}

.swirl-in-right-bck {
    animation-name: swirl-in-right-bck;
    animation-duration: 0.5s;
}

.swirl-in-bottom-bck {
    animation-name: swirl-in-bottom-bck;
    animation-duration: 0.5s;
}

.swirl-in-left-bck {
    animation-name: swirl-in-left-bck;
    animation-duration: 0.5s;
}

.swirl-in-left-fwd {
    animation-name: swirl-in-left-fwd;
    animation-duration: 0.5s;
}

.swirl-in-bottom-fwd {
    animation-name: swirl-in-bottom-fwd;
    animation-duration: 0.5s;
}

.swirl-in-right-fwd {
    animation-name: swirl-in-right-fwd;
    animation-duration: 0.5s;
}

.swirl-in-br-bck {
    animation-name: swirl-in-br-bck;
    animation-duration: 0.5s;
}

.swirl-in-bl-bck {
    animation-name: swirl-in-bl-bck;
    animation-duration: 0.5s;
}

.swirl-in-tl-bck {
    animation-name: swirl-in-tl-bck;
    animation-duration: 0.5s;
}

.swirl-in-tr-fwd {
    animation-name: swirl-in-tr-fwd;
    animation-duration: 0.5s;
}

.swirl-in-br-fwd {
    animation-name: swirl-in-br-fwd;
    animation-duration: 0.5s;
}

.swirl-in-bl-fwd {
    animation-name: swirl-in-bl-fwd;
    animation-duration: 0.5s;
}

.swirl-in-tl-fwd {
    animation-name: swirl-in-tl-fwd;
    animation-duration: 0.5s;
}

.flip-in-hor-bottom {
    animation-name: flip-in-hor-bottom;
    animation-duration: 0.5s;
}

.flip-in-hor-top {
    animation-name: flip-in-hor-top;
    animation-duration: 0.5s;
}

.flip-in-ver-left {
    animation-name: flip-in-ver-left;
    animation-duration: 0.5s;
}

.flip-in-diag-2-br {
    animation-name: flip-in-diag-2-br;
    animation-duration: 0.5s;
}

.flip-in-ver-right {
    animation-name: flip-in-ver-right;
    animation-duration: 0.5s;
}

.flip-in-diag-2-tl {
    animation-name: flip-in-diag-2-tl;
    animation-duration: 0.5s;
}

.flip-in-diag-1-bl {
    animation-name: flip-in-diag-1-bl;
    animation-duration: 0.5s;
}

.flip-in-diag-1-tr {
    animation-name: flip-in-diag-1-tr;
    animation-duration: 0.5s;
}

.slit-in-vertical {
    animation-name: slit-in-vertical;
    animation-duration: 0.5s;
}

.slit-in-horizontal {
    animation-name: slit-in-horizontal;
    animation-duration: 0.5s;
}

.slit-in-diagonal-2 {
    animation-name: slit-in-diagonal-2;
    animation-duration: 0.5s;
}

.slit-in-diagonal-1 {
    animation-name: slit-in-diagonal-1;
    animation-duration: 0.5s;
}

.slide-in-top {
    animation-name: slide-in-top;
    animation-duration: 0.5s;
}

.slide-in-br {
    animation-name: slide-in-br;
    animation-duration: 0.5s;
}

.slide-in-tl {
    animation-name: slide-in-tl;
    animation-duration: 0.5s;
}

.slide-in-right {
    animation-name: slide-in-right;
    animation-duration: 0.5s;
}

.slide-in-left {
    animation-name: slide-in-left;
    animation-duration: 0.5s;
}

.slide-in-tr {
    animation-name: slide-in-tr;
    animation-duration: 0.5s;
}

.slide-in-bl {
    animation-name: slide-in-bl;
    animation-duration: 0.5s;
}

.slide-in-bottom {
    animation-name: slide-in-bottom;
    animation-duration: 0.5s;
}

.slide-in-fwd-center {
    animation-name: slide-in-fwd-center;
    animation-duration: 0.5s;
}

.slide-in-fwd-top {
    animation-name: slide-in-fwd-top;
    animation-duration: 0.5s;
}

.slide-in-fwd-tr {
    animation-name: slide-in-fwd-tr;
    animation-duration: 0.5s;
}

.slide-in-fwd-right {
    animation-name: slide-in-fwd-right;
    animation-duration: 0.5s;
}

.slide-in-fwd-left {
    animation-name: slide-in-fwd-left;
    animation-duration: 0.5s;
}

.slide-in-fwd-bl {
    animation-name: slide-in-fwd-bl;
    animation-duration: 0.5s;
}

.slide-in-fwd-bottom {
    animation-name: slide-in-fwd-bottom;
    animation-duration: 0.5s;
}

.slide-in-fwd-tl {
    animation-name: slide-in-fwd-tl;
    animation-duration: 0.5s;
}

.slide-in-fwd-br {
    animation-name: slide-in-fwd-br;
    animation-duration: 0.5s;
}

.slide-in-bck-center {
    animation-name: slide-in-bck-center;
    animation-duration: 0.5s;
}

.slide-in-bck-top {
    animation-name: slide-in-bck-top;
    animation-duration: 0.5s;
}

.slide-in-bck-tr {
    animation-name: slide-in-bck-tr;
    animation-duration: 0.5s;
}

.slide-in-bck-right {
    animation-name: slide-in-bck-right;
    animation-duration: 0.5s;
}

.slide-in-bck-left {
    animation-name: slide-in-bck-left;
    animation-duration: 0.5s;
}

.slide-in-bck-bl {
    animation-name: slide-in-bck-bl;
    animation-duration: 0.5s;
}

.slide-in-bck-bottom {
    animation-name: slide-in-bck-bottom;
    animation-duration: 0.5s;
}

.slide-in-bck-tl {
    animation-name: slide-in-bck-tl;
    animation-duration: 0.5s;
}

.slide-in-bck-br {
    animation-name: slide-in-bck-br;
    animation-duration: 0.5s;
}

.slide-in-blurred-top {
    animation-name: slide-in-blurred-top;
    animation-duration: 0.5s;
}

.slide-in-blurred-tr {
    animation-name: slide-in-blurred-tr;
    animation-duration: 0.5s;
}

.slide-in-blurred-right {
    animation-name: slide-in-blurred-right;
    animation-duration: 0.5s;
}

.slide-in-blurred-br {
    animation-name: slide-in-blurred-br;
    animation-duration: 0.5s;
}

.slide-in-blurred-tl {
    animation-name: slide-in-blurred-tl;
    animation-duration: 0.5s;
}

.slide-in-blurred-left {
    animation-name: slide-in-blurred-left;
    animation-duration: 0.5s;
}

.slide-in-blurred-bl {
    animation-name: slide-in-blurred-bl;
    animation-duration: 0.5s;
}

.slide-in-blurred-bottom {
    animation-name: slide-in-blurred-bottom;
    animation-duration: 0.5s;
}

.slide-in-elliptic-top-fwd {
    animation-name: slide-in-elliptic-top-fwd;
    animation-duration: 0.5s;
}

.slide-in-elliptic-top-bck {
    animation-name: slide-in-elliptic-top-bck;
    animation-duration: 0.5s;
}

.slide-in-elliptic-right-fwd {
    animation-name: slide-in-elliptic-right-fwd;
    animation-duration: 0.5s;
}

.slide-in-elliptic-right-bck {
    animation-name: slide-in-elliptic-right-bck;
    animation-duration: 0.5s;
}

.slide-in-elliptic-left-bck {
    animation-name: slide-in-elliptic-left-bck;
    animation-duration: 0.5s;
}

.slide-in-elliptic-bottom-bck {
    animation-name: slide-in-elliptic-bottom-bck;
    animation-duration: 0.5s;
}

.slide-in-elliptic-bottom-fwd {
    animation-name: slide-in-elliptic-bottom-fwd;
    animation-duration: 0.5s;
}

.bounce-in-top {
    animation-name: bounce-in-top;
    animation-duration: 0.5s;
}

.bounce-in-right {
    animation-name: bounce-in-right;
    animation-duration: 0.5s;
}

.bounce-in-bottom {
    animation-name: bounce-in-bottom;
    animation-duration: 0.5s;
}

.bounce-in-left {
    animation-name: bounce-in-left;
    animation-duration: 0.5s;
}

.bounce-in-fwd {
    animation-name: bounce-in-fwd;
    animation-duration: 0.5s;
}

.bounce-in-bck {
    animation-name: bounce-in-bck;
    animation-duration: 0.5s;
}

.roll-in-left {
    animation-name: roll-in-left;
    animation-duration: 0.5s;
}

.roll-in-bottom {
    animation-name: roll-in-bottom;
    animation-duration: 0.5s;
}

.roll-in-right {
    animation-name: roll-in-right;
    animation-duration: 0.5s;
}

.roll-in-top {
    animation-name: roll-in-top;
    animation-duration: 0.5s;
}

.roll-in-blurred-left {
    animation-name: roll-in-blurred-left;
    animation-duration: 0.5s;
}

.roll-in-blurred-top {
    animation-name: roll-in-blurred-top;
    animation-duration: 0.5s;
}

.roll-in-blurred-right {
    animation-name: roll-in-blurred-right;
    animation-duration: 0.5s;
}

.roll-in-blurred-bottom {
    animation-name: roll-in-blurred-bottom;
    animation-duration: 0.5s;
}

.tilt-in-top-1 {
    animation-name: tilt-in-top-1;
    animation-duration: 0.5s;
}

.tilt-in-br {
    animation-name: tilt-in-br;
    animation-duration: 0.5s;
}

.tilt-in-tl {
    animation-name: tilt-in-tl;
    animation-duration: 0.5s;
}

.tilt-in-right-2 {
    animation-name: tilt-in-right-2;
    animation-duration: 0.5s;
}

.tilt-in-left-2 {
    animation-name: tilt-in-left-2;
    animation-duration: 0.5s;
}

.tilt-in-left-1 {
    animation-name: tilt-in-left-1;
    animation-duration: 0.5s;
}

.tilt-in-right-1 {
    animation-name: tilt-in-right-1;
    animation-duration: 0.5s;
}

.tilt-in-tr {
    animation-name: tilt-in-tr;
    animation-duration: 0.5s;
}

.tilt-in-bl {
    animation-name: tilt-in-bl;
    animation-duration: 0.5s;
}

.tilt-in-bottom-2 {
    animation-name: tilt-in-bottom-2;
    animation-duration: 0.5s;
}

.tilt-in-top-2 {
    animation-name: tilt-in-top-2;
    animation-duration: 0.5s;
}

.tilt-in-bottom-1 {
    animation-name: tilt-in-bottom-1;
    animation-duration: 0.5s;
}

.tilt-in-fwd-tr {
    animation-name: tilt-in-fwd-tr;
    animation-duration: 0.5s;
}

.tilt-in-fwd-tl {
    animation-name: tilt-in-fwd-tl;
    animation-duration: 0.5s;
}

.tilt-in-fwd-bl {
    animation-name: tilt-in-fwd-bl;
    animation-duration: 0.5s;
}

.tilt-in-fwd-br {
    animation-name: tilt-in-fwd-br;
    animation-duration: 0.5s;
}

.scale-out-center {
    animation-name: scale-out-center;
    animation-duration: 0.5s;
}

.scale-out-top {
    animation-name: scale-out-top;
    animation-duration: 0.5s;
}

.scale-out-tr {
    animation-name: scale-out-tr;
    animation-duration: 0.5s;
}

.scale-out-right {
    animation-name: scale-out-right;
    animation-duration: 0.5s;
}

.scale-out-left {
    animation-name: scale-out-left;
    animation-duration: 0.5s;
}

.scale-out-tl {
    animation-name: scale-out-tl;
    animation-duration: 0.5s;
}

.scale-out-bottom {
    animation-name: scale-out-bottom;
    animation-duration: 0.5s;
}

.scale-out-bl {
    animation-name: scale-out-bl;
    animation-duration: 0.5s;
}

.scale-out-hor-right {
    animation-name: scale-out-hor-right;
    animation-duration: 0.5s;
}

.scale-out-hor-left {
    animation-name: scale-out-hor-left;
    animation-duration: 0.5s;
}

.scale-out-ver-bottom {
    animation-name: scale-out-ver-bottom;
    animation-duration: 0.5s;
}

.scale-out-horizontal {
    animation-name: scale-out-horizontal;
    animation-duration: 0.5s;
}

.scale-out-ver-top {
    animation-name: scale-out-ver-top;
    animation-duration: 0.5s;
}

.scale-out-vertical {
    animation-name: scale-out-vertical;
    animation-duration: 0.5s;
}

.rotate-out-center {
    animation-name: rotate-out-center;
    animation-duration: 0.5s;
}

.rotate-out-top {
    animation-name: rotate-out-top;
    animation-duration: 0.5s;
}

.rotate-out-tr {
    animation-name: rotate-out-tr;
    animation-duration: 0.5s;
}

.rotate-out-right {
    animation-name: rotate-out-right;
    animation-duration: 0.5s;
}

.rotate-out-left {
    animation-name: rotate-out-left;
    animation-duration: 0.5s;
}

.rotate-out-diag-1 {
    animation-name: rotate-out-diag-1;
    animation-duration: 0.5s;
}

.rotate-out-ver {
    animation-name: rotate-out-ver;
    animation-duration: 0.5s;
}

.rotate-out-bl {
    animation-name: rotate-out-bl;
    animation-duration: 0.5s;
}

.rotate-out-hor {
    animation-name: rotate-out-hor;
    animation-duration: 0.5s;
}

.rotate-out-bottom {
    animation-name: rotate-out-bottom;
    animation-duration: 0.5s;
}

.rotate-out-tl {
    animation-name: rotate-out-tl;
    animation-duration: 0.5s;
}

.rotate-out-br {
    animation-name: rotate-out-br;
    animation-duration: 0.5s;
}

.rotate-out-diag-2 {
    animation-name: rotate-out-diag-2;
    animation-duration: 0.5s;
}

.rotate-out-2-cw {
    animation-name: rotate-out-2-cw;
    animation-duration: 0.5s;
}

.rotate-out-2-fwd {
    animation-name: rotate-out-2-fwd;
    animation-duration: 0.5s;
}

.rotate-out-2-br-ccw {
    animation-name: rotate-out-2-br-ccw;
    animation-duration: 0.5s;
}

.rotate-out-2-tl-ccw {
    animation-name: rotate-out-2-tl-ccw;
    animation-duration: 0.5s;
}

.rotate-out-2-tl-cw {
    animation-name: rotate-out-2-tl-cw;
    animation-duration: 0.5s;
}

.rotate-out-2-bck {
    animation-name: rotate-out-2-bck;
    animation-duration: 0.5s;
}

.rotate-out-2-br-cw {
    animation-name: rotate-out-2-br-cw;
    animation-duration: 0.5s;
}

.rotate-out-2-ccw {
    animation-name: rotate-out-2-ccw;
    animation-duration: 0.5s;
}

.rotate-out-2-tr-ccw {
    animation-name: rotate-out-2-tr-ccw;
    animation-duration: 0.5s;
}

.rotate-out-2-bl-ccw {
    animation-name: rotate-out-2-bl-ccw;
    animation-duration: 0.5s;
}

.rotate-out-2-tr-cw {
    animation-name: rotate-out-2-tr-cw;
    animation-duration: 0.5s;
}

.rotate-out-2-bl-cw {
    animation-name: rotate-out-2-bl-cw;
    animation-duration: 0.5s;
}

.swirl-out-bck {
    animation-name: swirl-out-bck;
    animation-duration: 0.5s;
}

.swirl-out-fwd {
    animation-name: swirl-out-fwd;
    animation-duration: 0.5s;
}

.swirl-out-top-bck {
    animation-name: swirl-out-top-bck;
    animation-duration: 0.5s;
}

.swirl-out-top-fwd {
    animation-name: swirl-out-top-fwd;
    animation-duration: 0.5s;
}

.swirl-out-right-fwd {
    animation-name: swirl-out-right-fwd;
    animation-duration: 0.5s;
}

.swirl-out-bottom-fwd {
    animation-name: swirl-out-bottom-fwd;
    animation-duration: 0.5s;
}

.swirl-out-left-fwd {
    animation-name: swirl-out-left-fwd;
    animation-duration: 0.5s;
}

.swirl-out-left-bck {
    animation-name: swirl-out-left-bck;
    animation-duration: 0.5s;
}

.swirl-out-bottom-bck {
    animation-name: swirl-out-bottom-bck;
    animation-duration: 0.5s;
}

.swirl-out-right-bck {
    animation-name: swirl-out-right-bck;
    animation-duration: 0.5s;
}

.swirl-out-br-fwd {
    animation-name: swirl-out-br-fwd;
    animation-duration: 0.5s;
}

.swirl-out-tr-fwd {
    animation-name: swirl-out-tr-fwd;
    animation-duration: 0.5s;
}

.swirl-out-bl-fwd {
    animation-name: swirl-out-bl-fwd;
    animation-duration: 0.5s;
}

.swirl-out-tl-fwd {
    animation-name: swirl-out-tl-fwd;
    animation-duration: 0.5s;
}

.swirl-out-tl-bck {
    animation-name: swirl-out-tl-bck;
    animation-duration: 0.5s;
}

.swirl-out-bl-bck {
    animation-name: swirl-out-bl-bck;
    animation-duration: 0.5s;
}

.swirl-out-br-bck {
    animation-name: swirl-out-br-bck;
    animation-duration: 0.5s;
}

.swirl-out-tr-bck {
    animation-name: swirl-out-tr-bck;
    animation-duration: 0.5s;
}

.flip-out-hor-top {
    animation-name: flip-out-hor-top;
    animation-duration: 0.5s;
}

.flip-out-hor-bottom {
    animation-name: flip-out-hor-bottom;
    animation-duration: 0.5s;
}

.flip-out-diag-2-br {
    animation-name: flip-out-diag-2-br;
    animation-duration: 0.5s;
}

.flip-out-diag-2-tl {
    animation-name: flip-out-diag-2-tl;
    animation-duration: 0.5s;
}

.flip-out-ver-left {
    animation-name: flip-out-ver-left;
    animation-duration: 0.5s;
}

.flip-out-diag-1-bl {
    animation-name: flip-out-diag-1-bl;
    animation-duration: 0.5s;
}

.flip-out-diag-1-tr {
    animation-name: flip-out-diag-1-tr;
    animation-duration: 0.5s;
}

.slit-out-vertical {
    animation-name: slit-out-vertical;
    animation-duration: 0.5s;
}

.slit-out-diagonal-2 {
    animation-name: slit-out-diagonal-2;
    animation-duration: 0.5s;
}

.slit-out-diagonal-1 {
    animation-name: slit-out-diagonal-1;
    animation-duration: 0.5s;
}

.slit-out-horizontal {
    animation-name: slit-out-horizontal;
    animation-duration: 0.5s;
}

.slide-out-top {
    animation-name: slide-out-top;
    animation-duration: 0.5s;
}

.slide-out-br {
    animation-name: slide-out-br;
    animation-duration: 0.5s;
}

.slide-out-tl {
    animation-name: slide-out-tl;
    animation-duration: 0.5s;
}

.slide-out-left {
    animation-name: slide-out-left;
    animation-duration: 0.5s;
}

.slide-out-right {
    animation-name: slide-out-right;
    animation-duration: 0.5s;
}

.slide-out-bl {
    animation-name: slide-out-bl;
    animation-duration: 0.5s;
}

.slide-out-tr {
    animation-name: slide-out-tr;
    animation-duration: 0.5s;
}

.slide-out-bottom {
    animation-name: slide-out-bottom;
    animation-duration: 0.5s;
}

.slide-out-bck-center {
    animation-name: slide-out-bck-center;
    animation-duration: 0.5s;
}

.slide-out-bck-left {
    animation-name: slide-out-bck-left;
    animation-duration: 0.5s;
}

.slide-out-bck-right {
    animation-name: slide-out-bck-right;
    animation-duration: 0.5s;
}

.slide-out-bck-bl {
    animation-name: slide-out-bck-bl;
    animation-duration: 0.5s;
}

.slide-out-bck-tr {
    animation-name: slide-out-bck-tr;
    animation-duration: 0.5s;
}

.slide-out-bck-bottom {
    animation-name: slide-out-bck-bottom;
    animation-duration: 0.5s;
}

.slide-out-bck-top {
    animation-name: slide-out-bck-top;
    animation-duration: 0.5s;
}

.slide-out-bck-tl {
    animation-name: slide-out-bck-tl;
    animation-duration: 0.5s;
}

.slide-out-bck-br {
    animation-name: slide-out-bck-br;
    animation-duration: 0.5s;
}

.slide-out-fwd-center {
    animation-name: slide-out-fwd-center;
    animation-duration: 0.5s;
}

.slide-out-fwd-left {
    animation-name: slide-out-fwd-left;
    animation-duration: 0.5s;
}

.slide-out-fwd-right {
    animation-name: slide-out-fwd-right;
    animation-duration: 0.5s;
}

.slide-out-fwd-bl {
    animation-name: slide-out-fwd-bl;
    animation-duration: 0.5s;
}

.slide-out-fwd-tr {
    animation-name: slide-out-fwd-tr;
    animation-duration: 0.5s;
}

.slide-out-fwd-bottom {
    animation-name: slide-out-fwd-bottom;
    animation-duration: 0.5s;
}

.slide-out-fwd-top {
    animation-name: slide-out-fwd-top;
    animation-duration: 0.5s;
}

.slide-out-fwd-tl {
    animation-name: slide-out-fwd-tl;
    animation-duration: 0.5s;
}

.slide-out-fwd-br {
    animation-name: slide-out-fwd-br;
    animation-duration: 0.5s;
}

.slide-out-blurred-top {
    animation-name: slide-out-blurred-top;
    animation-duration: 0.5s;
}

.slide-out-blurred-bl {
    animation-name: slide-out-blurred-bl;
    animation-duration: 0.5s;
}

.slide-out-blurred-br {
    animation-name: slide-out-blurred-br;
    animation-duration: 0.5s;
}

.slide-out-blurred-tl {
    animation-name: slide-out-blurred-tl;
    animation-duration: 0.5s;
}

.slide-out-blurred-left {
    animation-name: slide-out-blurred-left;
    animation-duration: 0.5s;
}

.slide-out-blurred-right {
    animation-name: slide-out-blurred-right;
    animation-duration: 0.5s;
}

.slide-out-blurred-tr {
    animation-name: slide-out-blurred-tr;
    animation-duration: 0.5s;
}

.slide-out-blurred-bottom {
    animation-name: slide-out-blurred-bottom;
    animation-duration: 0.5s;
}

.slide-out-elliptic-top-bck {
    animation-name: slide-out-elliptic-top-bck;
    animation-duration: 0.5s;
}

.slide-out-elliptic-right-fwd {
    animation-name: slide-out-elliptic-right-fwd;
    animation-duration: 0.5s;
}

.slide-out-elliptic-left-fwd {
    animation-name: slide-out-elliptic-left-fwd;
    animation-duration: 0.5s;
}

.slide-out-elliptic-left-bck {
    animation-name: slide-out-elliptic-left-bck;
    animation-duration: 0.5s;
}

.slide-out-elliptic-right-bck {
    animation-name: slide-out-elliptic-right-bck;
    animation-duration: 0.5s;
}

.slide-out-elliptic-bottom-fwd {
    animation-name: slide-out-elliptic-bottom-fwd;
    animation-duration: 0.5s;
}

.slide-out-elliptic-top-fwd {
    animation-name: slide-out-elliptic-top-fwd;
    animation-duration: 0.5s;
}

.slide-out-elliptic-bottom-bck {
    animation-name: slide-out-elliptic-bottom-bck;
    animation-duration: 0.5s;
}

.bounce-out-top {
    animation-name: bounce-out-top;
    animation-duration: 0.5s;
}

.bounce-out-right {
    animation-name: bounce-out-right;
    animation-duration: 0.5s;
}

.bounce-out-bottom {
    animation-name: bounce-out-bottom;
    animation-duration: 0.5s;
}

.bounce-out-left {
    animation-name: bounce-out-left;
    animation-duration: 0.5s;
}

.bounce-out-fwd {
    animation-name: bounce-out-fwd;
    animation-duration: 0.5s;
}

.bounce-out-bck {
    animation-name: bounce-out-bck;
    animation-duration: 0.5s;
}

.roll-out-left {
    animation-name: roll-out-left;
    animation-duration: 0.5s;
}

.roll-out-top {
    animation-name: roll-out-top;
    animation-duration: 0.5s;
}

.roll-out-right {
    animation-name: roll-out-right;
    animation-duration: 0.5s;
}

.roll-out-bottom {
    animation-name: roll-out-bottom;
    animation-duration: 0.5s;
}

.roll-out-blurred-left {
    animation-name: roll-out-blurred-left;
    animation-duration: 0.5s;
}

.roll-out-blurred-top {
    animation-name: roll-out-blurred-top;
    animation-duration: 0.5s;
}

.roll-out-blurred-right {
    animation-name: roll-out-blurred-right;
    animation-duration: 0.5s;
}

.roll-out-blurred-bottom {
    animation-name: roll-out-blurred-bottom;
    animation-duration: 0.5s;
}

.swing-out-top-bck {
    animation-name: swing-out-top-bck;
    animation-duration: 0.5s;
}

.swing-out-top-fwd {
    animation-name: swing-out-top-fwd;
    animation-duration: 0.5s;
}

.swing-out-right-bck {
    animation-name: swing-out-right-bck;
    animation-duration: 0.5s;
}

.swing-out-right-fwd {
    animation-name: swing-out-right-fwd;
    animation-duration: 0.5s;
}

.swing-out-left-fwd {
    animation-name: swing-out-left-fwd;
    animation-duration: 0.5s;
}

.swing-out-left-bck {
    animation-name: swing-out-left-bck;
    animation-duration: 0.5s;
}

.swing-out-bottom-fwd {
    animation-name: swing-out-bottom-fwd;
    animation-duration: 0.5s;
}

.swing-out-bottom-bck {
    animation-name: swing-out-bottom-bck;
    animation-duration: 0.5s;
}

.fade-out {
    animation-name: fade-out;
    animation-duration: 0.5s;
}

.fade-out-fwd {
    animation-name: fade-out-fwd;
    animation-duration: 0.5s;
}

.fade-out-top {
    animation-name: fade-out-top;
    animation-duration: 0.5s;
}

.fade-out-bottom {
    animation-name: fade-out-bottom;
    animation-duration: 0.5s;
}

.fade-out-tl {
    animation-name: fade-out-tl;
    animation-duration: 0.5s;
}

.fade-out-br {
    animation-name: fade-out-br;
    animation-duration: 0.5s;
}

.fade-out-left {
    animation-name: fade-out-left;
    animation-duration: 0.5s;
}

.fade-out-right {
    animation-name: fade-out-right;
    animation-duration: 0.5s;
}

.fade-out-bl {
    animation-name: fade-out-bl;
    animation-duration: 0.5s;
}

.fade-out-tr {
    animation-name: fade-out-tr;
    animation-duration: 0.5s;
}

.fade-out-bck {
    animation-name: fade-out-bck;
    animation-duration: 0.5s;
}

.puff-out-center {
    animation-name: puff-out-center;
    animation-duration: 0.5s;
}

.puff-out-right {
    animation-name: puff-out-right;
    animation-duration: 0.5s;
}

.puff-out-left {
    animation-name: puff-out-left;
    animation-duration: 0.5s;
}

.puff-out-ver {
    animation-name: puff-out-ver;
    animation-duration: 0.5s;
}

.puff-out-bl {
    animation-name: puff-out-bl;
    animation-duration: 0.5s;
}

.puff-out-tr {
    animation-name: puff-out-tr;
    animation-duration: 0.5s;
}

.puff-out-hor {
    animation-name: puff-out-hor;
    animation-duration: 0.5s;
}

.puff-out-bottom {
    animation-name: puff-out-bottom;
    animation-duration: 0.5s;
}

.puff-out-top {
    animation-name: puff-out-top;
    animation-duration: 0.5s;
}

.puff-out-tl {
    animation-name: puff-out-tl;
    animation-duration: 0.5s;
}

.puff-out-br {
    animation-name: puff-out-br;
    animation-duration: 0.5s;
}

.flicker-out-1 {
    animation-name: flicker-out-1;
    animation-duration: 0.5s;
}

.flicker-out-2 {
    animation-name: flicker-out-2;
    animation-duration: 0.5s;
}

.vibrate-1 {
    animation-name: vibrate-1;
    animation-duration: 0.5s;
}

.vibrate-2 {
    animation-name: vibrate-2;
    animation-duration: 0.5s;
}

.vibrate-3 {
    animation-name: vibrate-3;
    animation-duration: 0.5s;
}

.flicker-1 {
    animation-name: flicker-1;
    animation-duration: 0.5s;
}

.flicker-4 {
    animation-name: flicker-4;
    animation-duration: 0.5s;
}

.flicker-5 {
    animation-name: flicker-5;
    animation-duration: 0.5s;
}

.flicker-3 {
    animation-name: flicker-3;
    animation-duration: 0.5s;
}

.flicker-2 {
    animation-name: flicker-2;
    animation-duration: 0.5s;
}

.shake-horizontal {
    animation-name: shake-horizontal;
    animation-duration: 0.5s;
}

.shake-bottom {
    animation-name: shake-bottom;
    animation-duration: 0.5s;
}

.shake-top {
    animation-name: shake-top;
    animation-duration: 0.5s;
}

.shake-tl {
    animation-name: shake-tl;
    animation-duration: 0.5s;
}

.shake-br {
    animation-name: shake-br;
    animation-duration: 0.5s;
}

.shake-lr {
    animation-name: shake-lr;
    animation-duration: 0.5s;
}

.shake-left {
    animation-name: shake-left;
    animation-duration: 0.5s;
}

.shake-right {
    animation-name: shake-right;
    animation-duration: 0.5s;
}

.shake-vertical {
    animation-name: shake-vertical;
    animation-duration: 0.5s;
}

.shake-bl {
    animation-name: shake-bl;
    animation-duration: 0.5s;
}

.shake-tr {
    animation-name: shake-tr;
    animation-duration: 0.5s;
}

.jello-horizontal {
    animation-name: jello-horizontal;
    animation-duration: 0.5s;
}

.jello-diagonal-2 {
    animation-name: jello-diagonal-2;
    animation-duration: 0.5s;
}

.jello-diagonal-1 {
    animation-name: jello-diagonal-1;
    animation-duration: 0.5s;
}

.jello-vertical {
    animation-name: jello-vertical;
    animation-duration: 0.5s;
}

.wobble-hor-bottom {
    animation-name: wobble-hor-bottom;
    animation-duration: 0.5s;
}

.wobble-ver-right {
    animation-name: wobble-ver-right;
    animation-duration: 0.5s;
}

.wobble-ver-left {
    animation-name: wobble-ver-left;
    animation-duration: 0.5s;
}

.wobble-hor-top {
    animation-name: wobble-hor-top;
    animation-duration: 0.5s;
}

.bounce-top {
    animation-name: bounce-top;
    animation-duration: 0.5s;
}

.bounce-right {
    animation-name: bounce-right;
    animation-duration: 0.5s;
}

.bounce-left {
    animation-name: bounce-left;
    animation-duration: 0.5s;
}

.bounce-bottom {
    animation-name: bounce-bottom;
    animation-duration: 0.5s;
}

.heartbeat {
    animation-name: heartbeat;
    animation-duration: 0.5s;
}

.ping {
    animation-name: ping;
    animation-duration: 0.5s;
}

.pulsate-fwd {
    animation-name: pulsate-fwd;
    animation-duration: 0.5s;
}

.pulsate-bck {
    animation-name: pulsate-bck;
    animation-duration: 0.5s;
}

.blink-1 {
    animation-name: blink-1;
    animation-duration: 0.5s;
}

.blink-2 {
    animation-name: blink-2;
    animation-duration: 0.5s;
}

.kenburns-top {
    animation-name: kenburns-top;
    animation-duration: 0.5s;
}

.kenburns-bottom-right {
    animation-name: kenburns-bottom-right;
    animation-duration: 0.5s;
}

.kenburns-top-left {
    animation-name: kenburns-top-left;
    animation-duration: 0.5s;
}

.kenburns-left {
    animation-name: kenburns-left;
    animation-duration: 0.5s;
}

.kenburns-right {
    animation-name: kenburns-right;
    animation-duration: 0.5s;
}

.kenburns-bottom-left {
    animation-name: kenburns-bottom-left;
    animation-duration: 0.5s;
}

.kenburns-top-right {
    animation-name: kenburns-top-right;
    animation-duration: 0.5s;
}

.kenburns-bottom {
    animation-name: kenburns-bottom;
    animation-duration: 0.5s;
}

.bg-pan-left {
    animation-name: bg-pan-left;
    animation-duration: 0.5s;
}

.bg-pan-bottom {
    animation-name: bg-pan-bottom;
    animation-duration: 0.5s;
}

.bg-pan-tl {
    animation-name: bg-pan-tl;
    animation-duration: 0.5s;
}

.bg-pan-bl {
    animation-name: bg-pan-bl;
    animation-duration: 0.5s;
}

.bg-pan-top {
    animation-name: bg-pan-top;
    animation-duration: 0.5s;
}

.bg-pan-br {
    animation-name: bg-pan-br;
    animation-duration: 0.5s;
}

.bg-pan-right {
    animation-name: bg-pan-right;
    animation-duration: 0.5s;
}

.bg-pan-tr {
    animation-name: bg-pan-tr;
    animation-duration: 0.5s;
}

.color-change-2x {
    animation-name: color-change-2x;
    animation-duration: 0.5s;
}

.color-change-3x {
    animation-name: color-change-3x;
    animation-duration: 0.5s;
}

.color-change-4x {
    animation-name: color-change-4x;
    animation-duration: 0.5s;
}

.color-change-5x {
    animation-name: color-change-5x;
    animation-duration: 0.5s;
}




@-webkit-keyframes text-pop-up-top {
    0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

/**
 * ----------------------------------------
 * animation text-pop-up-tr
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px) translateX(50px);
            transform: translateY(-50px) translateX(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px) translateX(50px);
            transform: translateY(-50px) translateX(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

/**
 * ----------------------------------------
 * animation text-pop-up-right
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

/**
 * ----------------------------------------
 * animation text-pop-up-br
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(50px) translateX(50px);
            transform: translateY(50px) translateX(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(50px) translateX(50px);
            transform: translateY(50px) translateX(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

/**
 * ----------------------------------------
 * animation text-pop-up-bottom
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

/**
 * ----------------------------------------
 * animation text-pop-up-bl
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(50px) translateX(-50px);
            transform: translateY(50px) translateX(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(50px) translateX(-50px);
            transform: translateY(50px) translateX(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

/**
 * ----------------------------------------
 * animation text-pop-up-left
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

/**
 * ----------------------------------------
 * animation text-pop-up-tl
 * ----------------------------------------
 */
@-webkit-keyframes text-pop-up-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px) translateX(-50px);
            transform: translateY(-50px) translateX(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px) translateX(-50px);
            transform: translateY(-50px) translateX(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

/**
 * ----------------------------------------
 * animation text-shadow-pop-top
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-pop-top {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    text-shadow: 0 -1px #555555, 0 -2px #555555, 0 -3px #555555, 0 -4px #555555, 0 -5px #555555, 0 -6px #555555, 0 -7px #555555, 0 -8px #555555;
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
}
@keyframes text-shadow-pop-top {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    text-shadow: 0 -1px #555555, 0 -2px #555555, 0 -3px #555555, 0 -4px #555555, 0 -5px #555555, 0 -6px #555555, 0 -7px #555555, 0 -8px #555555;
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
}

/**
 * ----------------------------------------
 * animation text-shadow-pop-tr
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-pop-tr {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: 1px -1px #555555, 2px -2px #555555, 3px -3px #555555, 4px -4px #555555, 5px -5px #555555, 6px -6px #555555, 7px -7px #555555, 8px -8px #555555;
    -webkit-transform: translateX(-8px) translateY(8px);
            transform: translateX(-8px) translateY(8px);
  }
}
@keyframes text-shadow-pop-tr {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: 1px -1px #555555, 2px -2px #555555, 3px -3px #555555, 4px -4px #555555, 5px -5px #555555, 6px -6px #555555, 7px -7px #555555, 8px -8px #555555;
    -webkit-transform: translateX(-8px) translateY(8px);
            transform: translateX(-8px) translateY(8px);
  }
}

/**
 * ----------------------------------------
 * animation text-shadow-pop-right
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-pop-right {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    text-shadow: 1px 0 #555555, 2px 0 #555555, 3px 0 #555555, 4px 0 #555555, 5px 0 #555555, 6px 0 #555555, 7px 0 #555555, 8px 0 #555555;
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes text-shadow-pop-right {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    text-shadow: 1px 0 #555555, 2px 0 #555555, 3px 0 #555555, 4px 0 #555555, 5px 0 #555555, 6px 0 #555555, 7px 0 #555555, 8px 0 #555555;
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

/**
 * ----------------------------------------
 * animation text-shadow-pop-br
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-pop-br {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: 1px 1px #555555, 2px 2px #555555, 3px 3px #555555, 4px 4px #555555, 5px 5px #555555, 6px 6px #555555, 7px 7px #555555, 8px 8px #555555;
    -webkit-transform: translateX(-8px) translateY(-8px);
            transform: translateX(-8px) translateY(-8px);
  }
}
@keyframes text-shadow-pop-br {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: 1px 1px #555555, 2px 2px #555555, 3px 3px #555555, 4px 4px #555555, 5px 5px #555555, 6px 6px #555555, 7px 7px #555555, 8px 8px #555555;
    -webkit-transform: translateX(-8px) translateY(-8px);
            transform: translateX(-8px) translateY(-8px);
  }
}

/**
 * ----------------------------------------
 * animation text-shadow-pop-bottom
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-pop-bottom {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    text-shadow: 0 1px #555555, 0 2px #555555, 0 3px #555555, 0 4px #555555, 0 5px #555555, 0 6px #555555, 0 7px #555555, 0 8px #555555;
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
}
@keyframes text-shadow-pop-bottom {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    text-shadow: 0 1px #555555, 0 2px #555555, 0 3px #555555, 0 4px #555555, 0 5px #555555, 0 6px #555555, 0 7px #555555, 0 8px #555555;
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
}

/**
 * ----------------------------------------
 * animation text-shadow-pop-bl
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-pop-bl {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: -1px 1px #555555, -2px 2px #555555, -3px 3px #555555, -4px 4px #555555, -5px 5px #555555, -6px 6px #555555, -7px 7px #555555, -8px 8px #555555;
    -webkit-transform: translateX(8px) translateY(-8px);
            transform: translateX(8px) translateY(-8px);
  }
}
@keyframes text-shadow-pop-bl {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: -1px 1px #555555, -2px 2px #555555, -3px 3px #555555, -4px 4px #555555, -5px 5px #555555, -6px 6px #555555, -7px 7px #555555, -8px 8px #555555;
    -webkit-transform: translateX(8px) translateY(-8px);
            transform: translateX(8px) translateY(-8px);
  }
}

/**
 * ----------------------------------------
 * animation text-shadow-pop-left
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-pop-left {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    text-shadow: -1px 0 #555555, -2px 0 #555555, -3px 0 #555555, -4px 0 #555555, -5px 0 #555555, -6px 0 #555555, -7px 0 #555555, -8px 0 #555555;
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
}
@keyframes text-shadow-pop-left {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    text-shadow: -1px 0 #555555, -2px 0 #555555, -3px 0 #555555, -4px 0 #555555, -5px 0 #555555, -6px 0 #555555, -7px 0 #555555, -8px 0 #555555;
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
}

/**
 * ----------------------------------------
 * animation text-shadow-pop-tl
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-pop-tl {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: -1px -1px #555555, -2px -2px #555555, -3px -3px #555555, -4px -4px #555555, -5px -5px #555555, -6px -6px #555555, -7px -7px #555555, -8px -8px #555555;
    -webkit-transform: translateX(8px) translateY(8px);
            transform: translateX(8px) translateY(8px);
  }
}
@keyframes text-shadow-pop-tl {
  0% {
    text-shadow: 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555, 0 0 #555555;
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
  }
  100% {
    text-shadow: -1px -1px #555555, -2px -2px #555555, -3px -3px #555555, -4px -4px #555555, -5px -5px #555555, -6px -6px #555555, -7px -7px #555555, -8px -8px #555555;
    -webkit-transform: translateX(8px) translateY(8px);
            transform: translateX(8px) translateY(8px);
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-center
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-center {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-center {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 0 18px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-top
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-top {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 -6px 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-top {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 -6px 18px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-tr
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-tr {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 6px -6px 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-tr {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 6px -6px 18px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-right
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-right {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 6px 0 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-right {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 6px 0 18px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-br
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-br {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-br {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-bottom
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-bottom {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 6px 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-bottom {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: 0 6px 18px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-bl
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-bl {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: -6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-bl {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: -6px 6px 18px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-left
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-left {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: -6px 0 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-left {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: -6px 0 18px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * @animation text-shadow-drop-tl
 * ----------------------------------------
 */
@-webkit-keyframes text-shadow-drop-tl {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: -6px -6px 18px rgba(0, 0, 0, 0.35);
  }
}
@keyframes text-shadow-drop-tl {
  0% {
    text-shadow: 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    text-shadow: -6px -6px 18px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation text-flicker-in-glow
 * ----------------------------------------
 */
@-webkit-keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    text-shadow: none;
  }
  10.1% {
    opacity: 1;
    text-shadow: none;
  }
  10.2% {
    opacity: 0;
    text-shadow: none;
  }
  20% {
    opacity: 0;
    text-shadow: none;
  }
  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  20.6% {
    opacity: 0;
    text-shadow: none;
  }
  30% {
    opacity: 0;
    text-shadow: none;
  }
  30.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.6% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 0;
    text-shadow: none;
  }
  45.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55.1% {
    opacity: 0;
    text-shadow: none;
  }
  57% {
    opacity: 0;
    text-shadow: none;
  }
  57.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  60% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  60.1% {
    opacity: 0;
    text-shadow: none;
  }
  65% {
    opacity: 0;
    text-shadow: none;
  }
  65.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75.1% {
    opacity: 0;
    text-shadow: none;
  }
  77% {
    opacity: 0;
    text-shadow: none;
  }
  77.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  85% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  85.1% {
    opacity: 0;
    text-shadow: none;
  }
  86% {
    opacity: 0;
    text-shadow: none;
  }
  86.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
}
@keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    text-shadow: none;
  }
  10.1% {
    opacity: 1;
    text-shadow: none;
  }
  10.2% {
    opacity: 0;
    text-shadow: none;
  }
  20% {
    opacity: 0;
    text-shadow: none;
  }
  20.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  20.6% {
    opacity: 0;
    text-shadow: none;
  }
  30% {
    opacity: 0;
    text-shadow: none;
  }
  30.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  30.6% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 0;
    text-shadow: none;
  }
  45.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55.1% {
    opacity: 0;
    text-shadow: none;
  }
  57% {
    opacity: 0;
    text-shadow: none;
  }
  57.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  60% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  60.1% {
    opacity: 0;
    text-shadow: none;
  }
  65% {
    opacity: 0;
    text-shadow: none;
  }
  65.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  75.1% {
    opacity: 0;
    text-shadow: none;
  }
  77% {
    opacity: 0;
    text-shadow: none;
  }
  77.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  85% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  85.1% {
    opacity: 0;
    text-shadow: none;
  }
  86% {
    opacity: 0;
    text-shadow: none;
  }
  86.1% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  100% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
}

/**
 * ----------------------------------------
 * animation text-flicker-out-glow
 * ----------------------------------------
 */
@-webkit-keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 0;
    text-shadow: none;
  }
  14.9% {
    opacity: 0;
    text-shadow: none;
  }
  15% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 0;
    text-shadow: none;
  }
  24.9% {
    opacity: 0;
    text-shadow: none;
  }
  25% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 0;
    text-shadow: none;
  }
  39.9% {
    opacity: 0;
    text-shadow: none;
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 0;
    text-shadow: none;
  }
  44.9% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 0;
    text-shadow: none;
  }
  69.4% {
    opacity: 0;
    text-shadow: none;
  }
  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 0;
    text-shadow: none;
  }
  79.4% {
    opacity: 0;
    text-shadow: none;
  }
  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 0;
    text-shadow: none;
  }
  89.8% {
    opacity: 0;
    text-shadow: none;
  }
  89.9% {
    opacity: 1;
    text-shadow: none;
  }
  90% {
    opacity: 0;
    text-shadow: none;
  }
  100% {
    opacity: 0;
  }
}
@keyframes text-flicker-out-glow {
  0% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 0;
    text-shadow: none;
  }
  14.9% {
    opacity: 0;
    text-shadow: none;
  }
  15% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 0;
    text-shadow: none;
  }
  24.9% {
    opacity: 0;
    text-shadow: none;
  }
  25% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 0;
    text-shadow: none;
  }
  39.9% {
    opacity: 0;
    text-shadow: none;
  }
  40% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 0;
    text-shadow: none;
  }
  44.9% {
    opacity: 0;
    text-shadow: none;
  }
  45% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 0;
    text-shadow: none;
  }
  69.4% {
    opacity: 0;
    text-shadow: none;
  }
  69.5% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 0;
    text-shadow: none;
  }
  79.4% {
    opacity: 0;
    text-shadow: none;
  }
  79.9% {
    opacity: 1;
    text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 0;
    text-shadow: none;
  }
  89.8% {
    opacity: 0;
    text-shadow: none;
  }
  89.9% {
    opacity: 1;
    text-shadow: none;
  }
  90% {
    opacity: 0;
    text-shadow: none;
  }
  100% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation text-blur-out
 * ----------------------------------------
 */
@-webkit-keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}
@keyframes text-blur-out {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}

/**
 * ----------------------------------------
 * animation blur-out-contract
 * ----------------------------------------
 */
@-webkit-keyframes blur-out-contract {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}
@keyframes blur-out-contract {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}

/**
 * ----------------------------------------
 * animation blur-out-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes blur-out-contract-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px);
            transform: translateZ(-500px);
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}
@keyframes blur-out-contract-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px);
            transform: translateZ(-500px);
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}

/**
 * ----------------------------------------
 * animation blur-out-expand
 * ----------------------------------------
 */
@-webkit-keyframes blur-out-expand {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}
@keyframes blur-out-expand {
  0% {
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}

/**
 * ----------------------------------------
 * animation blur-out-expand-fwd
 * ----------------------------------------
 */
@-webkit-keyframes blur-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}
@keyframes blur-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: blur(0.01);
            filter: blur(0.01);
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
  }
}

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation focus-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-expand {
  0% {
    letter-spacing: -0.5em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation focus-in-expand-fwd
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-800px);
            transform: translateZ(-800px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-800px);
            transform: translateZ(-800px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation focus-in-contract
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation focus-in-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
            transform: translateZ(12px);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-out-contract
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
}
@keyframes tracking-out-contract {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation tracking-out-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-contract-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px);
            transform: translateZ(-500px);
    opacity: 0;
  }
}
@keyframes tracking-out-contract-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px);
            transform: translateZ(-500px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation tracking-out-contract-bck-top
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-contract-bck-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px) translateY(-300px);
            transform: translateZ(-500px) translateY(-300px);
    opacity: 0;
  }
}
@keyframes tracking-out-contract-bck-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px) translateY(-300px);
            transform: translateZ(-500px) translateY(-300px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation tracking-out-contract-bck-bottom
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-contract-bck-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px) translateY(300px);
            transform: translateZ(-500px) translateY(300px);
    opacity: 0;
  }
}
@keyframes tracking-out-contract-bck-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-500px) translateY(300px);
            transform: translateZ(-500px) translateY(300px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation tracking-out-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-expand {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    opacity: 0;
  }
}
@keyframes tracking-out-expand {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation tracking-out-expand-fwd
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    opacity: 0;
  }
}
@keyframes tracking-out-expand-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
            transform: translateZ(300px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation tracking-out-expand-fwd-top
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-expand-fwd-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px) translateY(-200px);
            transform: translateZ(300px) translateY(-200px);
    opacity: 0;
  }
}
@keyframes tracking-out-expand-fwd-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px) translateY(-200px);
            transform: translateZ(300px) translateY(-200px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation tracking-out-expand-fwd-bottom
 * ----------------------------------------
 */
@-webkit-keyframes tracking-out-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px) translateY(200px);
            transform: translateZ(300px) translateY(200px);
    opacity: 0;
  }
}
@keyframes tracking-out-expand-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  60% {
    opacity: 0.8;
  }
  100% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px) translateY(200px);
            transform: translateZ(300px) translateY(200px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px);
            transform: translateZ(-700px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd-top
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
            transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
            transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand-fwd-bottom
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
            transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
            transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-contract
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}
@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck-top
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
            transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-top {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(-300px);
            transform: translateZ(400px) translateY(-300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck-bottom
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
            transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation scale-up-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-top {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes scale-up-top {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-tr
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-br
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-br {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-up-br {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-bottom {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes scale-up-bottom {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-hor-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-up-hor-left {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-hor-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes scale-up-hor-center {
  0% {
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

/**
 * ----------------------------------------
 * animation scale-up-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-tl
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-tl {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-up-tl {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-ver-top {
  0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-left {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes scale-up-left {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-bl
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-bl {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes scale-up-bl {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-up-ver-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}
@keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

/**
 * ----------------------------------------
 * animation scale-down-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}
@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}

/**
 * ----------------------------------------
 * animation scale-down-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-top {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes scale-down-top {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-tr
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-tr {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-down-tr {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@keyframes scale-down-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-br
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-br {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-down-br {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-bottom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes scale-down-bottom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes scale-down-hor-right {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-left {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes scale-down-left {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-bl
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-bl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes scale-down-bl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-hor-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-down-hor-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-ver-to-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-ver-bottom {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(0.3);
            transform: scaleY(0.3);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes scale-down-ver-bottom {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(0.3);
            transform: scaleY(0.3);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-ver-top {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(0.3);
            transform: scaleY(0.3);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-down-ver-top {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scaleY(0.3);
            transform: scaleY(0.3);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-hor-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-hor-center {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
}
@keyframes scale-down-hor-center {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
}

/**
 * ----------------------------------------
 * animation scale-down-ver-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-ver-center {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(0.3);
            transform: scaleY(0.3);
  }
}
@keyframes scale-down-ver-center {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
  100% {
    -webkit-transform: scaleY(0.3);
            transform: scaleY(0.3);
  }
}

/**
 * ----------------------------------------
 * animation scale-down-tl
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-tl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes scale-down-tl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-top
 * ----------------------------------------
 */
@-webkit-keyframes rotate-top {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes rotate-top {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

/**
 * ----------------------------------------
 * animation rotate-br
 * ----------------------------------------
 */
@-webkit-keyframes rotate-br {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
}
@keyframes rotate-br {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
  }
}

/**
 * ----------------------------------------
 * animation rotate-tl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-tl {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}
@keyframes rotate-tl {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}

/**
 * ----------------------------------------
 * animation rotate-vert-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
}
@keyframes rotate-vert-center {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-diagonal-2
 * ----------------------------------------
 */
@-webkit-keyframes rotate-diagonal-2 {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 360deg);
            transform: rotate3d(-1, 1, 0, 360deg);
  }
}
@keyframes rotate-diagonal-2 {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 360deg);
            transform: rotate3d(-1, 1, 0, 360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-diagonal-tl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-diagonal-tl {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  50% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 360deg);
            transform: rotate3d(-1, 1, 0, 360deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes rotate-diagonal-tl {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  50% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 360deg);
            transform: rotate3d(-1, 1, 0, 360deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-bottom
 * ----------------------------------------
 */
@-webkit-keyframes rotate-bottom {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}
@keyframes rotate-bottom {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}

/**
 * ----------------------------------------
 * animation rotate-hor-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-hor-center {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
            transform: rotateX(-360deg);
  }
}
@keyframes rotate-hor-center {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
            transform: rotateX(-360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-vert-left
 * ----------------------------------------
 */
@-webkit-keyframes rotate-vert-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}
@keyframes rotate-vert-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}

/**
 * ----------------------------------------
 * animation rotate-diagonal-tr
 * ----------------------------------------
 */
@-webkit-keyframes rotate-diagonal-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  50% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -360deg);
            transform: rotate3d(1, 1, 0, -360deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes rotate-diagonal-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  50% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -360deg);
            transform: rotate3d(1, 1, 0, -360deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-tr
 * ----------------------------------------
 */
@-webkit-keyframes rotate-tr {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
}
@keyframes rotate-tr {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
}

/**
 * ----------------------------------------
 * animation rotate-bl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-bl {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }
}
@keyframes rotate-bl {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
  }
}

/**
 * ----------------------------------------
 * animation rotate-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes rotate-hor-top {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(-360deg);
            transform: rotateX(-360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes rotate-hor-top {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(-360deg);
            transform: rotateX(-360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

/**
 * ----------------------------------------
 * animation rotate-vert-right
 * ----------------------------------------
 */
@-webkit-keyframes rotate-vert-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}
@keyframes rotate-vert-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

/**
 * ----------------------------------------
 * animation rotate-diagonal-br
 * ----------------------------------------
 */
@-webkit-keyframes rotate-diagonal-br {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  50% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -360deg);
            transform: rotate3d(-1, 1, 0, -360deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes rotate-diagonal-br {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  50% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -360deg);
            transform: rotate3d(-1, 1, 0, -360deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-right
 * ----------------------------------------
 */
@-webkit-keyframes rotate-right {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}
@keyframes rotate-right {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}


/**
 * ----------------------------------------
 * animation rotate-left
 * ----------------------------------------
 */
@-webkit-keyframes rotate-left {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}
@keyframes rotate-left {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}

/**
 * ----------------------------------------
 * animation rotate-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes rotate-hor-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}
@keyframes rotate-hor-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}

/**
 * ----------------------------------------
 * animation rotate-diagonal-1
 * ----------------------------------------
 */
@-webkit-keyframes rotate-diagonal-1 {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -360deg);
            transform: rotate3d(1, 1, 0, -360deg);
  }
}
@keyframes rotate-diagonal-1 {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -360deg);
            transform: rotate3d(1, 1, 0, -360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-diagonal-bl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-diagonal-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  50% {
    -webkit-transform: rotate3d(1, 1, 0, 180deg);
            transform: rotate3d(1, 1, 0, 180deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 360deg);
            transform: rotate3d(1, 1, 0, 360deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes rotate-diagonal-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  50% {
    -webkit-transform: rotate3d(1, 1, 0, 180deg);
            transform: rotate3d(1, 1, 0, 180deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 360deg);
            transform: rotate3d(1, 1, 0, 360deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
            transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
            transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-down
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-down {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(0.5) rotateZ(180deg);
            transform: scale(0.5) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
  }
}
@keyframes rotate-scale-down {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
            transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(0.5) rotateZ(180deg);
            transform: scale(0.5) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
            transform: scale(1) rotateZ(360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-up-hor
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-up-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(2) rotateX(-180deg);
            transform: scale(2) rotateX(-180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-360deg);
            transform: scale(1) rotateX(-360deg);
  }
}
@keyframes rotate-scale-up-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(2) rotateX(-180deg);
            transform: scale(2) rotateX(-180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-360deg);
            transform: scale(1) rotateX(-360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-down-hor
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-down-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(0.5) rotateX(-180deg);
            transform: scale(0.5) rotateX(-180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-360deg);
            transform: scale(1) rotateX(-360deg);
  }
}
@keyframes rotate-scale-down-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(0.5) rotateX(-180deg);
            transform: scale(0.5) rotateX(-180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-360deg);
            transform: scale(1) rotateX(-360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-down-diag-1
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-down-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.5) rotate3d(1, 1, 0, -180deg);
            transform: scale(0.5) rotate3d(1, 1, 0, -180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, -360deg);
            transform: scale(1) rotate3d(1, 1, 0, -360deg);
  }
}
@keyframes rotate-scale-down-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.5) rotate3d(1, 1, 0, -180deg);
            transform: scale(0.5) rotate3d(1, 1, 0, -180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, -360deg);
            transform: scale(1) rotate3d(1, 1, 0, -360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-up-diag-1
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-up-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(2) rotate3d(1, 1, 0, -180deg);
            transform: scale(2) rotate3d(1, 1, 0, -180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, -360deg);
            transform: scale(1) rotate3d(1, 1, 0, -360deg);
  }
}
@keyframes rotate-scale-up-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(2) rotate3d(1, 1, 0, -180deg);
            transform: scale(2) rotate3d(1, 1, 0, -180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, -360deg);
            transform: scale(1) rotate3d(1, 1, 0, -360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-down-diag-2
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-down-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
            transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 360deg);
            transform: scale(1) rotate3d(-1, 1, 0, 360deg);
  }
}
@keyframes rotate-scale-down-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
            transform: scale(0.5) rotate3d(-1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 360deg);
            transform: scale(1) rotate3d(-1, 1, 0, 360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-down-ver
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-down-ver {
  0% {
    -webkit-transform: scale(1) rotateY(0);
            transform: scale(1) rotateY(0);
  }
  50% {
    -webkit-transform: scale(0.5) rotateY(180deg);
            transform: scale(0.5) rotateY(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
}
@keyframes rotate-scale-down-ver {
  0% {
    -webkit-transform: scale(1) rotateY(0);
            transform: scale(1) rotateY(0);
  }
  50% {
    -webkit-transform: scale(0.5) rotateY(180deg);
            transform: scale(0.5) rotateY(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-up-diag-2
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-up-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(2) rotate3d(-1, 1, 0, 180deg);
            transform: scale(2) rotate3d(-1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 360deg);
            transform: scale(1) rotate3d(-1, 1, 0, 360deg);
  }
}
@keyframes rotate-scale-up-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(2) rotate3d(-1, 1, 0, 180deg);
            transform: scale(2) rotate3d(-1, 1, 0, 180deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 360deg);
            transform: scale(1) rotate3d(-1, 1, 0, 360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-scale-up-ver
 * ----------------------------------------
 */
@-webkit-keyframes rotate-scale-up-ver {
  0% {
    -webkit-transform: scale(1) rotateY(0);
            transform: scale(1) rotateY(0);
  }
  50% {
    -webkit-transform: scale(2) rotateY(180deg);
            transform: scale(2) rotateY(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
}
@keyframes rotate-scale-up-ver {
  0% {
    -webkit-transform: scale(1) rotateY(0);
            transform: scale(1) rotateY(0);
  }
  50% {
    -webkit-transform: scale(2) rotateY(180deg);
            transform: scale(2) rotateY(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateY(360deg);
            transform: scale(1) rotateY(360deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
@keyframes rotate-90-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}
@keyframes rotate-90-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-tr-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-tr-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
}
@keyframes rotate-90-tr-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-br-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-br-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes rotate-90-br-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-bl-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-bl-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes rotate-90-bl-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-tl-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-tl-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes rotate-90-tl-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-vertical-fwd
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-vertical-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
  }
}
@keyframes rotate-90-vertical-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(90deg);
            transform: rotateY(90deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-tr-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-tr-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
}
@keyframes rotate-90-tr-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-br-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-br-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes rotate-90-br-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-bl-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-bl-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes rotate-90-bl-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-tl-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-tl-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes rotate-90-tl-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-vertical-bck
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-vertical-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-90deg);
            transform: rotateY(-90deg);
  }
}
@keyframes rotate-90-vertical-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-90deg);
            transform: rotateY(-90deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-top-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-top-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes rotate-90-top-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-right-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-right-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}
@keyframes rotate-90-right-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-bottom-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-bottom-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}
@keyframes rotate-90-bottom-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-left-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-left-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}
@keyframes rotate-90-left-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-horizontal-fwd
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-horizontal-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
  }
}
@keyframes rotate-90-horizontal-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(90deg);
            transform: rotateX(90deg);
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-top-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-top-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes rotate-90-top-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-right-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-right-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}
@keyframes rotate-90-right-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-bottom-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-bottom-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}
@keyframes rotate-90-bottom-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-left-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-left-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}
@keyframes rotate-90-left-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}

/**
 * ----------------------------------------
 * animation rotate-90-horizontal-bck
 * ----------------------------------------
 */
@-webkit-keyframes rotate-90-horizontal-bck {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
  }
}
@keyframes rotate-90-horizontal-bck {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-90deg);
            transform: rotateX(-90deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-horizontal-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-horizontal-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-horizontal-top {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
}
@keyframes flip-horizontal-top {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-horizontal-bck
 * ----------------------------------------
 */
@-webkit-keyframes flip-horizontal-bck {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotateX(180deg);
            transform: translateZ(-260px) rotateX(180deg);
  }
}
@keyframes flip-horizontal-bck {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotateX(180deg);
            transform: translateZ(-260px) rotateX(180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-horizontal-fwd
 * ----------------------------------------
 */
@-webkit-keyframes flip-horizontal-fwd {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) rotateX(-180deg);
            transform: translateZ(160px) rotateX(-180deg);
  }
}
@keyframes flip-horizontal-fwd {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) rotateX(-180deg);
            transform: translateZ(160px) rotateX(-180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-vertical-fwd
 * ----------------------------------------
 */
@-webkit-keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
            transform: translateZ(160px) rotateY(180deg);
  }
}
@keyframes flip-vertical-fwd {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) rotateY(180deg);
            transform: translateZ(160px) rotateY(180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-1-fwd
 * ----------------------------------------
 */
@-webkit-keyframes flip-diagonal-1-fwd {
  0% {
    -webkit-transform: translateZ(0) rotate3d(1, 1, 0, 0deg);
            transform: translateZ(0) rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: translateZ(160px) rotate3d(1, 1, 0, 180deg);
            transform: translateZ(160px) rotate3d(1, 1, 0, 180deg);
  }
}
@keyframes flip-diagonal-1-fwd {
  0% {
    -webkit-transform: translateZ(0) rotate3d(1, 1, 0, 0deg);
            transform: translateZ(0) rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: translateZ(160px) rotate3d(1, 1, 0, 180deg);
            transform: translateZ(160px) rotate3d(1, 1, 0, 180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-2-fwd
 * ----------------------------------------
 */
@-webkit-keyframes flip-diagonal-2-fwd {
  0% {
    -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
            transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: translateZ(160px) rotate3d(-1, 1, 0, 180deg);
            transform: translateZ(160px) rotate3d(-1, 1, 0, 180deg);
  }
}
@keyframes flip-diagonal-2-fwd {
  0% {
    -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
            transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: translateZ(160px) rotate3d(-1, 1, 0, 180deg);
            transform: translateZ(160px) rotate3d(-1, 1, 0, 180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-2-bck
 * ----------------------------------------
 */
@-webkit-keyframes flip-diagonal-2-bck {
  0% {
    -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
            transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotate3d(-1, 1, 0, -180deg);
            transform: translateZ(-260px) rotate3d(-1, 1, 0, -180deg);
  }
}
@keyframes flip-diagonal-2-bck {
  0% {
    -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
            transform: translateZ(0) rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotate3d(-1, 1, 0, -180deg);
            transform: translateZ(-260px) rotate3d(-1, 1, 0, -180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-1-bck
 * ----------------------------------------
 */
@-webkit-keyframes flip-diagonal-1-bck {
  0% {
    -webkit-transform: translateZ(0) rotate3d(1, 1, 0, 0deg);
            transform: translateZ(0) rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotate3d(1, 1, 0, -180deg);
            transform: translateZ(-260px) rotate3d(1, 1, 0, -180deg);
  }
}
@keyframes flip-diagonal-1-bck {
  0% {
    -webkit-transform: translateZ(0) rotate3d(1, 1, 0, 0deg);
            transform: translateZ(0) rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotate3d(1, 1, 0, -180deg);
            transform: translateZ(-260px) rotate3d(1, 1, 0, -180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-vertical-bck
 * ----------------------------------------
 */
@-webkit-keyframes flip-vertical-bck {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotateY(-180deg);
            transform: translateZ(-260px) rotateY(-180deg);
  }
}
@keyframes flip-vertical-bck {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateZ(-260px) rotateY(-180deg);
            transform: translateZ(-260px) rotateY(-180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-vertical-left
 * ----------------------------------------
 */
@-webkit-keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
}
@keyframes flip-vertical-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-1-bl
 * ----------------------------------------
 */
@-webkit-keyframes flip-diagonal-1-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
  }
}
@keyframes flip-diagonal-1-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-2-tl
 * ----------------------------------------
 */
@-webkit-keyframes flip-diagonal-2-tl {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
  }
}
@keyframes flip-diagonal-2-tl {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-2-br
 * ----------------------------------------
 */
@-webkit-keyframes flip-diagonal-2-br {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
  }
}
@keyframes flip-diagonal-2-br {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-diagonal-1-tr
 * ----------------------------------------
 */
@-webkit-keyframes flip-diagonal-1-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 180deg);
            transform: rotate3d(1, 1, 0, 180deg);
  }
}
@keyframes flip-diagonal-1-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 180deg);
            transform: rotate3d(1, 1, 0, 180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
@keyframes flip-vertical-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-2-hor-top-1
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-hor-top-1 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) rotateX(-180deg);
            transform: translateY(-100%) rotateX(-180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes flip-2-hor-top-1 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) rotateX(-180deg);
            transform: translateY(-100%) rotateX(-180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-hor-top-2
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-hor-top-2 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) rotateX(180deg);
            transform: translateY(-100%) rotateX(180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes flip-2-hor-top-2 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) rotateX(180deg);
            transform: translateY(-100%) rotateX(180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-hor-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-hor-top-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
            transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) translateZ(-260px) rotateX(180deg);
            transform: translateY(-100%) translateZ(-260px) rotateX(180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes flip-2-hor-top-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
            transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) translateZ(-260px) rotateX(180deg);
            transform: translateY(-100%) translateZ(-260px) rotateX(180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-hor-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-hor-top-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
            transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) translateZ(160px) rotateX(-180deg);
            transform: translateY(-100%) translateZ(160px) rotateX(-180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes flip-2-hor-top-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
            transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) translateZ(160px) rotateX(-180deg);
            transform: translateY(-100%) translateZ(160px) rotateX(-180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-ver-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-ver-right-fwd {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) translateZ(160px) rotateY(-180deg);
            transform: translateX(100%) translateZ(160px) rotateY(-180deg);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes flip-2-ver-right-fwd {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) translateZ(160px) rotateY(-180deg);
            transform: translateX(100%) translateZ(160px) rotateY(-180deg);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-hor-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-hor-bottom-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
            transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) translateZ(160px) rotateX(180deg);
            transform: translateY(100%) translateZ(160px) rotateX(180deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes flip-2-hor-bottom-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
            transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) translateZ(160px) rotateX(180deg);
            transform: translateY(100%) translateZ(160px) rotateX(180deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-ver-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-ver-left-fwd {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) translateZ(160px) rotateY(180deg);
            transform: translateX(-100%) translateZ(160px) rotateY(180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes flip-2-ver-left-fwd {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) translateZ(160px) rotateY(180deg);
            transform: translateX(-100%) translateZ(160px) rotateY(180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-ver-left-bck
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-ver-left-bck {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) translateZ(-260px) rotateY(-180deg);
            transform: translateX(-100%) translateZ(-260px) rotateY(-180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes flip-2-ver-left-bck {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) translateZ(-260px) rotateY(-180deg);
            transform: translateX(-100%) translateZ(-260px) rotateY(-180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-hor-bottom-bck
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-hor-bottom-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
            transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) translateZ(-260px) rotateX(-180deg);
            transform: translateY(100%) translateZ(-260px) rotateX(-180deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes flip-2-hor-bottom-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
            transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) translateZ(-260px) rotateX(-180deg);
            transform: translateY(100%) translateZ(-260px) rotateX(-180deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-ver-right-bck
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-ver-right-bck {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) translateZ(-260px) rotateY(180deg);
            transform: translateX(100%) translateZ(-260px) rotateY(180deg);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes flip-2-ver-right-bck {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) translateZ(-260px) rotateY(180deg);
            transform: translateX(100%) translateZ(-260px) rotateY(180deg);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-ver-right-2
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-ver-right-2 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) rotateY(180deg);
            transform: translateX(100%) rotateY(180deg);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes flip-2-ver-right-2 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) rotateY(180deg);
            transform: translateX(100%) rotateY(180deg);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-hor-bottom-2
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-hor-bottom-2 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) rotateX(-180deg);
            transform: translateY(100%) rotateX(-180deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes flip-2-hor-bottom-2 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) rotateX(-180deg);
            transform: translateY(100%) rotateX(-180deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-ver-left-2
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-ver-left-2 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) rotateY(-180deg);
            transform: translateX(-100%) rotateY(-180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes flip-2-ver-left-2 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) rotateY(-180deg);
            transform: translateX(-100%) rotateY(-180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-ver-left-1
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-ver-left-1 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) rotateY(180deg);
            transform: translateX(-100%) rotateY(180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes flip-2-ver-left-1 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) rotateY(180deg);
            transform: translateX(-100%) rotateY(180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-hor-bottom-1
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-hor-bottom-1 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) rotateX(180deg);
            transform: translateY(100%) rotateX(180deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes flip-2-hor-bottom-1 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) rotateX(180deg);
            transform: translateY(100%) rotateX(180deg);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}

/**
 * ----------------------------------------
 * animation flip-2-ver-right-1
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-ver-right-1 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) rotateY(-180deg);
            transform: translateX(100%) rotateY(-180deg);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes flip-2-ver-right-1 {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) rotateY(-180deg);
            transform: translateX(100%) rotateY(-180deg);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-up-hor
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-up-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(2.5) rotateX(-90deg);
            transform: scale(2.5) rotateX(-90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-180deg);
            transform: scale(1) rotateX(-180deg);
  }
}
@keyframes flip-scale-up-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(2.5) rotateX(-90deg);
            transform: scale(2.5) rotateX(-90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(-180deg);
            transform: scale(1) rotateX(-180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-down-hor
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-down-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(0.4) rotateX(90deg);
            transform: scale(0.4) rotateX(90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(180deg);
            transform: scale(1) rotateX(180deg);
  }
}
@keyframes flip-scale-down-hor {
  0% {
    -webkit-transform: scale(1) rotateX(0);
            transform: scale(1) rotateX(0);
  }
  50% {
    -webkit-transform: scale(0.4) rotateX(90deg);
            transform: scale(0.4) rotateX(90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateX(180deg);
            transform: scale(1) rotateX(180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-up-ver
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-up-ver {
  0% {
    -webkit-transform: scale(1) rotateY(0);
            transform: scale(1) rotateY(0);
  }
  50% {
    -webkit-transform: scale(2.5) rotateY(90deg);
            transform: scale(2.5) rotateY(90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateY(180deg);
            transform: scale(1) rotateY(180deg);
  }
}
@keyframes flip-scale-up-ver {
  0% {
    -webkit-transform: scale(1) rotateY(0);
            transform: scale(1) rotateY(0);
  }
  50% {
    -webkit-transform: scale(2.5) rotateY(90deg);
            transform: scale(2.5) rotateY(90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateY(180deg);
            transform: scale(1) rotateY(180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-down-diag-1
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-down-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.4) rotate3d(1, 1, 0, -90deg);
            transform: scale(0.4) rotate3d(1, 1, 0, -90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, -180deg);
            transform: scale(1) rotate3d(1, 1, 0, -180deg);
  }
}
@keyframes flip-scale-down-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.4) rotate3d(1, 1, 0, -90deg);
            transform: scale(0.4) rotate3d(1, 1, 0, -90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, -180deg);
            transform: scale(1) rotate3d(1, 1, 0, -180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-up-diag-1
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-up-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(2.5) rotate3d(1, 1, 0, 90deg);
            transform: scale(2.5) rotate3d(1, 1, 0, 90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 180deg);
            transform: scale(1) rotate3d(1, 1, 0, 180deg);
  }
}
@keyframes flip-scale-up-diag-1 {
  0% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 0deg);
            transform: scale(1) rotate3d(1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(2.5) rotate3d(1, 1, 0, 90deg);
            transform: scale(2.5) rotate3d(1, 1, 0, 90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(1, 1, 0, 180deg);
            transform: scale(1) rotate3d(1, 1, 0, 180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-down-ver
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-down-ver {
  0% {
    -webkit-transform: scale(1) rotateY(0);
            transform: scale(1) rotateY(0);
  }
  50% {
    -webkit-transform: scale(0.4) rotateY(-90deg);
            transform: scale(0.4) rotateY(-90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateY(-180deg);
            transform: scale(1) rotateY(-180deg);
  }
}
@keyframes flip-scale-down-ver {
  0% {
    -webkit-transform: scale(1) rotateY(0);
            transform: scale(1) rotateY(0);
  }
  50% {
    -webkit-transform: scale(0.4) rotateY(-90deg);
            transform: scale(0.4) rotateY(-90deg);
  }
  100% {
    -webkit-transform: scale(1) rotateY(-180deg);
            transform: scale(1) rotateY(-180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-down-diag-2
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-down-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.4) rotate3d(-1, 1, 0, -90deg);
            transform: scale(0.4) rotate3d(-1, 1, 0, -90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, -180deg);
            transform: scale(1) rotate3d(-1, 1, 0, -180deg);
  }
}
@keyframes flip-scale-down-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(0.4) rotate3d(-1, 1, 0, -90deg);
            transform: scale(0.4) rotate3d(-1, 1, 0, -90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, -180deg);
            transform: scale(1) rotate3d(-1, 1, 0, -180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-up-diag-2
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-up-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(2.5) rotate3d(-1, 1, 0, 90deg);
            transform: scale(2.5) rotate3d(-1, 1, 0, 90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 180deg);
            transform: scale(1) rotate3d(-1, 1, 0, 180deg);
  }
}
@keyframes flip-scale-up-diag-2 {
  0% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 0deg);
            transform: scale(1) rotate3d(-1, 1, 0, 0deg);
  }
  50% {
    -webkit-transform: scale(2.5) rotate3d(-1, 1, 0, 90deg);
            transform: scale(2.5) rotate3d(-1, 1, 0, 90deg);
  }
  100% {
    -webkit-transform: scale(1) rotate3d(-1, 1, 0, 180deg);
            transform: scale(1) rotate3d(-1, 1, 0, 180deg);
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-2-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-2-hor-top {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  50% {
    -webkit-transform: translateY(-50%) rotateX(-90deg) scale(2);
            transform: translateY(-50%) rotateX(-90deg) scale(2);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: translateY(-100%) rotateX(-180deg) scale(1);
            transform: translateY(-100%) rotateX(-180deg) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes flip-scale-2-hor-top {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  50% {
    -webkit-transform: translateY(-50%) rotateX(-90deg) scale(2);
            transform: translateY(-50%) rotateX(-90deg) scale(2);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: translateY(-100%) rotateX(-180deg) scale(1);
            transform: translateY(-100%) rotateX(-180deg) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-2-ver-left
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-2-ver-left {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  50% {
    -webkit-transform: translateX(-50%) rotateY(90deg) scale(2);
            transform: translateX(-50%) rotateY(90deg) scale(2);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) rotateY(180deg) scale(1);
            transform: translateX(-100%) rotateY(180deg) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}
@keyframes flip-scale-2-ver-left {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
  50% {
    -webkit-transform: translateX(-50%) rotateY(90deg) scale(2);
            transform: translateX(-50%) rotateY(90deg) scale(2);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: translateX(-100%) rotateY(180deg) scale(1);
            transform: translateX(-100%) rotateY(180deg) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-2-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-2-hor-bottom {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  50% {
    -webkit-transform: translateY(50%) rotateX(90deg) scale(2);
            transform: translateY(50%) rotateX(90deg) scale(2);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: translateY(100%) rotateX(180deg) scale(1);
            transform: translateY(100%) rotateX(180deg) scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}
@keyframes flip-scale-2-hor-bottom {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  50% {
    -webkit-transform: translateY(50%) rotateX(90deg) scale(2);
            transform: translateY(50%) rotateX(90deg) scale(2);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: translateY(100%) rotateX(180deg) scale(1);
            transform: translateY(100%) rotateX(180deg) scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
}

/**
 * ----------------------------------------
 * animation flip-scale-2-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-scale-2-ver-right {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  50% {
    -webkit-transform: translateX(50%) rotateY(-90deg) scale(2);
            transform: translateX(50%) rotateY(-90deg) scale(2);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) rotateY(-180deg) scale(1);
            transform: translateX(100%) rotateY(-180deg) scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}
@keyframes flip-scale-2-ver-right {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  50% {
    -webkit-transform: translateX(50%) rotateY(-90deg) scale(2);
            transform: translateX(50%) rotateY(-90deg) scale(2);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  100% {
    -webkit-transform: translateX(100%) rotateY(-180deg) scale(1);
            transform: translateX(100%) rotateY(-180deg) scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
  }
}

/**
 * ----------------------------------------
 * animation swing-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-top-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes swing-top-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

/**
 * ----------------------------------------
 * animation swing-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-top-bck {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes swing-top-bck {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

/**
 * ----------------------------------------
 * animation swing-bottom-left-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-bottom-left-bck {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 180deg);
            transform: rotate3d(1, 1, 0, 180deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes swing-bottom-left-bck {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 180deg);
            transform: rotate3d(1, 1, 0, 180deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation swing-right-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-right-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}
@keyframes swing-right-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

/**
 * ----------------------------------------
 * animation swing-bottom-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-bottom-left-fwd {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}
@keyframes swing-bottom-left-fwd {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation swing-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-right-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}
@keyframes swing-right-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

/**
 * ----------------------------------------
 * animation swing-top-right-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-top-right-bck {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes swing-top-right-bck {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -180deg);
            transform: rotate3d(1, 1, 0, -180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation swing-bottom-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-bottom-bck {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}
@keyframes swing-bottom-bck {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}

/**
 * ----------------------------------------
 * animation swing-top-left-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-top-left-bck {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes swing-top-left-bck {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation swing-top-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-top-right-fwd {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 180deg);
            transform: rotate3d(1, 1, 0, 180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes swing-top-right-fwd {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 180deg);
            transform: rotate3d(1, 1, 0, 180deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation swing-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-bottom-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}
@keyframes swing-bottom-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}

/**
 * ----------------------------------------
 * animation swing-top-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-top-left-fwd {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}
@keyframes swing-top-left-fwd {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation swing-left-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-left-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
}
@keyframes swing-left-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
  100% {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
}

/**
 * ----------------------------------------
 * animation swing-bottom-right-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-bottom-right-bck {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes swing-bottom-right-bck {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -180deg);
            transform: rotate3d(-1, 1, 0, -180deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation swing-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-left-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
}
@keyframes swing-left-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
  100% {
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
}

/**
 * ----------------------------------------
 * animation swing-bottom-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-bottom-right-fwd {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}
@keyframes swing-bottom-right-fwd {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 180deg);
            transform: rotate3d(-1, 1, 0, 180deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(100px) translateX(-100px);
            transform: translateY(100px) translateX(-100px);
  }
}
@keyframes slide-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(100px) translateX(-100px);
            transform: translateY(100px) translateX(-100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(100px) translateX(100px);
            transform: translateY(100px) translateX(100px);
  }
}
@keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(100px) translateX(100px);
            transform: translateY(100px) translateX(100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(-100px);
            transform: translateY(-100px) translateX(-100px);
  }
}
@keyframes slide-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(-100px);
            transform: translateY(-100px) translateX(-100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(100px);
            transform: translateY(-100px) translateX(100px);
  }
}
@keyframes slide-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-100px) translateX(100px);
            transform: translateY(-100px) translateX(100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-bck-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(-400px);
            transform: translateZ(-400px);
  }
}
@keyframes slide-bck-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(-400px);
            transform: translateZ(-400px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bck-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-bck-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(200px);
            transform: translateZ(-400px) translateY(200px);
  }
}
@keyframes slide-bck-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(200px);
            transform: translateZ(-400px) translateY(200px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bck-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-bck-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(200px) translateX(200px);
            transform: translateZ(-400px) translateY(200px) translateX(200px);
  }
}
@keyframes slide-bck-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(200px) translateX(200px);
            transform: translateZ(-400px) translateY(200px) translateX(200px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bck-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-bck-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateX(200px);
            transform: translateZ(-400px) translateX(200px);
  }
}
@keyframes slide-bck-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateX(200px);
            transform: translateZ(-400px) translateX(200px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bck-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-bck-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(-200px) translateX(200px);
            transform: translateZ(-400px) translateY(-200px) translateX(200px);
  }
}
@keyframes slide-bck-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(-200px) translateX(200px);
            transform: translateZ(-400px) translateY(-200px) translateX(200px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bck-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-bck-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(-200px) translateX(-200px);
            transform: translateZ(-400px) translateY(-200px) translateX(-200px);
  }
}
@keyframes slide-bck-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(-200px) translateX(-200px);
            transform: translateZ(-400px) translateY(-200px) translateX(-200px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bck-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-bck-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(-200px);
            transform: translateZ(-400px) translateY(-200px);
  }
}
@keyframes slide-bck-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(-200px);
            transform: translateZ(-400px) translateY(-200px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bck-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-bck-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateX(-200px);
            transform: translateZ(-400px) translateX(-200px);
  }
}
@keyframes slide-bck-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateX(-200px);
            transform: translateZ(-400px) translateX(-200px);
  }
}

/**
 * ----------------------------------------
 * animation slide-bck-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-bck-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(200px) translateX(-200px);
            transform: translateZ(-400px) translateY(200px) translateX(-200px);
  }
}
@keyframes slide-bck-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(-400px) translateY(200px) translateX(-200px);
            transform: translateZ(-400px) translateY(200px) translateX(-200px);
  }
}

/**
 * ----------------------------------------
 * animation slide-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
            transform: translateZ(160px);
  }
}
@keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
            transform: translateZ(160px);
  }
}

/**
 * ----------------------------------------
 * animation slide-fwd-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(-100px);
            transform: translateZ(160px) translateY(-100px);
  }
}
@keyframes slide-fwd-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(-100px);
            transform: translateZ(160px) translateY(-100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(-100px) translateX(100px);
            transform: translateZ(160px) translateY(-100px) translateX(100px);
  }
}
@keyframes slide-fwd-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(-100px) translateX(100px);
            transform: translateZ(160px) translateY(-100px) translateX(100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-fwd-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateX(100px);
            transform: translateZ(160px) translateX(100px);
  }
}
@keyframes slide-fwd-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateX(100px);
            transform: translateZ(160px) translateX(100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-fwd-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(100px) translateX(100px);
            transform: translateZ(160px) translateY(100px) translateX(100px);
  }
}
@keyframes slide-fwd-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(100px) translateX(100px);
            transform: translateZ(160px) translateY(100px) translateX(100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-fwd-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(100px);
            transform: translateZ(160px) translateY(100px);
  }
}
@keyframes slide-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(100px);
            transform: translateZ(160px) translateY(100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-fwd-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(-100px) translateX(-100px);
            transform: translateZ(160px) translateY(-100px) translateX(-100px);
  }
}
@keyframes slide-fwd-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(-100px) translateX(-100px);
            transform: translateZ(160px) translateY(-100px) translateX(-100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-fwd-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateX(-100px);
            transform: translateZ(160px) translateX(-100px);
  }
}
@keyframes slide-fwd-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateX(-100px);
            transform: translateZ(160px) translateX(-100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-fwd-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-fwd-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(100px) translateX(-100px);
            transform: translateZ(160px) translateY(100px) translateX(-100px);
  }
}
@keyframes slide-fwd-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateZ(160px) translateY(100px) translateX(-100px);
            transform: translateZ(160px) translateY(100px) translateX(-100px);
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-hor-top {
  0% {
    -webkit-transform: translateY(0) rotateX(0deg);
            transform: translateY(0) rotateX(0deg);
  }
  100% {
    -webkit-transform: translateY(-150px) rotateX(-90deg);
            transform: translateY(-150px) rotateX(-90deg);
  }
}
@keyframes slide-rotate-hor-top {
  0% {
    -webkit-transform: translateY(0) rotateX(0deg);
            transform: translateY(0) rotateX(0deg);
  }
  100% {
    -webkit-transform: translateY(-150px) rotateX(-90deg);
            transform: translateY(-150px) rotateX(-90deg);
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-hor-t-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-hor-t-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
  100% {
    -webkit-transform: translateY(-150px) translateZ(-230px) rotateX(-90deg);
            transform: translateY(-150px) translateZ(-230px) rotateX(-90deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
}
@keyframes slide-rotate-hor-t-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
  100% {
    -webkit-transform: translateY(-150px) translateZ(-230px) rotateX(-90deg);
            transform: translateY(-150px) translateZ(-230px) rotateX(-90deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-hor-t-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-hor-t-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
  }
  100% {
    -webkit-transform: translateY(-150px) translateZ(130px) rotateX(-90deg);
            transform: translateY(-150px) translateZ(130px) rotateX(-90deg);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
  }
}
@keyframes slide-rotate-hor-t-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
  }
  100% {
    -webkit-transform: translateY(-150px) translateZ(130px) rotateX(-90deg);
            transform: translateY(-150px) translateZ(130px) rotateX(-90deg);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-ver-right {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateX(150px) rotateY(-90deg);
            transform: translateX(150px) rotateY(-90deg);
  }
}
@keyframes slide-rotate-ver-right {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateX(150px) rotateY(-90deg);
            transform: translateX(150px) rotateY(-90deg);
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-ver-r-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-ver-r-bck {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: center right;
            transform-origin: center right;
  }
  100% {
    -webkit-transform: translateX(150px) translateZ(-230px) rotateY(-90deg);
            transform: translateX(150px) translateZ(-230px) rotateY(-90deg);
    -webkit-transform-origin: center right;
            transform-origin: center right;
  }
}
@keyframes slide-rotate-ver-r-bck {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: center right;
            transform-origin: center right;
  }
  100% {
    -webkit-transform: translateX(150px) translateZ(-230px) rotateY(-90deg);
            transform: translateX(150px) translateZ(-230px) rotateY(-90deg);
    -webkit-transform-origin: center right;
            transform-origin: center right;
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-ver-r-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-ver-r-fwd {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
  100% {
    -webkit-transform: translateX(150px) translateZ(130px) rotateY(-90deg);
            transform: translateX(150px) translateZ(130px) rotateY(-90deg);
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
}
@keyframes slide-rotate-ver-r-fwd {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
  100% {
    -webkit-transform: translateX(150px) translateZ(130px) rotateY(-90deg);
            transform: translateX(150px) translateZ(130px) rotateY(-90deg);
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-ver-l-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-ver-l-fwd {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: center right;
            transform-origin: center right;
  }
  100% {
    -webkit-transform: translateX(-150px) translateZ(130px) rotateY(90deg);
            transform: translateX(-150px) translateZ(130px) rotateY(90deg);
    -webkit-transform-origin: center right;
            transform-origin: center right;
  }
}
@keyframes slide-rotate-ver-l-fwd {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: center right;
            transform-origin: center right;
  }
  100% {
    -webkit-transform: translateX(-150px) translateZ(130px) rotateY(90deg);
            transform: translateX(-150px) translateZ(130px) rotateY(90deg);
    -webkit-transform-origin: center right;
            transform-origin: center right;
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-ver-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-ver-left {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateX(-150px) rotateY(90deg);
            transform: translateX(-150px) rotateY(90deg);
  }
}
@keyframes slide-rotate-ver-left {
  0% {
    -webkit-transform: translateX(0) rotateY(0);
            transform: translateX(0) rotateY(0);
  }
  100% {
    -webkit-transform: translateX(-150px) rotateY(90deg);
            transform: translateX(-150px) rotateY(90deg);
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-hor-b-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-hor-b-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
  100% {
    -webkit-transform: translateY(150px) translateZ(130px) rotateX(90deg);
            transform: translateY(150px) translateZ(130px) rotateX(90deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
}
@keyframes slide-rotate-hor-b-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
  100% {
    -webkit-transform: translateY(150px) translateZ(130px) rotateX(90deg);
            transform: translateY(150px) translateZ(130px) rotateX(90deg);
    -webkit-transform-origin: top center;
            transform-origin: top center;
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-hor-b-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-hor-b-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
  }
  100% {
    -webkit-transform: translateY(150px) translateZ(-230px) rotateX(90deg);
            transform: translateY(150px) translateZ(-230px) rotateX(90deg);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
  }
}
@keyframes slide-rotate-hor-b-bck {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0deg);
            transform: translateY(0) translateZ(0) rotateX(0deg);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
  }
  100% {
    -webkit-transform: translateY(150px) translateZ(-230px) rotateX(90deg);
            transform: translateY(150px) translateZ(-230px) rotateX(90deg);
    -webkit-transform-origin: bottom center;
            transform-origin: bottom center;
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-hor-bottom {
  0% {
    -webkit-transform: translateY(0) rotateX(0deg);
            transform: translateY(0) rotateX(0deg);
  }
  100% {
    -webkit-transform: translateY(150px) rotateX(90deg);
            transform: translateY(150px) rotateX(90deg);
  }
}
@keyframes slide-rotate-hor-bottom {
  0% {
    -webkit-transform: translateY(0) rotateX(0deg);
            transform: translateY(0) rotateX(0deg);
  }
  100% {
    -webkit-transform: translateY(150px) rotateX(90deg);
            transform: translateY(150px) rotateX(90deg);
  }
}

/**
 * ----------------------------------------
 * animation slide-rotate-ver-l-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-rotate-ver-l-bck {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
  100% {
    -webkit-transform: translateX(-150px) translateZ(-230px) rotateY(90deg);
            transform: translateX(-150px) translateZ(-230px) rotateY(90deg);
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
}
@keyframes slide-rotate-ver-l-bck {
  0% {
    -webkit-transform: translateX(0) translateZ(0) rotateY(0);
            transform: translateX(0) translateZ(0) rotateY(0);
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
  100% {
    -webkit-transform: translateX(-150px) translateZ(-230px) rotateY(90deg);
            transform: translateX(-150px) translateZ(-230px) rotateY(90deg);
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-center
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-center {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-top
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-top {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-top {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-right
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-right {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-right {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-bottom
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-bottom {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-bottom {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-left
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-left {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-left {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-lr
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-lr {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35), 12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35), 12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-lr {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35), 12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35), 12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-tl
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-tl {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -12px -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-tl {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -12px -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-bl
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-bl {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-bl {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-br
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-br {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-br {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-tr
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-tr {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 12px -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-tr {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 12px -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-tb
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-tb {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35), 0 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35), 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-tb {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35), 0 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35), 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-center
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
            box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-top
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateY(12px);
            transform: translateZ(50px) translateY(12px);
    -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-top {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateY(12px);
            transform: translateZ(50px) translateY(12px);
    -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-lr
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-lr {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35), 12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35), 12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-lr {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35), 12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35), 12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-tl
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-tl {
  0% {
    -webkit-transform: translateZ(0) translateX(0) translateY(0);
            transform: translateZ(0) translateX(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(12px) translateY(12px);
            transform: translateZ(50px) translateX(12px) translateY(12px);
    -webkit-box-shadow: -12px -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-tl {
  0% {
    -webkit-transform: translateZ(0) translateX(0) translateY(0);
            transform: translateZ(0) translateX(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(12px) translateY(12px);
            transform: translateZ(50px) translateX(12px) translateY(12px);
    -webkit-box-shadow: -12px -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-left
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(12px);
            transform: translateZ(50px) translateX(12px);
    -webkit-box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(12px);
            transform: translateZ(50px) translateX(12px);
    -webkit-box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-bottom
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateY(-12px);
            transform: translateZ(50px) translateY(-12px);
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateY(-12px);
            transform: translateZ(50px) translateY(-12px);
    -webkit-box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-bl
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-bl {
  0% {
    -webkit-transform: translateZ(0) translateX(0) translateY(0);
            transform: translateZ(0) translateX(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(12px) translateY(-12px);
            transform: translateZ(50px) translateX(12px) translateY(-12px);
    -webkit-box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-bl {
  0% {
    -webkit-transform: translateZ(0) translateX(0) translateY(0);
            transform: translateZ(0) translateX(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(12px) translateY(-12px);
            transform: translateZ(50px) translateX(12px) translateY(-12px);
    -webkit-box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: -12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-right
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(-12px);
            transform: translateZ(50px) translateX(-12px);
    -webkit-box-shadow: 12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(-12px);
            transform: translateZ(50px) translateX(-12px);
    -webkit-box-shadow: 12px 0 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px 0 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-br
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-br {
  0% {
    -webkit-transform: translateZ(0) translateX(0) translateY(0);
            transform: translateZ(0) translateX(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(-12px) translateY(-12px);
            transform: translateZ(50px) translateX(-12px) translateY(-12px);
    -webkit-box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-br {
  0% {
    -webkit-transform: translateZ(0) translateX(0) translateY(0);
            transform: translateZ(0) translateX(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(-12px) translateY(-12px);
            transform: translateZ(50px) translateX(-12px) translateY(-12px);
    -webkit-box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-tr
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-tr {
  0% {
    -webkit-transform: translateZ(0) translateX(0) translateY(0);
            transform: translateZ(0) translateX(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(-12px) translateY(12px);
            transform: translateZ(50px) translateX(-12px) translateY(12px);
    -webkit-box-shadow: 12px -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-tr {
  0% {
    -webkit-transform: translateZ(0) translateX(0) translateY(0);
            transform: translateZ(0) translateX(0) translateY(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px) translateX(-12px) translateY(12px);
            transform: translateZ(50px) translateX(-12px) translateY(12px);
    -webkit-box-shadow: 12px -12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 12px -12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation shadow-drop-2-tb
 * ----------------------------------------
 */
@-webkit-keyframes shadow-drop-2-tb {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35), 0 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35), 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}
@keyframes shadow-drop-2-tb {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
            box-shadow: 0 0 0 0 rgba(0, 0, 0, 0), 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-transform: translateZ(50px);
            transform: translateZ(50px);
    -webkit-box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35), 0 12px 20px -12px rgba(0, 0, 0, 0.35);
            box-shadow: 0 -12px 20px -12px rgba(0, 0, 0, 0.35), 0 12px 20px -12px rgba(0, 0, 0, 0.35);
  }
}

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-top {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bottom {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-right {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-tr
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-tr {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-hor-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-br
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-br {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-br {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-hor-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1;
  }
}
@keyframes scale-in-hor-center {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-right {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
}
@keyframes scale-in-right {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-bottom {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-tl
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-tl {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-tl {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-bl
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-bl {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bl {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-left {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 1;
  }
}
@keyframes scale-in-left {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-in-ver-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1;
  }
}
@keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-center {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-top
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-top {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes rotate-in-top {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-tr
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-tr {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    opacity: 1;
  }
}
@keyframes rotate-in-tr {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-right
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-right {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
}
@keyframes rotate-in-right {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-br
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-br {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    opacity: 1;
  }
}
@keyframes rotate-in-br {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-bottom {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
}
@keyframes rotate-in-bottom {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-diag-1
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-diag-1 {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, -360deg);
            transform: rotate3d(1, 1, 0, -360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}
@keyframes rotate-in-diag-1 {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, -360deg);
            transform: rotate3d(1, 1, 0, -360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-ver
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-ver {
  0% {
    -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    opacity: 1;
  }
}
@keyframes rotate-in-ver {
  0% {
    -webkit-transform: rotateY(-360deg);
            transform: rotateY(-360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-hor
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-hor {
  0% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 1;
  }
}
@keyframes rotate-in-hor {
  0% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-tl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-tl {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    opacity: 1;
  }
}
@keyframes rotate-in-tl {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-left
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-left {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
}
@keyframes rotate-in-left {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-bl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-bl {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    opacity: 1;
  }
}
@keyframes rotate-in-bl {
  0% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-diag-2
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-diag-2 {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, -360deg);
            transform: rotate3d(-1, 1, 0, -360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    opacity: 1;
  }
}
@keyframes rotate-in-diag-2 {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, -360deg);
            transform: rotate3d(-1, 1, 0, -360deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-2-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-2-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-bck-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-bck-cw {
  0% {
    -webkit-transform: translateZ(200px) rotate(-45deg);
            transform: translateZ(200px) rotate(-45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-2-bck-cw {
  0% {
    -webkit-transform: translateZ(200px) rotate(-45deg);
            transform: translateZ(200px) rotate(-45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-bck-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-bck-ccw {
  0% {
    -webkit-transform: translateZ(200px) rotate(45deg);
            transform: translateZ(200px) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-2-bck-ccw {
  0% {
    -webkit-transform: translateZ(200px) rotate(45deg);
            transform: translateZ(200px) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-bl-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-bl-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
}
@keyframes rotate-in-2-bl-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-bl-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-bl-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
}
@keyframes rotate-in-2-bl-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-br-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-br-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes rotate-in-2-br-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-fwd-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-fwd-ccw {
  0% {
    -webkit-transform: translateZ(-200px) rotate(45deg);
            transform: translateZ(-200px) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-2-fwd-ccw {
  0% {
    -webkit-transform: translateZ(-200px) rotate(45deg);
            transform: translateZ(-200px) rotate(45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-fwd-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-fwd-cw {
  0% {
    -webkit-transform: translateZ(-200px) rotate(-45deg);
            transform: translateZ(-200px) rotate(-45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}
@keyframes rotate-in-2-fwd-cw {
  0% {
    -webkit-transform: translateZ(-200px) rotate(-45deg);
            transform: translateZ(-200px) rotate(-45deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-br-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-br-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes rotate-in-2-br-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-tr-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-tr-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes rotate-in-2-tr-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-tl-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-tl-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes rotate-in-2-tl-ccw {
  0% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-tl-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-tl-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes rotate-in-2-tl-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-in-2-tr-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-in-2-tr-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes rotate-in-2-tr-cw {
  0% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
}
@keyframes swirl-in-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-top-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    opacity: 1;
  }
}
@keyframes swirl-in-top-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-tr-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-tr-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes swirl-in-tr-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
}
@keyframes swirl-in-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-top-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    opacity: 1;
  }
}
@keyframes swirl-in-top-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-right-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-right-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
}
@keyframes swirl-in-right-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-bottom-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-bottom-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes swirl-in-bottom-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-left-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-left-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 1;
  }
}
@keyframes swirl-in-left-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-left-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 1;
  }
}
@keyframes swirl-in-left-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-bottom-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes swirl-in-bottom-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-right-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
}
@keyframes swirl-in-right-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-br-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-br-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes swirl-in-br-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-bl-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-bl-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
}
@keyframes swirl-in-bl-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-tl-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-tl-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes swirl-in-tl-bck {
  0% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-tr-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-tr-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes swirl-in-tr-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-br-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-br-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes swirl-in-br-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-bl-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-bl-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
}
@keyframes swirl-in-bl-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swirl-in-tl-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-in-tl-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes swirl-in-tl-fwd {
  0% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
            transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-top {
  0% {
    -webkit-transform: rotateX(-80deg);
            transform: rotateX(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-ver-left
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-ver-left {
  0% {
    -webkit-transform: rotateY(80deg);
            transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}
@keyframes flip-in-ver-left {
  0% {
    -webkit-transform: rotateY(80deg);
            transform: rotateY(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-diag-2-br
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-diag-2-br {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, -80deg);
            transform: rotate3d(-1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}
@keyframes flip-in-diag-2-br {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, -80deg);
            transform: rotate3d(-1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
            transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}
@keyframes flip-in-ver-right {
  0% {
    -webkit-transform: rotateY(-80deg);
            transform: rotateY(-80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-diag-2-tl
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-diag-2-tl {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 80deg);
            transform: rotate3d(-1, 1, 0, 80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}
@keyframes flip-in-diag-2-tl {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 80deg);
            transform: rotate3d(-1, 1, 0, 80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-diag-1-bl
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-diag-1-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 80deg);
            transform: rotate3d(1, 1, 0, 80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}
@keyframes flip-in-diag-1-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 80deg);
            transform: rotate3d(1, 1, 0, 80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flip-in-diag-1-tr
 * ----------------------------------------
 */
@-webkit-keyframes flip-in-diag-1-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, -80deg);
            transform: rotate3d(1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}
@keyframes flip-in-diag-1-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, -80deg);
            transform: rotate3d(1, 1, 0, -80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slit-in-vertical
 * ----------------------------------------
 */
@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}
@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
  }
}

/**
 * ----------------------------------------
 * animation slit-in-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes slit-in-horizontal {
  0% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
            transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
            transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
  }
}
@keyframes slit-in-horizontal {
  0% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
            transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
            transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
  }
}

/**
 * ----------------------------------------
 * animation slit-in-diagonal-2
 * ----------------------------------------
 */
@-webkit-keyframes slit-in-diagonal-2 {
  0% {
    -webkit-transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
            transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
            transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0);
            transform: translateZ(0) rotate3d(-1, 1, 0, 0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes slit-in-diagonal-2 {
  0% {
    -webkit-transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
            transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
            transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0);
            transform: translateZ(0) rotate3d(-1, 1, 0, 0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation slit-in-diagonal-1
 * ----------------------------------------
 */
@-webkit-keyframes slit-in-diagonal-1 {
  0% {
    -webkit-transform: translateZ(-800px) rotate3d(1, 1, 0, 90deg);
            transform: translateZ(-800px) rotate3d(1, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotate3d(1, 1, 0, 87deg);
            transform: translateZ(-160px) rotate3d(1, 1, 0, 87deg);
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotate3d(1, 1, 0, 0);
            transform: translateZ(0) rotate3d(1, 1, 0, 0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes slit-in-diagonal-1 {
  0% {
    -webkit-transform: translateZ(-800px) rotate3d(1, 1, 0, 90deg);
            transform: translateZ(-800px) rotate3d(1, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotate3d(1, 1, 0, 87deg);
            transform: translateZ(-160px) rotate3d(1, 1, 0, 87deg);
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotate3d(1, 1, 0, 0);
            transform: translateZ(0) rotate3d(1, 1, 0, 0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-br {
  0% {
    -webkit-transform: translateY(1000px) translateX(1000px);
            transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-br {
  0% {
    -webkit-transform: translateY(1000px) translateX(1000px);
            transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-tl {
  0% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
            transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-tl {
  0% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
            transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-tr {
  0% {
    -webkit-transform: translateY(-1000px) translateX(1000px);
            transform: translateY(-1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-tr {
  0% {
    -webkit-transform: translateY(-1000px) translateX(1000px);
            transform: translateY(-1000px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bl {
  0% {
    -webkit-transform: translateY(1000px) translateX(-1000px);
            transform: translateY(1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bl {
  0% {
    -webkit-transform: translateY(1000px) translateX(-1000px);
            transform: translateY(1000px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
            transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-top {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px);
            transform: translateZ(-1400px) translateY(-800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-top {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px);
            transform: translateZ(-1400px) translateY(-800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-tr {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
            transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-tr {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
            transform: translateZ(-1400px) translateY(-800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
            transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
            transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(-1000px);
            transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-left {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(-1000px);
            transform: translateZ(-1400px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-bl {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(-1000px);
            transform: translateZ(-1400px) translateY(800px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-bl {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(-1000px);
            transform: translateZ(-1400px) translateY(800px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px);
            transform: translateZ(-1400px) translateY(800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-bottom {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px);
            transform: translateZ(-1400px) translateY(800px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-tl {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(-1000px);
            transform: translateZ(-1400px) translateY(-800px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-tl {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(-800px) translateX(-1000px);
            transform: translateZ(-1400px) translateY(-800px) translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-br {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(1000px);
            transform: translateZ(-1400px) translateY(800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-br {
  0% {
    -webkit-transform: translateZ(-1400px) translateY(800px) translateX(1000px);
            transform: translateZ(-1400px) translateY(800px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-center {
  0% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-top {
  0% {
    -webkit-transform: translateZ(700px) translateY(-300px);
            transform: translateZ(700px) translateY(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-top {
  0% {
    -webkit-transform: translateZ(700px) translateY(-300px);
            transform: translateZ(700px) translateY(-300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-tr {
  0% {
    -webkit-transform: translateZ(700px) translateY(-300px) translateX(400px);
            transform: translateZ(700px) translateY(-300px) translateX(400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-tr {
  0% {
    -webkit-transform: translateZ(700px) translateY(-300px) translateX(400px);
            transform: translateZ(700px) translateY(-300px) translateX(400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-right {
  0% {
    -webkit-transform: translateZ(700px) translateX(400px);
            transform: translateZ(700px) translateX(400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-right {
  0% {
    -webkit-transform: translateZ(700px) translateX(400px);
            transform: translateZ(700px) translateX(400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-left {
  0% {
    -webkit-transform: translateZ(700px) translateX(-400px);
            transform: translateZ(700px) translateX(-400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-left {
  0% {
    -webkit-transform: translateZ(700px) translateX(-400px);
            transform: translateZ(700px) translateX(-400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-bl {
  0% {
    -webkit-transform: translateZ(700px) translateY(300px) translateX(-400px);
            transform: translateZ(700px) translateY(300px) translateX(-400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-bl {
  0% {
    -webkit-transform: translateZ(700px) translateY(300px) translateX(-400px);
            transform: translateZ(700px) translateY(300px) translateX(-400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-bottom {
  0% {
    -webkit-transform: translateZ(700px) translateY(300px);
            transform: translateZ(700px) translateY(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-bottom {
  0% {
    -webkit-transform: translateZ(700px) translateY(300px);
            transform: translateZ(700px) translateY(300px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-tl {
  0% {
    -webkit-transform: translateZ(700px) translateY(-300px) translateX(-400px);
            transform: translateZ(700px) translateY(-300px) translateX(-400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-tl {
  0% {
    -webkit-transform: translateZ(700px) translateY(-300px) translateX(-400px);
            transform: translateZ(700px) translateY(-300px) translateX(-400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-bck-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bck-br {
  0% {
    -webkit-transform: translateZ(700px) translateY(300px) translateX(400px);
            transform: translateZ(700px) translateY(300px) translateX(400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-bck-br {
  0% {
    -webkit-transform: translateZ(700px) translateY(300px) translateX(400px);
            transform: translateZ(700px) translateY(300px) translateX(400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-top {
  0% {
    -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-tr {
  0% {
    -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
            transform: translate(1000px, -1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-tr {
  0% {
    -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
            transform: translate(1000px, -1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-br {
  0% {
    -webkit-transform: translate(1000px, 1000px) skew(80deg, 10deg);
            transform: translate(1000px, 1000px) skew(80deg, 10deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-br {
  0% {
    -webkit-transform: translate(1000px, 1000px) skew(80deg, 10deg);
            transform: translate(1000px, 1000px) skew(80deg, 10deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-tl {
  0% {
    -webkit-transform: translate(-1000px, -1000px) skew(80deg, 10deg);
            transform: translate(-1000px, -1000px) skew(80deg, 10deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-tl {
  0% {
    -webkit-transform: translate(-1000px, -1000px) skew(80deg, 10deg);
            transform: translate(-1000px, -1000px) skew(80deg, 10deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-bl {
  0% {
    -webkit-transform: translate(-1000px, 1000px) skew(-80deg, -10deg);
            transform: translate(-1000px, 1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-bl {
  0% {
    -webkit-transform: translate(-1000px, 1000px) skew(-80deg, -10deg);
            transform: translate(-1000px, 1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
            transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-top-bck {
  0% {
    -webkit-transform: translateY(-600px) rotateX(30deg) scale(6.5);
            transform: translateY(-600px) rotateX(30deg) scale(6.5);
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -500px;
            transform-origin: 50% -500px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-top-bck {
  0% {
    -webkit-transform: translateY(-600px) rotateX(30deg) scale(6.5);
            transform: translateY(-600px) rotateX(30deg) scale(6.5);
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -500px;
            transform-origin: 50% -500px;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-right-fwd {
  0% {
    -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
            transform: translateX(800px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
            transform-origin: -1800px 50%;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-right-fwd {
  0% {
    -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
            transform: translateX(800px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
            transform-origin: -1800px 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-right-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-right-bck {
  0% {
    -webkit-transform: translateX(800px) rotateY(30deg) scale(6.5);
            transform: translateX(800px) rotateY(30deg) scale(6.5);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 600px 50%;
            transform-origin: 600px 50%;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-right-bck {
  0% {
    -webkit-transform: translateX(800px) rotateY(30deg) scale(6.5);
            transform: translateX(800px) rotateY(30deg) scale(6.5);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 600px 50%;
            transform-origin: 600px 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * @animation slide-in-elliptic-left-fwd 
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
            transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
            transform-origin: 1800px 50%;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
            transform: translateX(-800px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 1800px 50%;
            transform-origin: 1800px 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-left-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-left-bck {
  0% {
    -webkit-transform: translateX(-800px) rotateY(-30deg) scale(6.5);
            transform: translateX(-800px) rotateY(-30deg) scale(6.5);
    -webkit-transform-origin: 200% 50%;
            transform-origin: 200% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -600px 50%;
            transform-origin: -600px 50%;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-left-bck {
  0% {
    -webkit-transform: translateX(-800px) rotateY(-30deg) scale(6.5);
            transform: translateX(-800px) rotateY(-30deg) scale(6.5);
    -webkit-transform-origin: 200% 50%;
            transform-origin: 200% 50%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -600px 50%;
            transform-origin: -600px 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-bottom-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-bottom-bck {
  0% {
    -webkit-transform: translateY(600px) rotateX(-30deg) scale(6.5);
            transform: translateY(600px) rotateX(-30deg) scale(6.5);
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 500px;
            transform-origin: 50% 500px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-bottom-bck {
  0% {
    -webkit-transform: translateY(600px) rotateX(-30deg) scale(6.5);
            transform: translateY(600px) rotateX(-30deg) scale(6.5);
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 500px;
            transform-origin: 50% 500px;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-in-elliptic-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
            transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
            transform-origin: 50% -1400px;
    opacity: 1;
  }
}
@keyframes slide-in-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
            transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
            transform-origin: 50% -1400px;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-top {
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
            transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
            transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
            transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
            transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
            transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(65px);
            transform: translateY(65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(28px);
            transform: translateY(28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-bottom {
  0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(65px);
            transform: translateY(65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(28px);
            transform: translateY(28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
            transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
            transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
            transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
            transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
            transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
            transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-fwd {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(0.84);
            transform: scale(0.84);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-fwd {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(0.84);
            transform: scale(0.84);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation bounce-in-bck
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-bck {
  0% {
    -webkit-transform: scale(7);
            transform: scale(7);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(1.24);
            transform: scale(1.24);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-bck {
  0% {
    -webkit-transform: scale(7);
            transform: scale(7);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: scale(1.24);
            transform: scale(1.24);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  89% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation roll-in-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
            transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
            transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation roll-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-bottom {
  0% {
    -webkit-transform: translateY(800px) rotate(540deg);
            transform: translateY(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-bottom {
  0% {
    -webkit-transform: translateY(800px) rotate(540deg);
            transform: translateY(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation roll-in-right
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-right {
  0% {
    -webkit-transform: translateX(800px) rotate(540deg);
            transform: translateX(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-right {
  0% {
    -webkit-transform: translateX(800px) rotate(540deg);
            transform: translateX(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation roll-in-top
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-540deg);
            transform: translateY(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-540deg);
            transform: translateY(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation roll-in-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) rotate(-720deg);
            transform: translateX(-1000px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes roll-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) rotate(-720deg);
            transform: translateX(-1000px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation roll-in-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-blurred-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-720deg);
            transform: translateY(-800px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes roll-in-blurred-top {
  0% {
    -webkit-transform: translateY(-800px) rotate(-720deg);
            transform: translateY(-800px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation roll-in-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) rotate(720deg);
            transform: translateX(1000px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes roll-in-blurred-right {
  0% {
    -webkit-transform: translateX(1000px) rotate(720deg);
            transform: translateX(1000px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation roll-in-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(800px) rotate(720deg);
            transform: translateY(800px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes roll-in-blurred-bottom {
  0% {
    -webkit-transform: translateY(800px) rotate(720deg);
            transform: translateY(800px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-top-1
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-top-1 {
  0% {
    -webkit-transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
            transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
            transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-top-1 {
  0% {
    -webkit-transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
            transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
            transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-br
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-br {
  0% {
    -webkit-transform: rotateY(-35deg) rotateX(-20deg) translate(250px, 250px) skew(12deg, 15deg);
            transform: rotateY(-35deg) rotateX(-20deg) translate(250px, 250px) skew(12deg, 15deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-br {
  0% {
    -webkit-transform: rotateY(-35deg) rotateX(-20deg) translate(250px, 250px) skew(12deg, 15deg);
            transform: rotateY(-35deg) rotateX(-20deg) translate(250px, 250px) skew(12deg, 15deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-tl
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-tl {
  0% {
    -webkit-transform: rotateY(35deg) rotateX(20deg) translate(-250px, -250px) skew(12deg, 15deg);
            transform: rotateY(35deg) rotateX(20deg) translate(-250px, -250px) skew(12deg, 15deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-tl {
  0% {
    -webkit-transform: rotateY(35deg) rotateX(20deg) translate(-250px, -250px) skew(12deg, 15deg);
            transform: rotateY(35deg) rotateX(20deg) translate(-250px, -250px) skew(12deg, 15deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-right-2
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-right-2 {
  0% {
    -webkit-transform: rotateX(30deg) translateX(300px) skewX(-30deg);
            transform: rotateX(30deg) translateX(300px) skewX(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-right-2 {
  0% {
    -webkit-transform: rotateX(30deg) translateX(300px) skewX(-30deg);
            transform: rotateX(30deg) translateX(300px) skewX(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-left-2
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-left-2 {
  0% {
    -webkit-transform: rotateX(30deg) translateX(-300px) skewX(30deg);
            transform: rotateX(30deg) translateX(-300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-left-2 {
  0% {
    -webkit-transform: rotateX(30deg) translateX(-300px) skewX(30deg);
            transform: rotateX(30deg) translateX(-300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-left-1
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-left-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
            transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-left-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
            transform: rotateX(-30deg) translateX(-300px) skewX(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-right-1
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-right-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
            transform: rotateX(-30deg) translateX(300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-right-1 {
  0% {
    -webkit-transform: rotateX(-30deg) translateX(300px) skewX(30deg);
            transform: rotateX(-30deg) translateX(300px) skewX(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
            transform: rotateX(0deg) translateX(0) skewX(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-tr
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-tr {
  0% {
    -webkit-transform: rotateY(-35deg) rotateX(20deg) translate(250px, -250px) skew(-12deg, -15deg);
            transform: rotateY(-35deg) rotateX(20deg) translate(250px, -250px) skew(-12deg, -15deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-tr {
  0% {
    -webkit-transform: rotateY(-35deg) rotateX(20deg) translate(250px, -250px) skew(-12deg, -15deg);
            transform: rotateY(-35deg) rotateX(20deg) translate(250px, -250px) skew(-12deg, -15deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-bl
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-bl {
  0% {
    -webkit-transform: rotateY(35deg) rotateX(-20deg) translate(-250px, 250px) skew(-12deg, -15deg);
            transform: rotateY(35deg) rotateX(-20deg) translate(-250px, 250px) skew(-12deg, -15deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-bl {
  0% {
    -webkit-transform: rotateY(35deg) rotateX(-20deg) translate(-250px, 250px) skew(-12deg, -15deg);
            transform: rotateY(35deg) rotateX(-20deg) translate(-250px, 250px) skew(-12deg, -15deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-bottom-2
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-bottom-2 {
  0% {
    -webkit-transform: rotateY(-30deg) translateY(300px) skewY(30deg);
            transform: rotateY(-30deg) translateY(300px) skewY(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
            transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-bottom-2 {
  0% {
    -webkit-transform: rotateY(-30deg) translateY(300px) skewY(30deg);
            transform: rotateY(-30deg) translateY(300px) skewY(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
            transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-top-2
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-top-2 {
  0% {
    -webkit-transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
            transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
            transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-top-2 {
  0% {
    -webkit-transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
            transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
            transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-bottom-1
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-bottom-1 {
  0% {
    -webkit-transform: rotateY(30deg) translateY(300px) skewY(-30deg);
            transform: rotateY(30deg) translateY(300px) skewY(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
            transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-bottom-1 {
  0% {
    -webkit-transform: rotateY(30deg) translateY(300px) skewY(-30deg);
            transform: rotateY(30deg) translateY(300px) skewY(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
            transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
            transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tr {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
            transform: rotateY(20deg) rotateX(35deg) translate(300px, -300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-fwd-tl
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px) skew(35deg, -10deg);
            transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px) skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px) skew(35deg, -10deg);
            transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px) skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-fwd-bl
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-bl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
            transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-bl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
            transform: rotateY(-20deg) rotateX(-35deg) translate(-300px, 300px) skew(-35deg, 10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tilt-in-fwd-br
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-fwd-br {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
            transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-br {
  0% {
    -webkit-transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
            transform: rotateY(20deg) rotateX(-35deg) translate(300px, 300px) skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
            transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}
@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-top {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}
@keyframes scale-out-top {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-tr
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-tr {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-out-tr {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
}
@keyframes scale-out-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-left {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 1;
  }
}
@keyframes scale-out-left {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-tl
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-tl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes scale-out-tl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-bottom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes scale-out-bottom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-bl
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-bl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-out-bl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-hor-right
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-hor-right {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-out-hor-right {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-hor-left
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-hor-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes scale-out-hor-left {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-ver-bottom
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-ver-bottom {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-out-ver-bottom {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-horizontal {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 1;
  }
}
@keyframes scale-out-horizontal {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-ver-top {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-out-ver-top {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-out-vertical
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-vertical {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    opacity: 1;
  }
}
@keyframes scale-out-vertical {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * @animation scale-out-br
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-br {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes scale-out-br {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
}
@keyframes rotate-out-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-top
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-top {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}
@keyframes rotate-out-top {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-tr
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-tr {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    opacity: 0;
  }
}
@keyframes rotate-out-tr {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top right;
            transform-origin: top right;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-right
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-right {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
}
@keyframes rotate-out-right {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-left
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-left {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
}
@keyframes rotate-out-left {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-diag-1
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-diag-1 {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 360deg);
            transform: rotate3d(1, 1, 0, 360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 0;
  }
}
@keyframes rotate-out-diag-1 {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 360deg);
            transform: rotate3d(1, 1, 0, 360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-ver
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-ver {
  0% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    opacity: 0;
  }
}
@keyframes rotate-out-ver {
  0% {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-bl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-bl {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    opacity: 0;
  }
}
@keyframes rotate-out-bl {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-hor
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-hor {
  0% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 0;
  }
}
@keyframes rotate-out-hor {
  0% {
    -webkit-transform: rotateX(360deg);
            transform: rotateX(360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-bottom {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
}
@keyframes rotate-out-bottom {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-tl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-tl {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    opacity: 0;
  }
}
@keyframes rotate-out-tl {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-br
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-br {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    opacity: 0;
  }
}
@keyframes rotate-out-br {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-diag-2
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-diag-2 {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 360deg);
            transform: rotate3d(-1, 1, 0, 360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    opacity: 0;
  }
}
@keyframes rotate-out-diag-2 {
  0% {
    -webkit-transform: rotate3d(-1, 1, 0, 360deg);
            transform: rotate3d(-1, 1, 0, 360deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 0deg);
            transform: rotate3d(-1, 1, 0, 0deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes rotate-out-2-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-fwd
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-fwd {
  0% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(180px) rotate(45deg);
            transform: translateZ(180px) rotate(45deg);
    opacity: 0;
  }
}
@keyframes rotate-out-2-fwd {
  0% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(180px) rotate(45deg);
            transform: translateZ(180px) rotate(45deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-br-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-br-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes rotate-out-2-br-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-tl-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-tl-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes rotate-out-2-tl-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-tl-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-tl-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes rotate-out-2-tl-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-bck
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-bck {
  0% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-180px) rotate(-45deg);
            transform: translateZ(-180px) rotate(-45deg);
    opacity: 0;
  }
}
@keyframes rotate-out-2-bck {
  0% {
    -webkit-transform: translateZ(0) rotate(0);
            transform: translateZ(0) rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-180px) rotate(-45deg);
            transform: translateZ(-180px) rotate(-45deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-br-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-br-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes rotate-out-2-br-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0;
  }
}
@keyframes rotate-out-2-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-tr-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-tr-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes rotate-out-2-tr-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-bl-ccw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-bl-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
}
@keyframes rotate-out-2-bl-ccw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-tr-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-tr-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes rotate-out-2-tr-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation rotate-out-2-bl-cw
 * ----------------------------------------
 */
@-webkit-keyframes rotate-out-2-bl-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
}
@keyframes rotate-out-2-bl-cw {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
}
@keyframes swirl-out-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    opacity: 0;
  }
}
@keyframes swirl-out-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-top-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes swirl-out-top-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-top-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes swirl-out-top-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-right-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes swirl-out-right-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-bottom-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes swirl-out-bottom-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-left-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 0;
  }
}
@keyframes swirl-out-left-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-left-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-left-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 0;
  }
}
@keyframes swirl-out-left-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-bottom-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-bottom-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}
@keyframes swirl-out-bottom-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-right-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-right-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes swirl-out-right-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-br-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-br-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes swirl-out-br-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-tr-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-tr-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes swirl-out-tr-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-bl-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-bl-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
}
@keyframes swirl-out-bl-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(540deg) scale(5);
            transform: rotate(540deg) scale(5);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-tl-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-tl-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(720deg) scale(5);
            transform: rotate(720deg) scale(5);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes swirl-out-tl-fwd {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(720deg) scale(5);
            transform: rotate(720deg) scale(5);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-tl-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-tl-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes swirl-out-tl-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-bl-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-bl-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
}
@keyframes swirl-out-bl-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-br-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-br-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes swirl-out-br-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swirl-out-tr-bck
 * ----------------------------------------
 */
@-webkit-keyframes swirl-out-tr-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}
@keyframes swirl-out-tr-bck {
  0% {
    -webkit-transform: rotate(0) scale(1);
            transform: rotate(0) scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-540deg) scale(0);
            transform: rotate(-540deg) scale(0);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation flip-out-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
            transform: rotateX(70deg);
    opacity: 0;
  }
}
@keyframes flip-out-hor-top {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
            transform: rotateX(70deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation flip-out-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-hor-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-70deg);
            transform: rotateX(-70deg);
    opacity: 0;
  }
}
@keyframes flip-out-hor-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-70deg);
            transform: rotateX(-70deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * @animation flip-out-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-ver-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(70deg);
            transform: rotateY(70deg);
    opacity: 0;
  }
}
@keyframes flip-out-ver-right {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(70deg);
            transform: rotateY(70deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation flip-out-diag-2-br
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-diag-2-br {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 70deg);
            transform: rotate3d(-1, 1, 0, 70deg);
    opacity: 0;
  }
}
@keyframes flip-out-diag-2-br {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, 70deg);
            transform: rotate3d(-1, 1, 0, 70deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation flip-out-diag-2-tl
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-diag-2-tl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -70deg);
            transform: rotate3d(-1, 1, 0, -70deg);
    opacity: 0;
  }
}
@keyframes flip-out-diag-2-tl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(-1, 1, 0, -70deg);
            transform: rotate3d(-1, 1, 0, -70deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation flip-out-ver-left
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-ver-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-70deg);
            transform: rotateY(-70deg);
    opacity: 0;
  }
}
@keyframes flip-out-ver-left {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-70deg);
            transform: rotateY(-70deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation flip-out-diag-1-bl
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-diag-1-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -70deg);
            transform: rotate3d(1, 1, 0, -70deg);
    opacity: 0;
  }
}
@keyframes flip-out-diag-1-bl {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, -70deg);
            transform: rotate3d(1, 1, 0, -70deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation flip-out-diag-1-tr
 * ----------------------------------------
 */
@-webkit-keyframes flip-out-diag-1-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 70deg);
            transform: rotate3d(1, 1, 0, 70deg);
    opacity: 0;
  }
}
@keyframes flip-out-diag-1-tr {
  0% {
    -webkit-transform: rotate3d(1, 1, 0, 0deg);
            transform: rotate3d(1, 1, 0, 0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate3d(1, 1, 0, 70deg);
            transform: rotate3d(1, 1, 0, 70deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slit-out-vertical
 * ----------------------------------------
 */
@-webkit-keyframes slit-out-vertical {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
}
@keyframes slit-out-vertical {
  0% {
    -webkit-transform: translateZ(0) rotateY(0);
            transform: translateZ(0) rotateY(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
            transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
            transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slit-out-diagonal-2
 * ----------------------------------------
 */
@-webkit-keyframes slit-out-diagonal-2 {
  0% {
    -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0);
            transform: translateZ(0) rotate3d(-1, 1, 0, 0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
            transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
            transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
    opacity: 0;
  }
}
@keyframes slit-out-diagonal-2 {
  0% {
    -webkit-transform: translateZ(0) rotate3d(-1, 1, 0, 0);
            transform: translateZ(0) rotate3d(-1, 1, 0, 0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
            transform: translateZ(-160px) rotate3d(-1, 1, 0, -87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
            transform: translateZ(-800px) rotate3d(-1, 1, 0, -90deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slit-out-diagonal-1
 * ----------------------------------------
 */
@-webkit-keyframes slit-out-diagonal-1 {
  0% {
    -webkit-transform: translateZ(0) rotate3d(1, 1, 0, 0);
            transform: translateZ(0) rotate3d(1, 1, 0, 0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotate3d(1, 1, 0, 87deg);
            transform: translateZ(-160px) rotate3d(1, 1, 0, 87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotate3d(1, 1, 0, 90deg);
            transform: translateZ(-800px) rotate3d(1, 1, 0, 90deg);
    opacity: 0;
  }
}
@keyframes slit-out-diagonal-1 {
  0% {
    -webkit-transform: translateZ(0) rotate3d(1, 1, 0, 0);
            transform: translateZ(0) rotate3d(1, 1, 0, 0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotate3d(1, 1, 0, 87deg);
            transform: translateZ(-160px) rotate3d(1, 1, 0, 87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotate3d(1, 1, 0, 90deg);
            transform: translateZ(-800px) rotate3d(1, 1, 0, 90deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slit-out-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes slit-out-horizontal {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
            transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
            transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
}
@keyframes slit-out-horizontal {
  0% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
    opacity: 1;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
            transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
            transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px);
            transform: translateY(-1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px) translateX(1000px);
            transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px) translateX(1000px);
            transform: translateY(1000px) translateX(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
            transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-tl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) translateX(-1000px);
            transform: translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px) translateX(-1000px);
            transform: translateY(1000px) translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bl {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px) translateX(-1000px);
            transform: translateY(1000px) translateX(-1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) translateX(1000px);
            transform: translateY(-1000px) translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
            transform: translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) translateX(1000px);
            transform: translateY(-1000px) translateX(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px);
            transform: translateZ(-1100px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px);
            transform: translateZ(-1100px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateX(-1000px);
            transform: translateZ(-1100px) translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateX(-1000px);
            transform: translateZ(-1100px) translateX(-1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateX(1000px);
            transform: translateZ(-1100px) translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateX(1000px);
            transform: translateZ(-1100px) translateX(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(1000px) translateX(-1000px);
            transform: translateZ(-1100px) translateY(1000px) translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(1000px) translateX(-1000px);
            transform: translateZ(-1100px) translateY(1000px) translateX(-1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(-1000px) translateX(1000px);
            transform: translateZ(-1100px) translateY(-1000px) translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(-1000px) translateX(1000px);
            transform: translateZ(-1100px) translateY(-1000px) translateX(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(1000px);
            transform: translateZ(-1100px) translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(1000px);
            transform: translateZ(-1100px) translateY(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-top {
  0% {
    -webkit-transform: translateZ(1) translateY(0);
            transform: translateZ(1) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(-1000px);
            transform: translateZ(-1100px) translateY(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-top {
  0% {
    -webkit-transform: translateZ(1) translateY(0);
            transform: translateZ(1) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(-1000px);
            transform: translateZ(-1100px) translateY(-1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(-1000px) translateX(-1000px);
            transform: translateZ(-1100px) translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(-1000px) translateX(-1000px);
            transform: translateZ(-1100px) translateY(-1000px) translateX(-1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bck-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
            transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bck-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
            transform: translateZ(-1100px) translateY(1000px) translateX(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-fwd-center {
  0% {
    -webkit-transform: translateZ(1);
            transform: translateZ(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-center {
  0% {
    -webkit-transform: translateZ(1);
            transform: translateZ(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px);
            transform: translateZ(600px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-fwd-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-fwd-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateX(-400px);
            transform: translateZ(600px) translateX(-400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-left {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateX(-400px);
            transform: translateZ(600px) translateX(-400px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-fwd-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-fwd-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateX(400px);
            transform: translateZ(600px) translateX(400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-right {
  0% {
    -webkit-transform: translateZ(0) translateX(0);
            transform: translateZ(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateX(400px);
            transform: translateZ(600px) translateX(400px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-fwd-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-fwd-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px) translateX(-400px);
            transform: translateZ(600px) translateY(300px) translateX(-400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-bl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px) translateX(-400px);
            transform: translateZ(600px) translateY(300px) translateX(-400px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-fwd-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-fwd-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(400px);
            transform: translateZ(600px) translateY(-300px) translateX(400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-tr {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(400px);
            transform: translateZ(600px) translateY(-300px) translateX(400px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-fwd-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px);
            transform: translateZ(600px) translateY(300px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-bottom {
  0% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px);
            transform: translateZ(600px) translateY(300px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-fwd-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-fwd-top {
  0% {
    -webkit-transform: translateZ(1) translateY(0);
            transform: translateZ(1) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px);
            transform: translateZ(600px) translateY(-300px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-top {
  0% {
    -webkit-transform: translateZ(1) translateY(0);
            transform: translateZ(1) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px);
            transform: translateZ(600px) translateY(-300px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-fwd-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-fwd-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(-400px);
            transform: translateZ(600px) translateY(-300px) translateX(-400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-tl {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(-300px) translateX(-400px);
            transform: translateZ(600px) translateY(-300px) translateX(-400px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-fwd-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-fwd-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px) translateX(400px);
            transform: translateZ(600px) translateY(300px) translateX(400px);
    opacity: 0;
  }
}
@keyframes slide-out-fwd-br {
  0% {
    -webkit-transform: translateZ(0) translateY(0) translateX(0);
            transform: translateZ(0) translateY(0) translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(600px) translateY(300px) translateX(400px);
            transform: translateZ(600px) translateY(300px) translateX(400px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
            transform: translateY(-1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-blurred-bl
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-bl {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-1000px, 1000px) skew(-80deg, -10deg);
            transform: translate(-1000px, 1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-bl {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-1000px, 1000px) skew(-80deg, -10deg);
            transform: translate(-1000px, 1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-blurred-br
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-br {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(1000px, 1000px) skew(80deg, 10deg);
            transform: translate(1000px, 1000px) skew(80deg, 10deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-br {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(1000px, 1000px) skew(80deg, 10deg);
            transform: translate(1000px, 1000px) skew(80deg, 10deg);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-blurred-tl
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-tl {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-1000px, -1000px) skew(80deg, 10deg);
            transform: translate(-1000px, -1000px) skew(80deg, 10deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-tl {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-1000px, -1000px) skew(80deg, 10deg);
            transform: translate(-1000px, -1000px) skew(80deg, 10deg);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-left {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) scaleX(2) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-left {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) scaleX(2) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
            transform: translateX(1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) scaleX(2) scaleY(0.2);
            transform: translateX(1000px) scaleX(2) scaleY(0.2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-blurred-tr
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-tr {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
            transform: translate(1000px, -1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-tr {
  0% {
    -webkit-transform: translate(0, 0) skew(0deg, 0deg);
            transform: translate(0, 0) skew(0deg, 0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
            transform: translate(1000px, -1000px) skew(-80deg, -10deg);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-bottom {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
            transform: translateY(1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}
@keyframes slide-out-blurred-bottom {
  0% {
    -webkit-transform: translateY(0) scaleY(1) scaleX(1);
            transform: translateY(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px) scaleY(2) scaleX(0.2);
            transform: translateY(1000px) scaleY(2) scaleX(0.2);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-elliptic-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-top-bck {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes slide-out-elliptic-top-bck {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 1400px;
            transform-origin: 50% 1400px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-600px) rotateX(-30deg) scale(0);
            transform: translateY(-600px) rotateX(-30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-elliptic-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-right-fwd {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 600px 50%;
            transform-origin: 600px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotateY(20deg) scale(6);
            transform: translateX(1000px) rotateY(20deg) scale(6);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
}
@keyframes slide-out-elliptic-right-fwd {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 600px 50%;
            transform-origin: 600px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotateY(20deg) scale(6);
            transform: translateX(1000px) rotateY(20deg) scale(6);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-elliptic-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -500px 50%;
            transform-origin: -500px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) rotateY(-20deg) scale(6);
            transform: translateX(-1000px) rotateY(-20deg) scale(6);
    -webkit-transform-origin: 200% 50%;
            transform-origin: 200% 50%;
    opacity: 0;
  }
}
@keyframes slide-out-elliptic-left-fwd {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -500px 50%;
            transform-origin: -500px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) rotateY(-20deg) scale(6);
            transform: translateX(-1000px) rotateY(-20deg) scale(6);
    -webkit-transform-origin: 200% 50%;
            transform-origin: 200% 50%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-elliptic-left-bck 
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-left-bck {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 2000px 50%;
            transform-origin: 2000px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) rotateY(30deg) scale(0);
            transform: translateX(-1000px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 1;
  }
}
@keyframes slide-out-elliptic-left-bck {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: 2000px 50%;
            transform-origin: 2000px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) rotateY(30deg) scale(0);
            transform: translateX(-1000px) rotateY(30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-elliptic-right-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-right-bck {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
            transform-origin: -1800px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotateY(-30deg) scale(0);
            transform: translateX(1000px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 1;
  }
}
@keyframes slide-out-elliptic-right-bck {
  0% {
    -webkit-transform: translateX(0) rotateY(0) scale(1);
            transform: translateX(0) rotateY(0) scale(1);
    -webkit-transform-origin: -1800px 50%;
            transform-origin: -1800px 50%;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotateY(-30deg) scale(0);
            transform: translateX(1000px) rotateY(-30deg) scale(0);
    -webkit-transform-origin: -100% 50%;
            transform-origin: -100% 50%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-elliptic-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 500px;
            transform-origin: 50% 500px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(600px) rotateX(-20deg) scale(6);
            transform: translateY(600px) rotateX(-20deg) scale(6);
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    opacity: 0;
  }
}
@keyframes slide-out-elliptic-bottom-fwd {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% 500px;
            transform-origin: 50% 500px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(600px) rotateX(-20deg) scale(6);
            transform: translateY(600px) rotateX(-20deg) scale(6);
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-elliptic-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -500px;
            transform-origin: 50% -500px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-600px) rotateX(20deg) scale(6);
            transform: translateY(-600px) rotateX(20deg) scale(6);
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    opacity: 0;
  }
}
@keyframes slide-out-elliptic-top-fwd {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -500px;
            transform-origin: 50% -500px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-600px) rotateX(20deg) scale(6);
            transform: translateY(-600px) rotateX(20deg) scale(6);
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-elliptic-bottom-bck
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-elliptic-bottom-bck {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
            transform-origin: 50% -1400px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
            transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}
@keyframes slide-out-elliptic-bottom-bck {
  0% {
    -webkit-transform: translateY(0) rotateX(0) scale(1);
            transform: translateY(0) rotateX(0) scale(1);
    -webkit-transform-origin: 50% -1400px;
            transform-origin: 50% -1400px;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(600px) rotateX(30deg) scale(0);
            transform: translateY(600px) rotateX(30deg) scale(0);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation bounce-out-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(-38px);
            transform: translateY(-38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-800px);
            transform: translateY(-800px);
    opacity: 0;
  }
}
@keyframes bounce-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(-38px);
            transform: translateY(-38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-800px);
            transform: translateY(-800px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation bounce-out-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateX(38px);
            transform: translateX(38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}
@keyframes bounce-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateX(30px);
            transform: translateX(30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateX(38px);
            transform: translateX(38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateX(80px);
            transform: translateX(80px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation bounce-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(38px);
            transform: translateY(38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px);
            transform: translateY(800px);
    opacity: 0;
  }
}
@keyframes bounce-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(30px);
            transform: translateY(30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(38px);
            transform: translateY(38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(75px);
            transform: translateY(75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px);
            transform: translateY(800px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation bounce-out-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateX(-38px);
            transform: translateX(-38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}
@keyframes bounce-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateX(-38px);
            transform: translateX(-38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateX(-80px);
            transform: translateX(-80px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation bounce-out-fwd
 * ----------------------------------------
 */
@-webkit-keyframes bounce-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateZ(90px);
            transform: translateZ(90px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateZ(95px);
            transform: translateZ(95px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateZ(150px);
            transform: translateZ(150px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(500px);
            transform: translateZ(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
}
@keyframes bounce-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateZ(90px);
            transform: translateZ(90px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateZ(95px);
            transform: translateZ(95px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateZ(150px);
            transform: translateZ(150px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(500px);
            transform: translateZ(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation bounce-out-bck
 * ----------------------------------------
 */
@-webkit-keyframes bounce-out-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateZ(-100px);
            transform: translateZ(-100px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateZ(-110px);
            transform: translateZ(-110px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-900px) scale(0);
            transform: translateZ(-900px) scale(0);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
}
@keyframes bounce-out-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateZ(-100px);
            transform: translateZ(-100px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateZ(-110px);
            transform: translateZ(-110px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateZ(-200px);
            transform: translateZ(-200px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateZ(0) scale(1);
            transform: translateZ(0) scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-900px) scale(0);
            transform: translateZ(-900px) scale(0);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation roll-out-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-out-left {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) rotate(-540deg);
            transform: translateX(-1000px) rotate(-540deg);
    opacity: 0;
  }
}
@keyframes roll-out-left {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) rotate(-540deg);
            transform: translateX(-1000px) rotate(-540deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation roll-out-top
 * ----------------------------------------
 */
@-webkit-keyframes roll-out-top {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-800px) rotate(-540deg);
            transform: translateY(-800px) rotate(-540deg);
    opacity: 0;
  }
}
@keyframes roll-out-top {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-800px) rotate(-540deg);
            transform: translateY(-800px) rotate(-540deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation roll-out-right
 * ----------------------------------------
 */
@-webkit-keyframes roll-out-right {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotate(540deg);
            transform: translateX(1000px) rotate(540deg);
    opacity: 0;
  }
}
@keyframes roll-out-right {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotate(540deg);
            transform: translateX(1000px) rotate(540deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation roll-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes roll-out-bottom {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px) rotate(540deg);
            transform: translateY(800px) rotate(540deg);
    opacity: 0;
  }
}
@keyframes roll-out-bottom {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px) rotate(540deg);
            transform: translateY(800px) rotate(540deg);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation roll-out-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes roll-out-blurred-left {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) rotate(-720deg);
            transform: translateX(-1000px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
}
@keyframes roll-out-blurred-left {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-1000px) rotate(-720deg);
            transform: translateX(-1000px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation roll-out-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes roll-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-800px) rotate(-720deg);
            transform: translateY(-800px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
}
@keyframes roll-out-blurred-top {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-800px) rotate(-720deg);
            transform: translateY(-800px) rotate(-720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation roll-out-blurred-right
 * ----------------------------------------
 */
@-webkit-keyframes roll-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotate(720deg);
            transform: translateX(1000px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
}
@keyframes roll-out-blurred-right {
  0% {
    -webkit-transform: translateX(0) rotate(0deg);
            transform: translateX(0) rotate(0deg);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px) rotate(720deg);
            transform: translateX(1000px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation roll-out-blurred-bottom
 * ----------------------------------------
 */
@-webkit-keyframes roll-out-blurred-bottom {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px) rotate(720deg);
            transform: translateY(800px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
}
@keyframes roll-out-blurred-bottom {
  0% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(800px) rotate(720deg);
            transform: translateY(800px) rotate(720deg);
    -webkit-filter: blur(50px);
            filter: blur(50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-top-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}
@keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-top-fwd {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
            transform: rotateX(70deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}
@keyframes swing-out-top-fwd {
  0% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(70deg);
            transform: rotateX(70deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-right-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-right-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-100deg);
            transform: rotateY(-100deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
}
@keyframes swing-out-right-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-100deg);
            transform: rotateY(-100deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-right-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(70deg);
            transform: rotateY(70deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
}
@keyframes swing-out-right-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(70deg);
            transform: rotateY(70deg);
    -webkit-transform-origin: right;
            transform-origin: right;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-left-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-left-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-70deg);
            transform: rotateY(-70deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
}
@keyframes swing-out-left-fwd {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(-70deg);
            transform: rotateY(-70deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-left-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-left-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(100deg);
            transform: rotateY(100deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
}
@keyframes swing-out-left-bck {
  0% {
    -webkit-transform: rotateY(0);
            transform: rotateY(0);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateY(100deg);
            transform: rotateY(100deg);
    -webkit-transform-origin: left;
            transform-origin: left;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-bottom-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-70deg);
            transform: rotateX(-70deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
}
@keyframes swing-out-bottom-fwd {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-70deg);
            transform: rotateX(-70deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-bottom-bck
 * ----------------------------------------
 */
@-webkit-keyframes swing-out-bottom-bck {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
}
@keyframes swing-out-bottom-bck {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(100deg);
            transform: rotateX(100deg);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(80px);
            transform: translateZ(80px);
    opacity: 0;
  }
}
@keyframes fade-out-fwd {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(80px);
            transform: translateZ(80px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-top
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-tl
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-tl {
  0% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px) translateY(-50px);
            transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-tl {
  0% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px) translateY(-50px);
            transform: translateX(-50px) translateY(-50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-br
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-br {
  0% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px) translateY(50px);
            transform: translateX(50px) translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-br {
  0% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px) translateY(50px);
            transform: translateX(50px) translateY(50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-left
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-left {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-right
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
}
@keyframes fade-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-bl
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-bl {
  0% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px) translateY(50px);
            transform: translateX(-50px) translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-bl {
  0% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50px) translateY(50px);
            transform: translateX(-50px) translateY(50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-tr
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-tr {
  0% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px) translateY(-50px);
            transform: translateX(50px) translateY(-50px);
    opacity: 0;
  }
}
@keyframes fade-out-tr {
  0% {
    -webkit-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(50px) translateY(-50px);
            transform: translateX(50px) translateY(-50px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-out-bck
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }
}
@keyframes fade-out-bck {
  0% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-80px);
            transform: translateZ(-80px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-right
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-right {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-left
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-left {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-left {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-ver
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-ver {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(2);
            transform: scaleY(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-ver {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(2);
            transform: scaleY(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-bl
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-bl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-bl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-tr
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-tr {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-tr {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-hor
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-hor {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(2);
            transform: scaleX(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-hor {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(2);
            transform: scaleX(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-bottom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-bottom {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-top
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-top {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-top {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-tl
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-tl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-tl {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation puff-out-br
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-br {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-br {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation flicker-out-1
 * ----------------------------------------
 */
@-webkit-keyframes flicker-out-1 {
  0% {
    opacity: 1;
  }
  13.9% {
    opacity: 1;
  }
  14% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  14.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  15% {
    opacity: 1;
  }
  22.9% {
    opacity: 1;
  }
  23% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  24.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  25% {
    opacity: 1;
  }
  34.9% {
    opacity: 1;
  }
  35% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  39.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  40% {
    opacity: 1;
  }
  42.9% {
    opacity: 1;
  }
  43% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  44.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  54.9% {
    opacity: 1;
  }
  55% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  69.4% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  69.5% {
    opacity: 1;
  }
  69.9% {
    opacity: 1;
  }
  70% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  79.4% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  79.9% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  89.8% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  89.9% {
    opacity: 1;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  90% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  100% {
    opacity: 0;
  }
}
@keyframes flicker-out-1 {
  0% {
    opacity: 1;
  }
  13.9% {
    opacity: 1;
  }
  14% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  14.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  15% {
    opacity: 1;
  }
  22.9% {
    opacity: 1;
  }
  23% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  24.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  25% {
    opacity: 1;
  }
  34.9% {
    opacity: 1;
  }
  35% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  39.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  40% {
    opacity: 1;
  }
  42.9% {
    opacity: 1;
  }
  43% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  44.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  54.9% {
    opacity: 1;
  }
  55% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  69.4% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  69.5% {
    opacity: 1;
  }
  69.9% {
    opacity: 1;
  }
  70% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  79.4% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  79.9% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  89.8% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  89.9% {
    opacity: 1;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  90% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  100% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation flicker-out-2
 * ----------------------------------------
 */
@-webkit-keyframes flicker-out-2 {
  0% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  14.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  15% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  24.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  25% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  39.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  40% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  44.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  45% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  69.4% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  69.5% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  79.4% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  79.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  89.8% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  89.9% {
    opacity: 1;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  90% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  100% {
    opacity: 0;
  }
}
@keyframes flicker-out-2 {
  0% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  13.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  14% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  14.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  15% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  22.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  23% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  24.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  25% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  34.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
  }
  35% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  39.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  40% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  42.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
  }
  43% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  44.9% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  45% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  50% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  54.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  55% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  69.4% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  69.5% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  69.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
  }
  70% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  79.4% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  79.9% {
    opacity: 1;
    -webkit-box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
            box-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
  }
  80% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  89.8% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  89.9% {
    opacity: 1;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  90% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  100% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

/**
 * ----------------------------------------
 * animation vibrate-2
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-2 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-2 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  60% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

/**
 * ----------------------------------------
 * animation vibrate-3
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-3 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  10% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  20% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  30% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  50% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  70% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  80% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  90% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}

/**
 * ----------------------------------------
 * animation flicker-1
 * ----------------------------------------
 */
@-webkit-keyframes flicker-1 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  47.99% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  49.01% {
    opacity: 1;
  }
}
@keyframes flicker-1 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  47.99% {
    opacity: 1;
  }
  48% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  49.01% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flicker-4
 * ----------------------------------------
 */
@-webkit-keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}
@keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flicker-5
 * ----------------------------------------
 */
@-webkit-keyframes flicker-5 {
  0%,
  100% {
    opacity: 1;
  }
  -0.02% {
    opacity: 1;
  }
  0% {
    opacity: 1;
  }
  1% {
    opacity: 1;
  }
  1.02% {
    opacity: 1;
  }
  8.98% {
    opacity: 1;
  }
  9% {
    opacity: 0;
  }
  9.8% {
    opacity: 0;
  }
  9.82% {
    opacity: 1;
  }
  9.48% {
    opacity: 1;
  }
  9.5% {
    opacity: 1;
  }
  9.6% {
    opacity: 1;
  }
  9.62% {
    opacity: 1;
  }
  14.98% {
    opacity: 1;
  }
  15% {
    opacity: 0.5;
  }
  15.8% {
    opacity: 0.5;
  }
  15.82% {
    opacity: 1;
  }
  15.18% {
    opacity: 1;
  }
  15.2% {
    opacity: 0.7;
  }
  16% {
    opacity: 0.7;
  }
  16.02% {
    opacity: 1;
  }
  15.48% {
    opacity: 1;
  }
  15.5% {
    opacity: 0.5;
  }
  16.2% {
    opacity: 0.5;
  }
  16.22% {
    opacity: 1;
  }
  16.98% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  17.8% {
    opacity: 1;
  }
  17.82% {
    opacity: 1;
  }
  20.48% {
    opacity: 1;
  }
  20.5% {
    opacity: 0.9;
  }
  21.3% {
    opacity: 0.9;
  }
  21.32% {
    opacity: 1;
  }
  20.98% {
    opacity: 1;
  }
  21% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  22.02% {
    opacity: 1;
  }
  39.98% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  41% {
    opacity: 1;
  }
  41.02% {
    opacity: 1;
  }
  40.48% {
    opacity: 1;
  }
  40.5% {
    opacity: 0.6;
  }
  41.4% {
    opacity: 0.6;
  }
  41.42% {
    opacity: 1;
  }
  41.98% {
    opacity: 1;
  }
  42% {
    opacity: 1;
  }
  42.8% {
    opacity: 1;
  }
  42.82% {
    opacity: 1;
  }
  59.98% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  61% {
    opacity: 1;
  }
  61.02% {
    opacity: 1;
  }
  60.18% {
    opacity: 1;
  }
  60.2% {
    opacity: 0.2;
  }
  61% {
    opacity: 0.2;
  }
  61.02% {
    opacity: 1;
  }
  60.78% {
    opacity: 1;
  }
  60.8% {
    opacity: 0.4;
  }
  61.6% {
    opacity: 0.4;
  }
  61.62% {
    opacity: 1;
  }
  61.38% {
    opacity: 1;
  }
  61.4% {
    opacity: 0;
  }
  62.2% {
    opacity: 0;
  }
  62.22% {
    opacity: 1;
  }
  61.78% {
    opacity: 1;
  }
  61.8% {
    opacity: 1;
  }
  62.8% {
    opacity: 1;
  }
  62.82% {
    opacity: 1;
  }
  75.98% {
    opacity: 1;
  }
  76% {
    opacity: 1;
  }
  77% {
    opacity: 1;
  }
  77.02% {
    opacity: 1;
  }
  77.98% {
    opacity: 1;
  }
  78% {
    opacity: 0.7;
  }
  78.8% {
    opacity: 0.7;
  }
  78.82% {
    opacity: 1;
  }
  78.98% {
    opacity: 1;
  }
  79% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  80.02% {
    opacity: 1;
  }
  99.98% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
  101% {
    opacity: 1;
  }
  101.02% {
    opacity: 1;
  }
}
@keyframes flicker-5 {
  0%,
  100% {
    opacity: 1;
  }
  -0.02% {
    opacity: 1;
  }
  0% {
    opacity: 1;
  }
  1% {
    opacity: 1;
  }
  1.02% {
    opacity: 1;
  }
  8.98% {
    opacity: 1;
  }
  9% {
    opacity: 0;
  }
  9.8% {
    opacity: 0;
  }
  9.82% {
    opacity: 1;
  }
  9.48% {
    opacity: 1;
  }
  9.5% {
    opacity: 1;
  }
  9.6% {
    opacity: 1;
  }
  9.62% {
    opacity: 1;
  }
  14.98% {
    opacity: 1;
  }
  15% {
    opacity: 0.5;
  }
  15.8% {
    opacity: 0.5;
  }
  15.82% {
    opacity: 1;
  }
  15.18% {
    opacity: 1;
  }
  15.2% {
    opacity: 0.7;
  }
  16% {
    opacity: 0.7;
  }
  16.02% {
    opacity: 1;
  }
  15.48% {
    opacity: 1;
  }
  15.5% {
    opacity: 0.5;
  }
  16.2% {
    opacity: 0.5;
  }
  16.22% {
    opacity: 1;
  }
  16.98% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  17.8% {
    opacity: 1;
  }
  17.82% {
    opacity: 1;
  }
  20.48% {
    opacity: 1;
  }
  20.5% {
    opacity: 0.9;
  }
  21.3% {
    opacity: 0.9;
  }
  21.32% {
    opacity: 1;
  }
  20.98% {
    opacity: 1;
  }
  21% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  22.02% {
    opacity: 1;
  }
  39.98% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  41% {
    opacity: 1;
  }
  41.02% {
    opacity: 1;
  }
  40.48% {
    opacity: 1;
  }
  40.5% {
    opacity: 0.6;
  }
  41.4% {
    opacity: 0.6;
  }
  41.42% {
    opacity: 1;
  }
  41.98% {
    opacity: 1;
  }
  42% {
    opacity: 1;
  }
  42.8% {
    opacity: 1;
  }
  42.82% {
    opacity: 1;
  }
  59.98% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  61% {
    opacity: 1;
  }
  61.02% {
    opacity: 1;
  }
  60.18% {
    opacity: 1;
  }
  60.2% {
    opacity: 0.2;
  }
  61% {
    opacity: 0.2;
  }
  61.02% {
    opacity: 1;
  }
  60.78% {
    opacity: 1;
  }
  60.8% {
    opacity: 0.4;
  }
  61.6% {
    opacity: 0.4;
  }
  61.62% {
    opacity: 1;
  }
  61.38% {
    opacity: 1;
  }
  61.4% {
    opacity: 0;
  }
  62.2% {
    opacity: 0;
  }
  62.22% {
    opacity: 1;
  }
  61.78% {
    opacity: 1;
  }
  61.8% {
    opacity: 1;
  }
  62.8% {
    opacity: 1;
  }
  62.82% {
    opacity: 1;
  }
  75.98% {
    opacity: 1;
  }
  76% {
    opacity: 1;
  }
  77% {
    opacity: 1;
  }
  77.02% {
    opacity: 1;
  }
  77.98% {
    opacity: 1;
  }
  78% {
    opacity: 0.7;
  }
  78.8% {
    opacity: 0.7;
  }
  78.82% {
    opacity: 1;
  }
  78.98% {
    opacity: 1;
  }
  79% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  80.02% {
    opacity: 1;
  }
  99.98% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
  101% {
    opacity: 1;
  }
  101.02% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flicker-3
 * ----------------------------------------
 */
@-webkit-keyframes flicker-3 {
  0%,
  100% {
    opacity: 1;
  }
  32.98% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  34% {
    opacity: 0;
  }
  34.02% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.9% {
    opacity: 0;
  }
  35.92% {
    opacity: 1;
  }
  38.98% {
    opacity: 1;
  }
  39% {
    opacity: 0;
  }
  39.8% {
    opacity: 0;
  }
  39.82% {
    opacity: 1;
  }
  83.98% {
    opacity: 1;
  }
  84% {
    opacity: 0;
  }
  84.9% {
    opacity: 0;
  }
  84.92% {
    opacity: 1;
  }
}
@keyframes flicker-3 {
  0%,
  100% {
    opacity: 1;
  }
  32.98% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  34% {
    opacity: 0;
  }
  34.02% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.9% {
    opacity: 0;
  }
  35.92% {
    opacity: 1;
  }
  38.98% {
    opacity: 1;
  }
  39% {
    opacity: 0;
  }
  39.8% {
    opacity: 0;
  }
  39.82% {
    opacity: 1;
  }
  83.98% {
    opacity: 1;
  }
  84% {
    opacity: 0;
  }
  84.9% {
    opacity: 0;
  }
  84.92% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation flicker-2
 * ----------------------------------------
 */
@-webkit-keyframes flicker-2 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  45.99% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  46.9% {
    opacity: 0;
  }
  46.91% {
    opacity: 1;
  }
  51.99% {
    opacity: 1;
  }
  52% {
    opacity: 0;
  }
  52.8% {
    opacity: 0;
  }
  52.81% {
    opacity: 1;
  }
}
@keyframes flicker-2 {
  0%,
  100% {
    opacity: 1;
  }
  41.99% {
    opacity: 1;
  }
  42% {
    opacity: 0;
  }
  43% {
    opacity: 0;
  }
  43.01% {
    opacity: 1;
  }
  45.99% {
    opacity: 1;
  }
  46% {
    opacity: 0;
  }
  46.9% {
    opacity: 0;
  }
  46.91% {
    opacity: 1;
  }
  51.99% {
    opacity: 1;
  }
  52% {
    opacity: 0;
  }
  52.8% {
    opacity: 0;
  }
  52.81% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation shake-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
}

/**
 * ----------------------------------------
 * animation shake-bottom
 * ----------------------------------------
 */
@-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

/**
 * ----------------------------------------
 * animation shake-top
 * ----------------------------------------
 */
@-webkit-keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
            transform-origin: 50% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

/**
 * ----------------------------------------
 * animation shake-tl
 * ----------------------------------------
 */
@-webkit-keyframes shake-tl {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-tl {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

/**
 * ----------------------------------------
 * animation shake-br
 * ----------------------------------------
 */
@-webkit-keyframes shake-br {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-br {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

/**
 * ----------------------------------------
 * animation shake-lr
 * ----------------------------------------
 */
@-webkit-keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
}
@keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
            transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
            transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
            transform: rotate(8deg);
  }
}

/**
 * ----------------------------------------
 * animation shake-left
 * ----------------------------------------
 */
@-webkit-keyframes shake-left {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-left {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 0 50%;
            transform-origin: 0 50%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

/**
 * ----------------------------------------
 * animation shake-right
 * ----------------------------------------
 */
@-webkit-keyframes shake-right {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-right {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

/**
 * ----------------------------------------
 * animation shake-vertical
 * ----------------------------------------
 */
@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
            transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
            transform: translateY(-6.4px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
            transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
            transform: translateY(-6.4px);
  }
}

/**
 * ----------------------------------------
 * animation shake-bl
 * ----------------------------------------
 */
@-webkit-keyframes shake-bl {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-bl {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

/**
 * ----------------------------------------
 * animation shake-tr
 * ----------------------------------------
 */
@-webkit-keyframes shake-tr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}
@keyframes shake-tr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
  }
  10% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
            transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
}

/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
            transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

/**
 * ----------------------------------------
 * animation jello-diagonal-2
 * ----------------------------------------
 */
@-webkit-keyframes jello-diagonal-2 {
  0% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
  30% {
    -webkit-transform: skew(-25deg -25deg);
            transform: skew(-25deg -25deg);
  }
  40% {
    -webkit-transform: skew(15deg, 15deg);
            transform: skew(15deg, 15deg);
  }
  50% {
    -webkit-transform: skew(-15deg, -15deg);
            transform: skew(-15deg, -15deg);
  }
  65% {
    -webkit-transform: skew(5deg, 5deg);
            transform: skew(5deg, 5deg);
  }
  75% {
    -webkit-transform: skew(-5deg, -5deg);
            transform: skew(-5deg, -5deg);
  }
  100% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
}
@keyframes jello-diagonal-2 {
  0% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
  30% {
    -webkit-transform: skew(-25deg -25deg);
            transform: skew(-25deg -25deg);
  }
  40% {
    -webkit-transform: skew(15deg, 15deg);
            transform: skew(15deg, 15deg);
  }
  50% {
    -webkit-transform: skew(-15deg, -15deg);
            transform: skew(-15deg, -15deg);
  }
  65% {
    -webkit-transform: skew(5deg, 5deg);
            transform: skew(5deg, 5deg);
  }
  75% {
    -webkit-transform: skew(-5deg, -5deg);
            transform: skew(-5deg, -5deg);
  }
  100% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
}

/**
 * ----------------------------------------
 * animation jello-diagonal-1
 * ----------------------------------------
 */
@-webkit-keyframes jello-diagonal-1 {
  0% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
  30% {
    -webkit-transform: skew(25deg 25deg);
            transform: skew(25deg 25deg);
  }
  40% {
    -webkit-transform: skew(-15deg, -15deg);
            transform: skew(-15deg, -15deg);
  }
  50% {
    -webkit-transform: skew(15deg, 15deg);
            transform: skew(15deg, 15deg);
  }
  65% {
    -webkit-transform: skew(-5deg, -5deg);
            transform: skew(-5deg, -5deg);
  }
  75% {
    -webkit-transform: skew(5deg, 5deg);
            transform: skew(5deg, 5deg);
  }
  100% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
}
@keyframes jello-diagonal-1 {
  0% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
  30% {
    -webkit-transform: skew(25deg 25deg);
            transform: skew(25deg 25deg);
  }
  40% {
    -webkit-transform: skew(-15deg, -15deg);
            transform: skew(-15deg, -15deg);
  }
  50% {
    -webkit-transform: skew(15deg, 15deg);
            transform: skew(15deg, 15deg);
  }
  65% {
    -webkit-transform: skew(-5deg, -5deg);
            transform: skew(-5deg, -5deg);
  }
  75% {
    -webkit-transform: skew(5deg, 5deg);
            transform: skew(5deg, 5deg);
  }
  100% {
    -webkit-transform: skew(0deg 0deg);
            transform: skew(0deg 0deg);
  }
}

/**
 * ----------------------------------------
 * animation jello-vertical
 * ----------------------------------------
 */
@-webkit-keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-vertical {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
            transform: scale3d(0.75, 1.25, 1);
  }
  40% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
            transform: scale3d(1.25, 0.75, 1);
  }
  50% {
    -webkit-transform: scale3d(0.85, 1.15, 1);
            transform: scale3d(0.85, 1.15, 1);
  }
  65% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
            transform: scale3d(1.05, 0.95, 1);
  }
  75% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
            transform: scale3d(0.95, 1.05, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1);
  }
}

/**
 * ----------------------------------------
 * animation wobble-hor-bottom
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
            transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
            transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
            transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
            transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
            transform: translateX(-6px) rotate(-1.2deg);
  }
}

/**
 * ----------------------------------------
 * animation wobble-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-right {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
            transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
            transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
            transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
            transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
            transform: translateY(-6px) rotate(1.2deg);
  }
}
@keyframes wobble-ver-right {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
            transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
            transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
            transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
            transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
            transform: translateY(-6px) rotate(1.2deg);
  }
}

/**
 * ----------------------------------------
 * animation wobble-ver-left
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(-6deg);
            transform: translateY(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(6deg);
            transform: translateY(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(-3.6deg);
            transform: translateY(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(2.4deg);
            transform: translateY(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(-1.2deg);
            transform: translateY(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-ver-left {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(-6deg);
            transform: translateY(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(6deg);
            transform: translateY(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(-3.6deg);
            transform: translateY(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(2.4deg);
            transform: translateY(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(-1.2deg);
            transform: translateY(-6px) rotate(-1.2deg);
  }
}

/**
 * ----------------------------------------
 * animation wobble-hor-top
 * ----------------------------------------
 */
@-webkit-keyframes wobble-hor-top {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
            transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
            transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
            transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
            transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
            transform: translateX(-6px) rotate(1.2deg);
  }
}
@keyframes wobble-hor-top {
  0%,
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(6deg);
            transform: translateX(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(-6deg);
            transform: translateX(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(3.6deg);
            transform: translateX(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(-2.4deg);
            transform: translateX(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(1.2deg);
            transform: translateX(-6px) rotate(1.2deg);
  }
}

/**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
            transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
            transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation bounce-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-right {
  0% {
    -webkit-transform: translateX(48px);
            transform: translateX(48px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(26px);
            transform: translateX(26px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(13px);
            transform: translateX(13px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(6.5px);
            transform: translateX(6.5px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-right {
  0% {
    -webkit-transform: translateX(48px);
            transform: translateX(48px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(26px);
            transform: translateX(26px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(13px);
            transform: translateX(13px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(6.5px);
            transform: translateX(6.5px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation bounce-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-left {
  0% {
    -webkit-transform: translateX(-48px);
            transform: translateX(-48px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(-26px);
            transform: translateX(-26px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(-13px);
            transform: translateX(-13px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(-6.5px);
            transform: translateX(-6.5px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-left {
  0% {
    -webkit-transform: translateX(-48px);
            transform: translateX(-48px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateX(-26px);
            transform: translateX(-26px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateX(-13px);
            transform: translateX(-13px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateX(-6.5px);
            transform: translateX(-6.5px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateX(-4px);
            transform: translateX(-4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87%,
  98% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation bounce-bottom
 * ----------------------------------------
 */
@-webkit-keyframes bounce-bottom {
  0% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(24px);
            transform: translateY(24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(12px);
            transform: translateY(12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-bottom {
  0% {
    -webkit-transform: translateY(45px);
            transform: translateY(45px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(24px);
            transform: translateY(24px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(12px);
            transform: translateY(12px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

/**
 * ----------------------------------------
 * animation ping
 * ----------------------------------------
 */
@-webkit-keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}
@keyframes ping {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0.8;
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(2.2);
            transform: scale(2.2);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation blink-2
 * ----------------------------------------
 */
@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-bottom-right
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-bottom-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 84%;
            transform-origin: 84% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(20px, 15px);
            transform: scale(1.25) translate(20px, 15px);
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
  }
}
@keyframes kenburns-bottom-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 84%;
            transform-origin: 84% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(20px, 15px);
            transform: scale(1.25) translate(20px, 15px);
    -webkit-transform-origin: right bottom;
            transform-origin: right bottom;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-top-left
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
            transform-origin: 16% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
            transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}
@keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
            transform-origin: 16% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
            transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-left
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 50%;
            transform-origin: 16% 50%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, 15px);
            transform: scale(1.25) translate(-20px, 15px);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}
@keyframes kenburns-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 50%;
            transform-origin: 16% 50%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, 15px);
            transform: scale(1.25) translate(-20px, 15px);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-right
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 50%;
            transform-origin: 84% 50%;
  }
  100% {
    -webkit-transform: scale(1.25) translateX(20px);
            transform: scale(1.25) translateX(20px);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}
@keyframes kenburns-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 50%;
            transform-origin: 84% 50%;
  }
  100% {
    -webkit-transform: scale(1.25) translateX(20px);
            transform: scale(1.25) translateX(20px);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-bottom-left
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-bottom-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 84%;
            transform-origin: 16% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, 15px);
            transform: scale(1.25) translate(-20px, 15px);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
}
@keyframes kenburns-bottom-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 84%;
            transform-origin: 16% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, 15px);
            transform: scale(1.25) translate(-20px, 15px);
    -webkit-transform-origin: left bottom;
            transform-origin: left bottom;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-top-right
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 16%;
            transform-origin: 84% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(20px, -15px);
            transform: scale(1.25) translate(20px, -15px);
    -webkit-transform-origin: right top;
            transform-origin: right top;
  }
}
@keyframes kenburns-top-right {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 84% 16%;
            transform-origin: 84% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(20px, -15px);
            transform: scale(1.25) translate(20px, -15px);
    -webkit-transform-origin: right top;
            transform-origin: right top;
  }
}

/**
 * ----------------------------------------
 * animation kenburns-bottom
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-bottom {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 84%;
            transform-origin: 50% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(15px);
            transform: scale(1.25) translateY(15px);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}
@keyframes kenburns-bottom {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 84%;
            transform-origin: 50% 84%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(15px);
            transform: scale(1.25) translateY(15px);
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }
}

/**
 * ----------------------------------------
 * animation bg-pan-left
 * ----------------------------------------
 */
@-webkit-keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes bg-pan-left {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/**
 * ----------------------------------------
 * animation bg-pan-bottom
 * ----------------------------------------
 */
@-webkit-keyframes bg-pan-bottom {
  0% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 50% 100%;
  }
}
@keyframes bg-pan-bottom {
  0% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 50% 100%;
  }
}

/**
 * ----------------------------------------
 * animation bg-pan-tl
 * ----------------------------------------
 */
@-webkit-keyframes bg-pan-tl {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes bg-pan-tl {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/**
 * ----------------------------------------
 * animation bg-pan-bl
 * ----------------------------------------
 */
@-webkit-keyframes bg-pan-bl {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}
@keyframes bg-pan-bl {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}

/**
 * ----------------------------------------
 * animation bg-pan-top
 * ----------------------------------------
 */
@-webkit-keyframes bg-pan-top {
  0% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
@keyframes bg-pan-top {
  0% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

/**
 * ----------------------------------------
 * animation bg-pan-br
 * ----------------------------------------
 */
@-webkit-keyframes bg-pan-br {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}
@keyframes bg-pan-br {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

/**
 * ----------------------------------------
 * animation bg-pan-right
 * ----------------------------------------
 */
@-webkit-keyframes bg-pan-right {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes bg-pan-right {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/**
 * ----------------------------------------
 * animation bg-pan-tr
 * ----------------------------------------
 */
@-webkit-keyframes bg-pan-tr {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}
@keyframes bg-pan-tr {
  0% {
    background-position: 0% 100%;
  }
  100% {
    background-position: 100% 0%;
  }
}

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-2x {
  0% {
    background: #19dcea;
  }
  100% {
    background: #b22cff;
  }
}
@keyframes color-change-2x {
  0% {
    background: #19dcea;
  }
  100% {
    background: #b22cff;
  }
}

/**
 * ----------------------------------------
 * animation color-change-3x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-3x {
  0% {
    background: #19dcea;
  }
  50% {
    background: #b22cff;
  }
  100% {
    background: #ea2222;
  }
}
@keyframes color-change-3x {
  0% {
    background: #19dcea;
  }
  50% {
    background: #b22cff;
  }
  100% {
    background: #ea2222;
  }
}

/**
 * ----------------------------------------
 * animation color-change-4x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-4x {
  0% {
    background: #19dcea;
  }
  33.3333% {
    background: #b22cff;
  }
  66.666% {
    background: #ea2222;
  }
  100% {
    background: #f5be10;
  }
}
@keyframes color-change-4x {
  0% {
    background: #19dcea;
  }
  33.3333% {
    background: #b22cff;
  }
  66.666% {
    background: #ea2222;
  }
  100% {
    background: #f5be10;
  }
}

/**
 * ----------------------------------------
 * animation color-change-5x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-5x {
  0% {
    background: #19dcea;
  }
  25% {
    background: #b22cff;
  }
  50% {
    background: #ea2222;
  }
  75% {
    background: #f5be10;
  }
  100% {
    background: #3bd80d;
  }
}
@keyframes color-change-5x {
  0% {
    background: #19dcea;
  }
  25% {
    background: #b22cff;
  }
  50% {
    background: #ea2222;
  }
  75% {
    background: #f5be10;
  }
  100% {
    background: #3bd80d;
  }
}
